import { useCallback, useEffect, useState } from 'react';

function useTimer(interval = 1000) {
    const [tick, setTick] = useState(false);
    const [value, setValue] = useState({ date: new Date() });

    const updateTick = useCallback(() => {
        setTick(true);
    }, []);

    useEffect(() => {
        const timer = setInterval(updateTick, interval);
        return () => {
            clearInterval(timer);
        }
    }, []);

    useEffect(() => {
        if (!tick) return
        setTick(false)
        var e = { ...value }
        const previousDate = e.date
        e.date = new Date()
        e.elapsed = (e.date - previousDate) * 1.0 / 1000
        setValue(e);
        //console.log(e)
    }, [tick]);

    return value;
};

export default useTimer;