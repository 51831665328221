import { appConfig } from '../../configurations/appConfig'
import urlJoin from 'url-join';
import { dispatchError, dispatchResponseError } from "../../events/errorEvents";

const fetchLastPositions = (onResult = null, queryFilter) => {
    const searchParams = new URLSearchParams();
    if (queryFilter?.assetId) searchParams.append("assetId", queryFilter.assetId);

    const method = "positions/latest?" + searchParams;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (!response.ok) {
                response.text().then(text => {
                    console.info(text);
                    dispatchResponseError(response, text, { ignore: true })
                    if (!onResult) return;
                    onResult({
                        error: `${response.statusText} (${response.status}): ${text}`,
                        data: null,

                    });
                });
                return;
            }

            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({ error: null, data: data });
                });
            }
            else {
                response.text().then(text => {
                    console.info(text);
                    if (!onResult) return;
                    onResult({ error: text, data: null, });
                });
            }
        })
        .catch(err => {
            dispatchError(err, { ignore: true })
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

export default fetchLastPositions;