import urlJoin from 'url-join';
import { appConfig } from '../../../configurations/appConfig';
import { dispatchError, dispatchResponseError } from "../../../events/errorEvents";

const fetchEvents = (onResult = null, queryFilter = null) => {
    const searchParams = new URLSearchParams();
    if (queryFilter?.pageNumber) searchParams.append("pageNumber", queryFilter.pageNumber);
    if (queryFilter?.pageSize) searchParams.append("pageSize", queryFilter.pageSize);
    if (queryFilter?.search) searchParams.append("search", queryFilter.search);
    if (queryFilter?.sort) searchParams.append("sort", queryFilter.sort);

    const method = "events?" + searchParams;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    const authorisationToken = appConfig.authorisationToken;
    const requestOptions = {
        headers: {
            'Authorization': `Bearer ${authorisationToken}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        method: 'GET',
    };

    fetch(urlJoin(server, serverBasePath, method), requestOptions)
        .then(response => {
            if (response.ok) {
                console.info("Operation succeeded");
                response.json().then(data => {
                    console.info(data);
                    if (!onResult) return;
                    onResult({
                        error: null,
                        data: data
                    });
                });
            }
            else {
                response.text()
                    .then(text => {
                        dispatchResponseError(response, text)
                        if (!onResult) return;
                        onResult({ error: `${response.statusText} (${response.status}): ${text}`, data: null });
                    })
                    .catch(err => {
                        dispatchResponseError(response, err)
                        console.info(`${err}`);
                        if (!onResult) return;
                        onResult({ error: `${err}`, data: null });
                    });
            }
        })
        .catch(err => {
            dispatchError(err)
            console.info(`${err}`);
            if (!onResult) return;
            onResult({ error: `${err}`, data: null });
        });
};

export default fetchEvents;