import React from 'react'
import './TimeDisplay.css'

function TimeDisplay(props) {
    const [dateTime, setDateTime] = React.useState(new Date());

    const onTimeInterval = () => {
        //console.log("Interval Tick ...")
        setDateTime(new Date())
    }

    React.useEffect(() => {
        //var language = window.navigator.userLanguage || window.navigator.language;
        //alert(language); 
        const interval = setInterval(onTimeInterval, 1000);
        return () => {
            clearInterval(interval);
            console.log("Clear Interval")
        }
    }, []);

    const date = dateTime.toLocaleString("fr", { dateStyle: "long" });
    const time = dateTime.toLocaleString("fr", { timeStyle: "short" });
    return (
        <div className='main-frame'>
            <span className='text-display' style={{ fontSize: props.fontSize }}>
                {date}
            </span>
            <span className='text-display' style={{ fontSize: props.fontSize }}>
                {time}
            </span>
        </div>);
};

export default TimeDisplay;