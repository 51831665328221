import React from 'react'
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import isLogin from '../calls/authentication/isLogin';
import TopBarComponenent from './TopBarComponenent';
import { AppContext } from "../AppContext";
import { addDays } from '../utilities/timeHelper'
import { root, admin, support, installer, user, hasRole, rolesCount, hasAnyRoles } from '../definitions/roles';



function PageSelector(props) {

    const navigate = useNavigate();
    const [ready, setReady] = React.useState(false);
    const { appTheme } = React.useContext(AppContext);
    const { activeUser } = React.useContext(AppContext);
    const { activeRoles } = React.useContext(AppContext);

    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "35px";

    const checkIfReady = () => {
        showLoading(true)
        isLogin((e) => {
            showLoading(false)
            if (e.error) {
                navigate("/home", { replace: true });
                return;
            }

            if (!activeUser) {
                navigate("/home", { replace: true });
                return;
            }

            setReady(true);
        })
    }

    const onBackButtonEvent = (e) => {
        e.preventDefault();
        checkIfReady(false);
    }

    const processRoles = (roles) => {
        var count = rolesCount(roles);
        if (count === 1) {
            if (hasRole(roles, user)) {
                navigate("/dashboard");
                return;
            }
        }
    }

    React.useEffect(() => {
        checkIfReady();
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    React.useEffect(() => {
        if (ready) {
            processRoles(activeRoles)
        }
    }, [ready]);

    if (!props.ready || !ready) {
        return null;
    }

    const roles = activeRoles
    const isAdmin = hasRole(roles, root)
    const isUser = hasAnyRoles(roles, [root, admin, support, installer, user])
    return (
        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appTheme.themeColor }}>
                <TopBarComponenent
                    showInfo={true}
                    userMenuControlOptions={{
                        userMenuControl: true,
                    }}
                    showScreenControl={true}
                    showNoFullScreen={true} />
            </div>
            <div style={{ height: "100%", display: "flex" }}>
                <div style={{
                    display: "flex", flexDirection: "row", textAlign: "center", height: "400px", width: "400px"
                    , borderWidth: "1px", borderStyle: "solid", padding: "10px", borderRadius: "5px",
                    alignSelf: "center", marginLeft: "auto", marginRight: "auto"
                }}>
                    <button
                        disabled={!isAdmin}
                        style={{ width: "100%", margin: "5px", fontWeight: "bold", fontSize: "16px" }}
                        onClick={() => {
                            navigate("/administration");
                        }}>
                        Administration
                    </button>
                    <button
                        disabled={!isUser}
                        style={{ width: "100%", margin: "5px", fontWeight: "bold", fontSize: "16px" }}
                        onClick={() => {
                            navigate("/dashboard");
                        }}>
                        Utilisateur
                    </button>
                </div>
            </div>
            {/* <button onClick={() => {
                refreshToken({ token: 'fofo', refreshToken: 'dfdrre' }, (data) => {
                    console.log('Refresh token data ----> ' + data)
                })
            }}>refresh token</button> */}
            <div style={{ height: barHeight }}></div>
        </div>
    );
}

export default PageSelector;