import './App.css';
import './App.scss'
import React from 'react'
import { loadAppConfigs } from './configurations/appConfig';
import { useState, useEffect, useCallback } from 'react';
import { GlobalLoading, showLoading } from 'react-global-loading';
import WebtraceDashboard from './components/Dashboard/WebtraceDashboard'
import WebTraceDemoDashboard from './components/DashboardDemo/WebTraceDemoDashboard'
import Administration from './components/administration/Administration'
import Home from './components/Home/Home';
import PageSelector from './components/PageSelector';
import Logout from './components/Logout';
import { AppContext } from "./AppContext";
import { loadLanguage } from './language/lang';
import { BrowserView, MobileView, isIE } from 'react-device-detect';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom'
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import hello from './calls/authentication/hello';
import fetchVisitorCoordinates from './calls/identity/fetchVisitorCoordinates';
import { isDevelopment } from './utilities/devHelper';
import AboutBoxComponent from './components/dialogs/AboutBoxComponent';
import { CookiesProvider } from "react-cookie";
import refreshTokenFromContext from './calls/authentication/refreshTokenFromContext'
import getUserProfile from './calls/authentication/getUserProfile'
import { setConfigProperty } from './configurations/appConfig'
import PasswordChangePage from './components/administration/passwords/PasswordChangePage'
import PasswordChangeForm from './components/administration/passwords/PasswordChangeForm';
import GlobalError from "./components/menus/GlobalError"
import { hexToRgbaWithAlpha } from './utilities/convertColors';
import getTokenLifetime from './calls/authentication/getTokenLifetime';
import { errorEvents } from './events/errorEvents';
import useOnlineStatus from './utilities/useOnlineStatus';
import NoInternet from './components/dialogs/NoInternet'
import useTabActive from './utilities/useTabActive';
import useTimer from './utilities/useTimer';
import useOrientation from './utilities/useOrientation';
import { configureTheme } from './styles/appThemes';

// import * as rdd from 'react-device-detect';
// rdd.isMobile = true;

function App() {
    const [timerReady, setTimerReady] = useState(false);
    const [tokenReady, setTokenReady] = useState(false);
    const [configLoaded, setConfigLoaded] = useState(false);
    const [visitorReady, setVisitorReady] = useState(false);
    const [googleApiKey, setGoogleApiKey] = useState(null);
    const [ready, setReady] = useState(false);
    const fullScreenHandle = useFullScreenHandle();
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [busy, setBusy] = useState(true);
    const [map, setMap] = useState(null);
    const onlineStatus = useOnlineStatus();
    const [online, setOnline] = useState(false);
    const [accountBarData, setAccountBarData] = useState({ expanded: true });
    const [gridBarData, setGridBarData] = useState({ expanded: false });
    const [makerSelectedId, setMakerSelectedId] = useState(-1);
    const [gridSelectedRowId, setGridSelectedRowId] = useState(-1);
    const [gridScrollIndex, setGridScrollIndex] = useState(-1);
    const [selectionWarning, setSelectionWarning] = useState(false);
    const [contactsPanel, setContactsPanel] = useState(false);
    const [loginOpen, setLoginOpen] = useState(false);
    const [loggedIn, setLoggedIn] = useState(false);
    const [authorisationToken, setAuthorisationToken] = useState(null);
    const [settingActive, setSettingActive] = useState(false);
    const [accountData, setAccountData] = useState({});
    const [visitorLocation, setVisitorLocation] = useState(null);
    const [settings, setSettings] = useState(null);
    const [activeUser, setActiveUser] = useState(null);
    const [activeRoles, setActiveRoles] = useState(null);
    const [showAbout, setShowAbout] = useState(false);
    const [aboutContactInfo, setAboutContactInfo] = React.useState(null);
    const [globalError, setGlobalError] = React.useState(null);
    const [passwordChangeOverlay, setPasswordChangeOverlay] = React.useState(false);
    const [tokenExpiryCountDown, setTokenExpiryCountDown] = useState(null);
    const [appTheme, setAppTheme] = React.useState({}); //ios  **

    // ***** hooks ******** 
    const tabActive = useTabActive();
    const timerTick = useTimer(1000);
    const portrait = useOrientation();



    const refreshVisitorInformation = () => {
        fetchVisitorCoordinates((e) => {
            if (e.error) {
                setVisitorReady(true)
                return;
            }

            setVisitorLocation(e.data);
            setVisitorReady(true)
        })
    }

    const loadConfiguations = () => {
        loadAppConfigs((data) => {
            setSettings(data);
            setConfigLoaded(true);
            refreshVisitorInformation();
            updateToken();
            if (!isDevelopment()) {

                hello((e) => {
                    if (e.error) {
                        return;
                    }

                    console.info("Hello");
                });
            }
        });
    }

    const updateToken = () => {
        refreshTokenFromContext((e) => {
            console.log(e)
            if (e.error) {
                setTokenReady(true)
                return;
            }

            const token = e.data.accessToken
            setAuthorisationToken(token)
            setConfigProperty("authorisationToken", token);
            getUserProfile((e) => {
                console.log(e)
                if (e.error) {
                    setTokenReady(true)
                    return;
                }

                const user = e.data.user;
                const roles = e.data.roles;

                setActiveUser(user);
                const normalisedRoles = roles.map(element => {
                    return element.toLowerCase();
                });

                setActiveRoles(normalisedRoles);
                setTokenReady(true)
            })
        })
    }

    const updateTokenExpiryCountDown = (e) => {
        if (!authorisationToken)
            return
        const refreshLevel = 15;
        const countDown = tokenExpiryCountDown;
        if (countDown && countDown > 0) {
            const newExpiry = countDown - e.elapsed
            if (newExpiry >= refreshLevel) {
                setTokenExpiryCountDown(newExpiry)
                //console.log(`token expiry --> ${newExpiry}`)
                return
            }

            setTokenExpiryCountDown(null)
            refreshTokenFromContext((e) => {
                console.log(e)
                if (e.error) {
                    const errorFallBackExpiry = newExpiry - 3 > 0 ? newExpiry - 3 : 0;
                    setTokenExpiryCountDown(errorFallBackExpiry)
                    return;
                }

                const token = e.data.accessToken
                setAuthorisationToken(token)
                setConfigProperty("authorisationToken", token);
            })
        }
    }

    const updateExpiryTokenLifetime = () => {
        if (!authorisationToken)
            return
        const data = {
            token: authorisationToken
        }

        getTokenLifetime((e) => {
            if (e.error) {
                console.log(e.error);
                setTokenExpiryCountDown(null)
                return
            }

            const refreshTime = e.data.refreshTime;
            if (refreshTime <= 0) {
                console.log("Refresh token has expired")
                setTokenExpiryCountDown(null)
                return;
            }

            const tokenTime = e.data.tokenTime;
            setTokenExpiryCountDown(parseInt(tokenTime))
        }, data)
    }

    const onFullScreenChanged = useCallback((state, handle) => {
        setIsFullScreen(state);
    }, [fullScreenHandle]);

    useEffect(() => {
        const onErrorEvent = (e) => {
            console.log(e)
            if (!e.ignore)
                setGlobalError(e.error)
        }

        errorEvents.on(onErrorEvent)
        showLoading(true);
        loadLanguage("french");
        loadConfiguations();
        setAppTheme(configureTheme({ isPortrait: portrait }))
        return () => {
            errorEvents.remove(onErrorEvent)
        }
    }, []);

    useEffect(() => {
        setOnline(onlineStatus)
    }, [onlineStatus]);

    useEffect(() => {
        if (tabActive) {
            console.log("ACTIVE ---> TRUE")
        } else {
            console.log("ACTIVE ---> FALSE")
        }

    }, [tabActive]);

    useEffect(() => {
        setAppTheme(configureTheme({ isPortrait: portrait }))
    }, [portrait]);

    useEffect(() => {
        const monitorTimerReady = () => {
            if (timerReady)
                return
            if (configLoaded && tokenReady && visitorReady)
                setTimerReady(true)
        }

        if (timerTick) {
            updateTokenExpiryCountDown(timerTick)
            monitorTimerReady()
        }
    }, [timerTick]);

    useEffect(() => {
        if (authorisationToken) {
            updateExpiryTokenLifetime()
        }
        else {
            setTokenExpiryCountDown(null)
        }
    }, [authorisationToken]);

    useEffect(() => {
        if (timerReady && configLoaded && tokenReady && visitorReady)
            setReady(true)

    }, [timerReady, configLoaded, tokenReady, visitorReady]);

    // const api_regex = /^\/api\/.*/
    // // if using "/api/" in the pathname, don't use React Router
    // if (api_regex.test(window.location.pathname)) {
    //     return <div /> // must return at least an empty div
    // }

    if (isIE) return <div> IE is not supported. Download Chrome/Opera/Firefox </div>

    return (
        <FullScreen handle={fullScreenHandle} onChange={onFullScreenChanged} >
            <BrowserRouter>
                <AppContext.Provider value={
                    {
                        ready, setReady,
                        busy: busy, setBusy: setBusy,
                        map: map, setMap: setMap,
                        online: online, setOnline: setOnline,
                        portrait: portrait,
                        accountBarData: accountBarData, setAccountBarData: setAccountBarData,
                        gridBarData: gridBarData, setGridBarData: setGridBarData,
                        fullScreenHandle: fullScreenHandle,
                        selectionWarning, setSelectionWarning,
                        makerSelectedId, setMakerSelectedId,
                        gridSelectedRowId, setGridSelectedRowId,
                        gridScrollIndex, setGridScrollIndex,
                        contactsPanel, setContactsPanel,
                        loginOpen, setLoginOpen,
                        loggedIn, setLoggedIn,
                        authorisationToken, setAuthorisationToken,
                        settingActive, setSettingActive,
                        accountData, setAccountData,
                        googleApiKey, setGoogleApiKey,
                        settings, setSettings,
                        activeUser, setActiveUser,
                        activeRoles, setActiveRoles,
                        showAbout, setShowAbout,
                        aboutContactInfo, setAboutContactInfo,
                        isFullScreen, setIsFullScreen,
                        visitorLocation, setVisitorLocation,
                        appTheme,
                        globalError, setGlobalError,
                        passwordChangeOverlay, setPasswordChangeOverlay,

                    }}>
                    <BrowserView>
                        <CookiesProvider>
                            <div className="App" onContextMenu={(e) => {
                                e.preventDefault(); // prevent the default behaviour when right clicked
                                console.log("Right Click");
                            }}>
                                <ApplicationOverlay />
                                {
                                    ready ?
                                        <Routes>
                                            <Route path="/" element={<Home ready={ready} />} />
                                            <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                            <Route path="/selector" element={<PageSelector ready={ready} />} />
                                            <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                            <Route path="/administration" element={<Administration ready={ready} />} />
                                            <Route path="/logout" element={<Logout ready={ready} />} />
                                            <Route path="/home" element={<Home ready={ready} />} />
                                            <Route path="/password_change" element={
                                                <PasswordChangePage
                                                    successDelay={4000}
                                                    cancelRoute='/logout'
                                                    ready={ready}
                                                    successRoute='/selector' />
                                            } />
                                            <Route path="*" element={<Home ready={ready} />} />
                                        </Routes> :
                                        null
                                }
                                <GlobalLoading loadingSize={35} loadingType='spokes'
                                    loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                    loadingColor="#eee"
                                    backgroundColor='rgba(0, 0, 0, 0.5)'>
                                </GlobalLoading>
                            </div>
                        </CookiesProvider>
                    </BrowserView>
                    <MobileView>
                        <CookiesProvider>
                            <div className="App" onContextMenu={(e) => {
                                e.preventDefault(); // prevent the default behaviour when right clicked
                                console.log("Right Click");
                            }}>
                                <ApplicationOverlay />
                                {
                                    ready ?
                                        <Routes>
                                            <Route path="/" element={<Home ready={ready} />} />
                                            <Route path="/dashboard" element={<WebtraceDashboard ready={ready} />} />
                                            <Route path="/selector" element={<PageSelector ready={ready} />} />
                                            <Route path="/demo" element={<WebTraceDemoDashboard ready={ready} />} />
                                            <Route path="/administration" element={<Administration ready={ready} />} />
                                            <Route path="/logout" element={<Logout ready={ready} />} />
                                            <Route path="/home" element={<Home ready={ready} />} />
                                            <Route path="/password_change" element={
                                                <PasswordChangePage
                                                    successDelay={4000}
                                                    cancelRoute='/logout'
                                                    ready={ready}
                                                    successRoute='/selector' />
                                            } />
                                            <Route path="*" element={<Home ready={ready} />} />
                                        </Routes> :
                                        null
                                }
                                <GlobalLoading loadingSize={25} loadingType='spokes'
                                    loadingBackgroundColor='rgba(255, 255, 255, 0.5)'
                                    loadingColor="#eee"
                                    backgroundColor='rgba(0, 0, 0, 0.5)'>
                                </GlobalLoading>
                            </div>
                        </CookiesProvider>
                    </MobileView>
                </AppContext.Provider>
            </BrowserRouter>
        </FullScreen>);
};

function ApplicationOverlay() {
    const navigate = useNavigate();
    const { showAbout, setShowAbout } = React.useContext(AppContext);
    const { globalError, setGlobalError } = React.useContext(AppContext);
    const { passwordChangeOverlay, setPasswordChangeOverlay } = React.useContext(AppContext);
    const { online } = React.useContext(AppContext);
    const { ready } = React.useContext(AppContext);
    return (
        <>
            {
                ready &&
                    showAbout ?
                    <AboutBoxComponent
                        open={showAbout}
                        onClickOutside={() => { setShowAbout(false) }}
                        onClose={() => { setShowAbout(false) }} /> :
                    null
            }
            {
                globalError &&
                <div style={{ display: "flex", position: "absolute", right: "20px", top: "20px", zIndex: 1 }}>
                    <GlobalError
                        error={globalError}
                        onComplete={() => {
                            setGlobalError(null)
                        }}>
                    </GlobalError>
                </div>
            }

            {
                ready &&
                passwordChangeOverlay &&
                <div
                    style={{
                        display: "flex",
                        position: "absolute",
                        width: "100%",
                        height: "100%",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                        zIndex: 1
                    }}>
                    <PasswordChangeForm
                        successDelay={3000}
                        onCancel={() => {
                            setPasswordChangeOverlay(false)
                        }} onSuccess={() => {
                            setPasswordChangeOverlay(false)
                            navigate("/logout", { replace: true })
                        }}>
                    </PasswordChangeForm>
                </div>
            }
            {
                ready && !online &&
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        position: "absolute",
                        pointerEvents: "none",
                        width: "100%",
                        height: "100%",
                        backgroundColor: hexToRgbaWithAlpha("#000000", 0.2),
                        zIndex: 1
                    }}>
                    <div style={{ width: "100%", flex: 1 }}></div>
                    <div style={{ display: "flex", width: "100%", flex: 1, alignItems: "center" }}>
                        <NoInternet />
                    </div>

                </div>
            }
        </>);
}

export default App;
