import { useRef, useEffect } from "react";
import React from 'react';
import { Link } from "react-router-dom";
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'
import appConfig from '../../configurations/appConfig.json';
import { AppContext } from "../../AppContext";
import fetchContactInformation from "../../calls/misc/fetchContactInformation";
import { showLoading } from 'react-global-loading';

function AboutBoxComponent(props) {
    const { aboutContactInfo, setAboutContactInfo } = React.useContext(AppContext);
    const { setBusy } = React.useContext(AppContext);

    const wrapperRef = useRef(null);
    const openValue = React.useRef(false);

    React.useEffect(() => {
        if (aboutContactInfo) return;
        setBusy(true);
        showLoading(true);
        fetchContactInformation((e) => {
            setBusy(false);
            showLoading(false);
            if (e.error)
                return;
            setAboutContactInfo(e.data);
        })
    }, []);

    useEffect(() => {
        const handleClickOutside = event => {
            if (!openValue.current) {
                return;
            }

            if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                if (!props.onClickOutside) return;
                props.onClickOutside()
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };
    }, []);

    useEffect(() => {
        openValue.current = props.open;
    }, [props.open]);

    const onEmailClick = (e) => {
        window.location.href = `mailto:${email}`;
        e.preventDefault();
    };

    const onTelClick = (e) => {
        window.location.href = `tel:${email}`;
        e.preventDefault();
    };

    if (!aboutContactInfo) {
        return null;
    }

    const title = `Webtrace CD ${appConfig.version}`;
    const email = aboutContactInfo?.emails;
    const mailTo = `mailto:${email}`;
    const tel = aboutContactInfo?.number;
    const telTo = `tel:${tel}`;



    return (
        <div style={{ zIndex: 1, position: "absolute", width: "100%", height: "100%", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
            <dialog open={props.open}
                style={{

                    padding: '10px',
                    borderWidth: "2px",
                    borderRadius: "5px",
                    borderColor: "lightgray",
                    backgroundColor: "whitesmoke",
                    top: "50%",
                    bottom: "50%",
                    marginRight: "auto",
                    marginLeft: "auto",
                    zIndex: 1
                }}
                ref={wrapperRef}>
                <div style={{ textAlign: "left", fontSize: 14, fontWeight: "bold", margin: "10px 0px" }}>{title}</div>
                <div style={{ textAlign: "left", fontSize: 12, fontWeight: "bold", margin: "4px 0px" }}>
                    Un project de traçage et surveillance carburant</div>
                <div style={{ textAlign: "left", fontSize: 12, margin: "4px 0px" }}>
                    Par les congolais, pour les congolais</div>
                <hr />
                <div style={{ textAlign: "left", fontSize: 12, margin: "5px 0px" }}>
                    <span style={{ fontWeight: "bold", margin: "0px 5px 0px 0px" }}>Developpeur:</span>
                    <span>John Ukundji</span>
                </div>
                <div style={{ textAlign: "left", fontSize: 12, margin: "5px 0px" }}>
                    <span style={{ fontWeight: "bold", margin: "0px 5px 0px 0px" }}>Contributeur:</span>
                    <span>Cedrick Kadima, Heritier Masala</span>
                </div>
                <div style={{ textAlign: "left", fontSize: 12, margin: "5px 0px" }}>
                    <span style={{ fontWeight: "bold", margin: "0px 5px 0px 0px" }}>Contacts:</span>
                    <Link to={mailTo} onClick={onEmailClick} style={{ margin: "0px 5px 0px 0px" }}>
                        {email}
                    </Link>
                    <Link to={telTo} onClick={onTelClick} style={{ margin: "0px 5px 0px 0px" }}>
                        {tel}
                    </Link>
                </div>
                <div style={{ margin: "10px 0px 0px 0px" }}>
                    <button style={{
                        width: "100%",
                        backgroundColor: "whitesmoke",
                        color: "black",
                        fontSize: 12,
                        height: 20,
                        borderRadius: "2px",
                        borderWidth: "2px",
                        margin: "5px 0px 1px 0px",
                        borderColor: 'lightgray',
                        cursor: "pointer"
                    }} onClick={() => {
                        if (!props.onClose) return;
                        props.onClose()
                    }}>Fermer</button>
                </div>
            </dialog>
        </div>
    );
}

export default AboutBoxComponent;