import './GoogleMapComponent.css';
import React from 'react'
import { GoogleMap, useJsApiLoader, Polyline } from '@react-google-maps/api';
import { Marker, InfoWindow } from '@react-google-maps/api';
import { AppContext } from "../AppContext";

import { getPolylineIcon } from '../map/mapIconProvider'

import { isMobile } from 'react-device-detect';
import dateFormat from "dateformat";
import selectionMarkerIcon from '../images/selection.svg';
import { buildSelectionIconUrl } from '../utilities/assetHelper';
import { forwardRef, useImperativeHandle } from 'react';
import { toLocalTime } from '../utilities/timeHelper'

import {
    iconSize,
    selectedIconSize,
    iconIndexLayer,
    selectedMarkerIconIndexLayer,
    selectionAreaMarkerIndexLayer,
    extraIconIndexLayer,
    setNormalIconSize,
    setSelectedIconSize
}
    from '../configurations/mapConfig';

function GoogleMapComponent(props, ref) {
    const defaultCenter = { lat: -4.0335162, lng: 21.7500603 };
    const defaultZoom = 13;
    const { googleApiKey } = React.useContext(AppContext);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: googleApiKey
    })

    const { map, setMap } = React.useContext(AppContext);
    const { visitorLocation } = React.useContext(AppContext);
    const [markerInstances] = React.useState([]);
    const [infoWindowMaker, setInfoWindowMaker] = React.useState(null);
    const [selectionMarker, setSelectionMarker] = React.useState(null);
    const [currentCenter, setCurrentCenter] = React.useState(null);
    const [currentZoom, setCurrentZoom] = React.useState(18);
    const [makerSelectedId, setMakerSelectedId] = React.useState(-1);
    const [mapInternal, setMapInternal] = React.useState(null);

    const closerCenter = visitorLocation ?
        { lat: visitorLocation.latitude, lng: visitorLocation.longitude } :
        defaultCenter;

    React.useEffect(() => {
        if (props.center) {
            setCurrentCenter({ ...props.center })
        }
        else {
            setCurrentZoom(defaultZoom)
            setCurrentCenter({ ...closerCenter });
        }
    }, [props.center]);

    React.useEffect(() => {
        markerInstanceSelection(makerSelectedId);
    }, [makerSelectedId]);

    React.useEffect(() => {
        clearMakerInstanceSelection();
        setInfoWindowMaker(null)
        setSelectionMarker(null)
        setMakerSelectedId(-1);
    }, [props.markers]);

    const setMarkerSelection = ({ id, activateSelection, showInfoWindow }) => {
        setInfoWindowMaker(null)
        setMakerSelectedId(id)
        const instance = markerInstances.find(x => !x.disposed && x.id === id);

        if (activateSelection) {
            setSelectionMarker(instance)
        }

        if (showInfoWindow) {
            setInfoWindowMaker(instance)
        }
    }

    const getInternalMap = () => {
        return mapInternal;
    }

    useImperativeHandle(ref, () => ({
        setMarkerSelection: setMarkerSelection,
        getInternalMap: getInternalMap
    }));

    const clearMakerInstanceSelection = () => {
        for (var instance of markerInstances) {
            instance.isSelected = false;
            instance.setZIndex(iconIndexLayer);
            //setNormalIconSize(instance)
        }
    }

    const markerInstanceSelection = (id) => {
        clearMakerInstanceSelection();
        if (markerInstances.length < 1) {
            return;
        }

        const instance = markerInstances.find(x => !x.disposed && x.id === id);
        if (instance) {

            instance.isSelected = true;
            instance.setZIndex(selectedMarkerIconIndexLayer);
            //setSelectedIconSize(instance)
        }
    }

    const onLoad = React.useCallback(function callback(map) {
        map.markers = [];
        setMap(map)
        setMapInternal(map);
    }, []);

    const handleZoomChanged = () => {
        if (!map) { return; }
        var zoom = map.getZoom();
        setCurrentZoom(zoom)
    }

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
        setMapInternal(null);
    }, []);

    const mobile = isMobile;
    const controlSize = mobile ? 20 : 30;

    if (!window?.google?.maps?.Point) {
        return <></>;
    }

    const onMapClickedHandler = (e) => {
        clearMakerInstanceSelection();
        setInfoWindowMaker(null)
        setSelectionMarker(null)
        setMakerSelectedId(-1);
    }

    var polyIcon = getPolylineIcon();
    const buildGoogleMarkers = () => {
        if (!map || !props.markers) { return; }
        return props.markers.map((marker, index) => {
            const clickCallack = marker.onClick;
            marker.prev = index > 0 ? props.markers[index - 1] : undefined;
            return (
                <React.Fragment key={marker.id}>
                    <Marker key={index} {...marker}
                        onLoad={(markerInstance) => {
                            markerInstance.disposed = false;
                            markerInstance.id = marker.id;
                            markerInstance.type = marker.type;
                            if (markerInstance.type == "map") {
                                markerInstances.push(markerInstance);
                            }
                        }}
                        onUnmount={(markerInstance) => {
                            markerInstance.disposed = true;
                        }}
                        onClick={(e) => {
                            e.stop();
                            clickCallack(e);
                            setSelectionMarker(marker)
                            setInfoWindowMaker(marker)
                        }}>
                    </Marker>
                </React.Fragment>
            )
        })
    };

    const renderSelectionMarker = () => {
        if (!selectionMarker) { return null; }
        return (
            <Marker
                type="selection"
                zIndex={selectionAreaMarkerIndexLayer}
                position={selectionMarker?.position}
                icon={{
                    url: buildSelectionIconUrl("black", "green", 0.05),
                    size: new window.google.maps.Size(40, 40),
                    //origin: new window.google.maps.Point(0,-20),
                    anchor: new window.google.maps.Point(20, 35),
                    scaledSize: new window.google.maps.Size(40, 40)
                }}
            >
            </Marker>);
    };

    const renderInfoWindow = () => {
        const marker = infoWindowMaker;
        if (!marker) {
            return null;
        }

        const position = marker.positionData;
        const speed = position.speed;

        var time = toLocalTime(new Date(position.timeSent));
        var date = dateFormat(time, "dd mmm yyyy");
        var hour = dateFormat(time, "HH'h'MM'm'ss");

        return (
            <InfoWindow
                onCloseClick={() => { setInfoWindowMaker(null) }}
                open={false}
                onLoad={(instance) => { console.log(instance) }}
                onUnmount={() => { setInfoWindowMaker(null) }}
                options={{ pixelOffset: new window.google.maps.Size(0, -25) }}
                position={marker.position}>
                <div style={{ background: `white`, textAlign: 'left', fontSize: '12px', display: "flex", flexDirection: "column" }}>
                    <h3>{position.asset}</h3>
                    <strong>{position?.eventDescription}</strong>
                    {speed > 0 ? <strong>{(`En mouvement (${speed}Km/h)`)}</strong> : <strong>Stationnaire</strong>}
                    <strong>{(`${date}, ${hour}`)}</strong>
                    <strong>{(`${position.locationText}`)}</strong>
                    <strong>{(`lat ${position.latitude}, lng ${position.longitude}, spd ${speed}Km/h`)}</strong>
                </div>
            </InfoWindow>);
    };

    return isLoaded ? (
        <GoogleMap
            defaultCenter={closerCenter}
            defaultZoom={defaultZoom}
            id="Chapresoft"
            mapContainerStyle={{ width: "100%", height: "100%" }}
            center={currentCenter}
            zoom={currentZoom}
            //center={defaultCenter}
            //zoom={defaultZoom}
            onZoomChanged={handleZoomChanged}
            onLoad={onLoad}
            options={{ controlSize: controlSize, streetViewControl: false, fullscreenControl: false }}
            onUnmount={onUnmount}
            onClick={onMapClickedHandler}>
            {renderSelectionMarker()}
            {buildGoogleMarkers()}
            {renderInfoWindow()}

            <Polyline
                path={props.pathCoordinates}
                geodesic={true}
                options={{
                    strokeColor: "#ff2527",
                    strokeOpacity: 0.95,
                    strokeWeight: 1.5,
                    icons: [
                        {
                            icon: polyIcon,
                            repeat: '70px'
                        },
                    ]
                }}
            />
        </GoogleMap>
    ) : <></>
}

export default React.memo(forwardRef(GoogleMapComponent))
//export default GoogleMapComponent;