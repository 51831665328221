import { useCallback, useEffect, useState } from 'react';

function useTabActive() {
    const [isTabVisible, setIsTabVisible] = useState(true);

    const handleVisibilityChange = useCallback(() => {
        setIsTabVisible(document.visibilityState === 'visible');
    }, []);


    const logEvent = useCallback(() => {
        console.log("My hat")
    }, []);

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        document.addEventListener('sleep', logEvent);
        document.addEventListener('wake', logEvent);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
            document.removeEventListener('sleep', logEvent);
            document.removeEventListener('wake', logEvent);
        };
    }, []);

    return isTabVisible;
};

export default useTabActive;