import React from 'react'
import DropdownList from "react-widgets/DropdownList";
import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import createUnit from "../../../calls/administration/units/createUnit";
import fetchUnitTypes from "../../../calls/units/fetchUnitTypes"
import updateUnit from '../../../calls/administration/units/updateUnit';

function UnitCreation(props) {
    const [init, setInit] = React.useState(1);
    const [serial, setSerial] = React.useState("");
    const [unitTypes, setUnitTypes] = React.useState([]);
    const [unitTypeSelection, setUnitTypeSelection] = React.useState(null);
    const [comments, setComments] = React.useState("");
    const [alias, setAlias] = React.useState("");
    const [error, setError] = React.useState("");

    const updateUnitTypes = (onComplete) => {
        fetchUnitTypes((e) => {
            if (e.error) {
                if (onComplete) onComplete(false)
                return;
            }

            setUnitTypes(e.data)
            if (onComplete) onComplete(true)
        })
    }

    const loadEditUnit = (unit) => {
        console.log(unit)
        setSerial(unit.hwSerial)
        setAlias(unit.alias)
        setComments(unit.comments)
        var selectedType = unitTypes.find(x => x.id === unit.unitTypeId)
        setUnitTypeSelection(selectedType)
    }

    React.useEffect(() => {
        if (init === 1) {
            updateUnitTypes(() => {
                setInit(2)
            });
        }

        if (init === 2) {
            if (props.editUnit) {
                loadEditUnit(props.editUnit);
            }

            setInit(3)
        }
    }, [init]);

    return <dialog open={props.open}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div>
            <div style={{ textAlign: "right" }}>
                {props.editUnit ? `Unit edition (${props.editUnit.id})` : "Unit creation"}
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="unit_creation_serial">Numero serie</label>
                <input style={{ display: "block", width: "100%" }} type="text" id="unit_creation_serial" placeholder="Numero serie" value={serial}
                    autoComplete="off"
                    onChange={(e) => {
                        setSerial(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "140px", textAlign: "left" }} htmlFor="unit_creation_type">Type</label>
                <DropdownList
                    id="unit_creation_type"
                    style={{ display: "block", width: "100%" }}
                    data={unitTypes}
                    dataKey='id'
                    textField='model'
                    value={unitTypeSelection}
                    onChange={(value) => {
                        setUnitTypeSelection(value)
                    }}
                    renderValue={({ item }) => (
                        <div style={{ display: "flex", minWidth: "60px" }}>
                            <strong style={{ flex: 1 }}>{item?.model}</strong>
                        </div>
                    )}
                />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="unit_creation_hw_edition">Alias</label>
                <input style={{ display: "block", width: "100%", height: "20px" }} type="text" id="unit_creation_hw_edition" placeholder="Alias" value={alias}
                    autoComplete="off"
                    onChange={(e) => {
                        setAlias(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", width: "140px" }} htmlFor="unit_creation_hw_edition">Comments</label>
                <input style={{ display: "block", width: "100%", height: "20px" }} type="text" id="unit_creation_hw_edition" placeholder="Comments" value={comments}
                    autoComplete="off"
                    onChange={(e) => {
                        setComments(e.target.value)
                    }} />
            </div>

            {
                checkIfEmptyOrWhiteSpace(error) ?
                    null :
                    <div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8" }}>
                        {error}
                    </div>
            }
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }}
                    onClick={() => {
                        if (checkIfEmptyOrWhiteSpace(serial)) {
                            setError("Username cannot be empty")
                            return;
                        }

                        if (!unitTypeSelection?.id) {
                            setError("Unit type not specified !!!")
                            return;
                        }

                        if (props.editUnit) {
                            const unitId = props.editUnit.id;
                            if (!unitId) return;
                            const data = {
                                serial: serial,
                                unitTypeId: unitTypeSelection?.id,
                                comments: comments,
                                alias: alias
                            };

                            updateUnit((e) => {
                                if (e.error) {
                                    setError(e.error)
                                    return;
                                }

                                if (!props?.onUnitCreated) {
                                    return;
                                }

                                props?.onUnitCreated(e.data)
                            }, unitId, data);
                        }
                        else {
                            const data = {
                                serial: serial,
                                unitTypeId: unitTypeSelection?.id,
                                comments: comments,
                                alias: alias
                            };

                            createUnit(data, (e) => {
                                if (e.error) {
                                    setError(e.error)
                                    return;
                                }

                                if (!props?.onUnitCreated) {
                                    return;
                                }

                                props?.onUnitCreated(e.data)
                            });
                        }
                    }}>
                    confirmer
                </button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }}
                    onClick={() => {
                        if (!props?.onCancel) {
                            return;
                        }
                        props.onCancel();
                    }}>
                    Annuler
                </button>
            </div>
        </div>
    </dialog>
}

export default UnitCreation;