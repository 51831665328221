import { useEffect, useState } from 'react';


const useClickOutside = (elementName) => {
    const [value, setValue] = useState(false);
    useEffect(() => {
        const handleClickOutside = event => {
            let div = document.getElementById(elementName);
            if (!div) {
                console.error(`Click outside ref element ${elementName} does not exist`)
                return
            }

            if (!div.contains(event.target)) {
                setValue(true)
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };

    }, []);

    useEffect(() => {
        if (value) {
            setValue(false)
        }
    }, [value, setValue]);

    return value;
};

const useRefClickOutside = ({ ref, exceptionRefs = [] }) => {
    const [value, setValue] = useState(false);
    useEffect(() => {
        const handleClickOutside = event => {
            let div = ref?.current;
            if (!div) {
                console.error(`Click outside ref element ${div} does not exist`)
                return
            }

            if (!div.contains(event.target)) {
                if (exceptionRefs && exceptionRefs?.length && exceptionRefs?.length > 0) {
                    for (const e of exceptionRefs) {
                        if (e?.current && e.current.contains(event.target)) {
                            return
                        }
                    }
                }

                setValue(true)
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };

    }, []);

    useEffect(() => {
        if (value) {
            setValue(false)
        }
    }, [value, setValue]);

    return value;
};

const useGridRefClickOutside = ({ ref, exceptionRefs = [] }) => {
    const [value, setValue] = useState(false);
    useEffect(() => {
        const handleClickOutside = event => {
            let div = ref?.current?.element;
            if (!div) {
                console.error(`Click outside ref element ${div} does not exist`)
                return
            }

            if (!div.contains(event.target)) {
                if (exceptionRefs && exceptionRefs?.length && exceptionRefs?.length > 0) {
                    for (const e of exceptionRefs) {
                        if (e?.current && e.current.contains(event.target)) {
                            return
                        }
                    }
                }

                setValue(true)
            }
        };

        document.addEventListener("mouseup", handleClickOutside, false);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside, false);
        };

    }, []);

    useEffect(() => {
        if (value) {
            setValue(false)
        }
    }, [value, setValue]);

    return value;
};

export { useClickOutside, useRefClickOutside, useGridRefClickOutside }