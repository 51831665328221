import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import login from '../../calls/authentication/login';
import { checkIfEmptyOrWhiteSpace } from '../../utilities/stringHelper';
import { useNavigate } from "react-router-dom";
import { setConfigProperty } from "../../configurations/appConfig"
import TopBarComponenent from '../TopBarComponenent';
import { Link } from 'react-router-dom';
import './Home.css';

function Home(props) {
    const navigate = useNavigate();
    const { busy, setBusy } = useContext(AppContext);
    const { portrait } = React.useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);
    const { setAuthorisationToken } = React.useContext(AppContext);
    const { setActiveUser } = React.useContext(AppContext);
    const { setActiveRoles } = React.useContext(AppContext);

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [error, setError] = React.useState("");
    const mobile = isMobile;
    const title = "Identification";
    const titleFontSize = mobile ? "12px" : "16px";
    const barHeight = mobile ? "22px" : "35px";

    const loginUser = (username, password) => {
        const loginData = {
            name: username,
            password: password,
            remember: true,
            tag: "yoyo"
        }

        showLoading(true);
        setBusy(true)
        setError(null);
        login(loginData,
            (e) => {
                showLoading(false);
                setBusy(false)
                if (e.error) {
                    setError(e.error.toString());
                    return;
                }

                const token = e.data.token;
                if (token) {
                    const user = e.data.user;
                    const roles = e?.data?.roles ?? [];

                    setAuthorisationToken(token);
                    setConfigProperty("authorisationToken", token);
                    setActiveUser(user);

                    const normalisedRoles = roles.map(element => {
                        return element.toLowerCase();
                    });

                    setActiveRoles(normalisedRoles);

                    if (user.passChangeRequired) {
                        navigate("/password_change", { replace: true });
                        return;
                    }

                    if (normalisedRoles.includes("root") ||
                        normalisedRoles.includes("admin") ||
                        normalisedRoles.includes("support")) {
                        navigate("/selector", { replace: true });
                        //navigate("/password_change", { replace: true });
                        return;
                    }


                    navigate("/dashboard", { replace: true });
                    //navigate("/password_change", { replace: true });

                }
            });
    }

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, [props.ready]);

    if (!props.ready)
        return null;

    const noError = checkIfEmptyOrWhiteSpace(error);
    const canConfirm = !checkIfEmptyOrWhiteSpace(username) && !checkIfEmptyOrWhiteSpace(password);
    return (
        <div className='full-screen'>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appTheme.themeColor }}>
                <TopBarComponenent showInfo={true} showScreenControl={true} showNoFullScreen={true} />
            </div>
            <div style={{ height: "100%", display: "flex", flex: 1, overflowY: "auto" }}>
                <div className='login-box-div'>
                    <div style={{ margin: "0px 0px 10px 0px", padding: "3px", color: "darkgray", height: "15px", textAlign: "right", fontWeight: "bold", fontSize: titleFontSize }}>{title}</div>
                    <hr />
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "140px" }} htmlFor="login_username">Utilisateur</label>
                        <input id="login_username" type="text"
                            value={username}
                            autoComplete='off'
                            placeholder="Nom d'utilisateur"
                            style={{ width: "100%" }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    if (!busy && username && password) {
                                        loginUser(username, password);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setError(null)
                                setUsername(e.target.value)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "140px" }} htmlFor="login_password">Password</label>
                        <input type="password"
                            id="login_password"
                            autoComplete='off'
                            placeholder="Mot de passe"
                            value={password} style={{ width: "100%" }}
                            onKeyUp={(e) => {
                                if (e.key === 'Enter') {
                                    if (!busy && username && password) {
                                        loginUser(username, password);
                                    }
                                }
                            }}
                            onChange={(e) => {
                                setError(null)
                                setPassword(e.target.value)
                            }} />
                    </div>{noError ? null : (<div className='error-style'>{error}</div>)}
                    <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                        <button
                            disabled={!canConfirm} className='confirm-button' onClick={(e) => {
                                e.preventDefault()
                                loginUser(username, password);
                            }}>Connexion</button>
                    </div>
                </div>
            </div>
            <div className='link-div'>
                <Link
                    // to='/demo'
                    className='link-style'
                    onClick={(e) => {
                        e.preventDefault();
                        navigate("/demo");
                    }}>Voir la demo</Link>
            </div>
            <div style={{ height: barHeight, display: 'flex' }}></div>
        </div>);
}

export default Home;