import React from 'react'
import { localTimeFormat, toUctTime, toLocalTime } from '../../../utilities/timeHelper'
import { showLoading } from 'react-global-loading';
import fetchActivities from '../../../calls/administration/activities/fetchActivities';
import { IconContext } from "react-icons";
import { GrUpdate } from "react-icons/gr";
import ActivityTimeRange from './ActivityTimeRange'
import "./ActivitiesListView.css"
import Paginator from '../../Paginator/Paginator';
import SearchFilter from '../../SearchFilter/SearchFilter';

function ActivitiesListView(props) {
    const [intervalData, setIntervalData] = React.useState(null);
    const [selectedActivity, setSelectedActivity] = React.useState(null);
    const [data, setData] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const divRef = React.useRef(null);
    const updateActivities = (onComplete, silent = true) => {
        if (!silent) {
            //setBusy(true);
            showLoading(true);
        }

        const filter = {
            start: intervalData?.start ?? null,
            end: intervalData?.end ?? null,
            pageNumber: pageNumber?.value,
            search: searchFilter?.value ?? ""
            //pageSize: 100
        }

        fetchActivities((e) => {
            if (!silent) {
                //setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                if (onComplete) onComplete(false);
                return;
            }

            setData(e.data)
            //setActivities(e.data)
            if (onComplete) onComplete(true);
        }, filter);
    }

    React.useEffect(() => {
        updateActivities(null, false)

        const handleClickOutside = event => {
            event.preventDefault()
            if (divRef.current && !divRef.current.contains(event.target)) {
                if (event.target.nodeName.toLowerCase() === 'button') return;
                setSelectedActivity(null)
            }
        };

        document.addEventListener("mouseup", handleClickOutside);
        return () => {
            document.removeEventListener("mouseup", handleClickOutside);
        };
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const filter = { ...pageNumber }
            filter.updated = false;
            setPageNumber(filter)
            updateActivities(null, true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateActivities(null, true);
        }
    }, [searchFilter]);

    const activities = data?.data;
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end", gap: "20px", ma: "5px" }} >
                    <div style={{ display: "flex", flexDirection: "row", gap: "4px" }}>
                        <ActivityTimeRange
                            onRangeInterval={(e) => {
                                setIntervalData(e)
                            }} />
                        <button
                            onClick={() => {
                                updateActivities(null, false)
                            }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: 14 }}>
                                    <GrUpdate />
                                </IconContext.Provider>
                            </div>
                        </button>
                    </div>

                    <SearchFilter
                        initialValue={searchFilter?.value}
                        onFilterChange={(s) => {
                            setSearchFilter({
                                updated: true,
                                value: s
                            })
                        }}>
                    </SearchFilter>
                </div>
            </div>
            <div ref={divRef} style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "12px" }}>
                        <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Date</th>
                                <th style={{ textAlign: "left", }}>Message</th>
                                <th style={{ textAlign: "left" }}>User</th>
                                <th style={{ textAlign: "left" }}>Machine</th>
                                <th style={{ textAlign: "left" }}>Ip</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                activities &&
                                activities.map((contact, index) => {
                                    const finalActivity = contact;
                                    const isSelected = (selectedActivity && finalActivity.id === selectedActivity?.id);
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                            onClick={() => {
                                                setSelectedActivity(finalActivity)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{localTimeFormat(finalActivity.createdAt)}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.message}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.user}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.machineName}</td>
                                            <td style={{ textAlign: "left" }}>{finalActivity.ipAddress}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>

            </div>
            {
                Boolean(data?.pageCount && data?.pageCount > 1) &&
                <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                    <Paginator
                        changeIndicator={data}
                        initialNumber={pageNumber?.value}
                        pageSize={data?.pageSize}
                        pageCount={data?.pageCount}
                        count={data?.count}
                        onPageSelected={(page) => {
                            console.log(page)
                            setPageNumber({
                                value: page.number,
                                updated: true
                            })
                        }} />
                </div>
            }
            <div style={{ height: "20px", width: "100%" }}>
            </div>
        </div>);
};

export default ActivitiesListView;