const convertHeadingCompassOrientation = (heading) => {
    if (heading === 0)
        return "N"
    if (!heading)
        return "__"
    const half = 45.0 / 2.0;
    if (heading >= (0 - half) && heading < (0 + half))
        return "N"
    if (heading > (360 - half) && heading < (360 + half))
        return "N"
    else if (heading > (45 - half) && heading < (45 + half))
        return "NE"
    else if (heading > (90 - half) && heading < (90 + half))
        return "E"
    else if (heading > (135 - half) && heading < (135 + half))
        return "NW"
    else if (heading > (180 - half) && heading < (180 + half))
        return "S"
    else if (heading > (225 - half) && heading < (225 + half))
        return "SW"
    else if (heading > (270 - half) && heading < (270 + half))
        return "W"
    else if (heading > (315 - half) && heading < (315 + half))
        return "SE"
    return heading
}

export { convertHeadingCompassOrientation }