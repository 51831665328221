import React from 'react'
import { useRef } from 'react'
import { AppContext } from "../AppContext";
import { showLoading } from 'react-global-loading';
import logo from '../images/logo.svg';
import flag from '../images/flag.svg';
import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { FcRefresh } from "react-icons/fc";
import { FcInfo, FcHome, FcMultipleInputs } from "react-icons/fc";
import { FaUser } from "react-icons/fa";
import { RxEnterFullScreen, RxExitFullScreen } from "react-icons/rx";
import { truncateWithEllipses } from '../utilities/stringHelper';
import UserDropdownMenu from './UserDropdownMenu';

function TopBarComponenent(props) {
    const { busy, setBusy } = React.useContext(AppContext);
    const [width, setWidth] = React.useState(window.innerWidth);
    const [rightCoords, setRightCoords] = React.useState(0);
    const UserDropdownMenuButtonRef = useRef()
    const { portrait } = React.useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);
    const { fullScreenHandle } = React.useContext(AppContext);
    const { isFullScreen } = React.useContext(AppContext);
    const { loggedIn } = React.useContext(AppContext);
    const { setShowAbout } = React.useContext(AppContext);
    const { activeUser } = React.useContext(AppContext);

    const [userMenuOpen, setUserMenuOpen] = React.useState(false);
    const [mouseOverUserMenuButton, setMouseOverUserMenuButton] = React.useState(false);

    const copyright = "Powered by Chapresoft ©2025";

    const mobile = isMobile;
    const appTitle = mobile ? `Webtrace Mobile CD` : `Webtrace CD`;
    const flagSize = mobile ? (portrait ? "10px" : "12px") : "18px";
    const textFontSize = mobile ? (portrait ? "8px" : "12px") : "14px";

    const awesomeSize = mobile ? 12 : 16;

    const refresh = () => {
        setBusy(true);
        showLoading(true);
        window.location.reload(false);
    }

    const updateRightCoords = () => {
        const cord = UserDropdownMenuButtonRef?.current?.getBoundingClientRect()?.right;
        if (cord) {
            setRightCoords(cord)
        }
        else {
            setRightCoords(0)
        }
    }


    const updateDimensions = () => {
        setWidth(window.innerWidth);
        updateRightCoords();
    }

    React.useEffect(() => {
        return () => {
            setShowAbout(false)
        }
    }, [])

    React.useEffect(() => {
        updateRightCoords();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const renderFullScreenButtons = () => {
        if (isFullScreen) {
            return (<button style={{ backgroundColor: "transparent", height: "100%" }} onClick={fullScreenHandle.exit}>
                <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <IconContext.Provider value={{ size: awesomeSize, color: 'whitesmoke' }}>
                        <RxExitFullScreen />
                    </IconContext.Provider>
                </div>
            </button>);
        }
        else {
            if (props.showNoFullScreen) {
                return null;
            }

            return (
                <button style={{ backgroundColor: "transparent", height: "100%" }} onClick={fullScreenHandle.enter}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize, color: 'whitesmoke' }}>
                            <RxEnterFullScreen />
                        </IconContext.Provider>
                    </div>
                </button>);
        }
    }

    const styler = loggedIn ?
        <style>
            {`svg path {stroke: green}`}
        </style> :
        null;

    const userMenuControlOptions = props?.userMenuControlOptions;
    return (
        <div style={{ backgroundColor: appTheme.themeColor, display: "flex", width: "100%", height: "100%", justifyContent: "space-evenly" }}>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "left", width: "100%" }} >
                <img src={logo} alt="logo" style={{ width: flagSize, height: flagSize, margin: "5px 5px" }} />
                <span style={{ color: "#e1e1dd", textAlign: "center", fontSize: textFontSize, alignSelf: "center" }}>{appTitle}</span>
            </div>
            <div style={{ alignItems: "center", display: "flex", width: "100%", justifyContent: "center" }}>
                <span style={{ color: "#e1e1dd", textAlign: "center", fontSize: textFontSize }}>{copyright}</span>
            </div>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "right", width: "100%" }}>
                {
                    userMenuControlOptions?.userMenuControl ?
                        <div ref={UserDropdownMenuButtonRef} style={{ marginRight: mobile ? "5px" : "50px" }}>
                            <button
                                onMouseLeave={() => {
                                    setMouseOverUserMenuButton(false);
                                }}
                                onMouseEnter={() => {
                                    setMouseOverUserMenuButton(true);
                                }}
                                onClick={() => {
                                    setUserMenuOpen(!userMenuOpen)
                                }}
                                style={{ borderRadius: "3px", backgroundColor: "transparent" }}>
                                <span style={{ alignItems: "center", display: "flex", justifyContent: "center", gap: "2px" }}>

                                    <IconContext.Provider value={{ size: awesomeSize, title: "Settings" }}>
                                        <FaUser />
                                    </IconContext.Provider>
                                    {
                                        !mobile &&
                                        <strong style={{ margin: "0px,5px" }}>
                                            {truncateWithEllipses(activeUser?.userName, 18)}
                                        </strong>
                                    }

                                </span>
                            </button>
                            {
                                userMenuOpen ?
                                    <UserDropdownMenu {...userMenuControlOptions}
                                        right={width - rightCoords}
                                        onMouseClickOutside={() => {
                                            setUserMenuOpen(false)
                                        }}

                                        mouseOverButon={mouseOverUserMenuButton}
                                    />
                                    : null
                            }
                        </div> :
                        null
                }
                {
                    props.showHome ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            if (props.onShowHome) {
                                props.onShowHome()
                            }
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcHome />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showScreenControl ?
                        renderFullScreenButtons() :
                        null
                }
                {
                    props.showInfo ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            setShowAbout(true)
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcInfo />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showRefresh ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={refresh}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcRefresh />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                {
                    props.showFullQuery ?
                        (<button style={{
                            backgroundColor: "transparent", height: "100%", borderRadius: "3px"
                        }} onClick={() => {
                            if (props.onShowFullQuery) {
                                props.onShowFullQuery()
                            }
                        }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <FcMultipleInputs />
                                </IconContext.Provider>
                            </div>
                        </button>) :
                        null
                }
                <img src={flag} alt="logo" style={{ width: flagSize, height: flagSize, alignSelf: "center", margin: "5px 5px" }} />
            </div>
        </div>);
}

export default TopBarComponenent;