import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import AccountListView from "./accounts/AccountListView";
import AssetsListView from "./assets/AssetsListView";
import ContactsListView from "./contacts/ContactsListView";
import UsersListView from "./users/UsersListView";
import UnitsListView from "./units/UnitsListView";
import ActivitiesListView from './activities/ActivitiesListView';
import EventsListView from './events/EventsListView';
import SimcardOperator from './simcards/SimcardOperator';

import TwilioListView from './twilio/TwilioListView';
import AdminSettingPage from './AdminSettingPage';

import isLogin from '../../calls/authentication/isLogin';
import TopBarComponenent from '../TopBarComponenent';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors'

import TimeDisplay from "../TimeDisplay/TimeDisplay"
import { admin } from '../../definitions/roles'
import hasRoleAuthority from '../../calls/administration/roles/hasRoleAuthority';
import RolesListView from './roles/RolesListView';

function Administration(props) {
    const navigate = useNavigate();
    const tabRef = React.useRef(null)
    const { setBusy } = useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);
    const [tabIndex, setTabIndex] = React.useState(0);

    const { isFullScreen } = React.useContext(AppContext);
    const [ready, setReady] = React.useState(false);

    const accountsTabIndex = 0;
    const usersTabIndex = 1;
    const assetsTabIndex = 2;
    const unitsTabIndex = 3;
    const contactsTabIndex = 4;
    const actvitiesTabIndex = 5;
    const eventsTabIndex = 6;
    const simcarOparatorTabIndex = 7;
    const twilioTabIndex = 8;
    const settingTabIndex = 9;
    const RolesTabIndex = 10;

    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "30px";

    const checkIfReady = () => {
        setBusy(true)
        showLoading(true)
        isLogin((e) => {
            if (e.error) {
                navigate("/home", { replace: true });
                setBusy(false)
                showLoading(false)
                return;
            }

            setReady(true);
        })
    }

    const verifyRoleAuthority = (onComplete) => {
        hasRoleAuthority((e) => {
            if (e.error) {
                navigate("/selector", { replace: true });
                setBusy(false)
                showLoading(false)
                return;
            }

            if (onComplete)
                onComplete()
        }, admin)
    }

    const buildActiveStyle = (active) => {
        return {
            minHeight: categoryButtonHeight,
            margin: "1px",
            borderRight: active ? "0px" : undefined,
            backgroundColor: active ? appTheme.themeColor : undefined,
            color: active ? "white" : undefined,
            fontWeight: active ? "bold" : undefined
        };
    }

    React.useEffect(() => {
        checkIfReady();
    }, []);

    React.useEffect(() => {
        if (ready) {
            verifyRoleAuthority(() => {

            })
        }
    }, [ready]);

    if (!ready) {
        return null;
    }
    const categoryButtonHeight = "60px";
    const categoryButtonMargin = "2px 0px";
    const userTabActive = tabIndex === usersTabIndex;
    return (

        <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "column" }}>
            <div style={{ width: "100%", height: barHeight, display: "flex", backgroundColor: appTheme.themeColor }}>
                <TopBarComponenent
                    showInfo={true}
                    showScreenControl={true}
                    userMenuControlOptions={{
                        userMenuControl: true,
                        showUserMenuDashboard: true
                    }} />
            </div>
            <div style={{ height: "100%", width: "100%", display: "flex", flex: 1, }}>
                <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "row" }}>
                    <div ref={tabRef} style={{ width: "200px", height: "100%", display: "flex", flexDirection: "column" }}>
                        <div style={{ width: "100%", minHeight: "30px", display: "flex", padding: "4px 5px" }}>
                            {"Categories"}
                        </div>
                        <div ref={tabRef} style={{ width: "100%", height: "100%", display: "flex", position: "relative" }}>
                            <div style={{
                                width: "100%", height: "100%", display: "flex", flexFlow: "column", overflowY: "auto", position: "absolute", direction: "rtl"
                            }}>
                                <button
                                    style={buildActiveStyle(tabIndex === accountsTabIndex)}
                                    onClick={() => {
                                        setTabIndex(accountsTabIndex);
                                    }}>
                                    Accounts
                                </button>
                                <button
                                    style={buildActiveStyle(tabIndex === usersTabIndex)}
                                    onClick={() => {
                                        setTabIndex(usersTabIndex);
                                    }}>
                                    Users
                                </button>
                                <button
                                    style={buildActiveStyle(tabIndex === assetsTabIndex)}
                                    onClick={() => {
                                        setTabIndex(assetsTabIndex);
                                    }}>Assets</button>
                                <button
                                    style={buildActiveStyle(tabIndex === unitsTabIndex)}
                                    onClick={() => {
                                        setTabIndex(unitsTabIndex);
                                    }}>Units</button>
                                <button
                                    style={buildActiveStyle(tabIndex === contactsTabIndex)}
                                    onClick={() => {
                                        setTabIndex(contactsTabIndex);
                                    }}>Contacts</button>
                                <button
                                    style={buildActiveStyle(tabIndex === actvitiesTabIndex)}
                                    onClick={() => {
                                        setTabIndex(actvitiesTabIndex);
                                    }}>Activities</button>
                                <button
                                    style={buildActiveStyle(tabIndex === eventsTabIndex)}
                                    onClick={() => {
                                        setTabIndex(eventsTabIndex);
                                    }}>Events</button>
                                <button
                                    style={buildActiveStyle(tabIndex === simcarOparatorTabIndex)}
                                    onClick={() => {
                                        setTabIndex(simcarOparatorTabIndex);
                                    }}>Simcard operator
                                </button>
                                <button
                                    style={buildActiveStyle(tabIndex === twilioTabIndex)}
                                    onClick={() => {
                                        setTabIndex(twilioTabIndex);
                                    }}>Twilio requests
                                </button>
                                <button
                                    style={buildActiveStyle(tabIndex === settingTabIndex)}
                                    onClick={() => {
                                        setTabIndex(settingTabIndex);
                                    }}>Settings
                                </button>
                                <button
                                    style={buildActiveStyle(tabIndex === RolesTabIndex)}
                                    onClick={() => {
                                        setTabIndex(RolesTabIndex);
                                    }}>Roles
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        backgroundColor: "lightgray",
                        height: "98%", width: "2px",
                        margin: "0px 5px", display: "flex", alignSelf: "center"
                    }}>

                    </div>
                    <div style={{ width: "100%", height: "100%", display: "flex", flex: 1, flexDirection: "column" }}>
                        <div style={{ height: "2px", width: "100%", margin: "4px 0px", backgroundColor: "lightgray" }} />
                        <div style={{ width: "100%", height: "100%", display: "flex", flex: 1, position: "relative" }}>
                            <div style={{
                                width: "100%", height: "100%", position: "absolute"
                            }}>
                                {
                                    userTabActive &&
                                    <UsersListView />
                                }
                                {
                                    tabIndex === accountsTabIndex &&
                                    <AccountListView></AccountListView>
                                }
                                {
                                    tabIndex === assetsTabIndex &&
                                    <AssetsListView />
                                }
                                {
                                    tabIndex === unitsTabIndex &&
                                    <UnitsListView />
                                }
                                {
                                    tabIndex === contactsTabIndex &&
                                    <ContactsListView />
                                }
                                {
                                    tabIndex === actvitiesTabIndex &&
                                    <ActivitiesListView />
                                }
                                {
                                    tabIndex === eventsTabIndex &&
                                    <EventsListView />
                                }
                                {
                                    tabIndex === simcarOparatorTabIndex &&
                                    <SimcardOperator />
                                }
                                {
                                    tabIndex === twilioTabIndex &&
                                    <TwilioListView />
                                }
                                {
                                    tabIndex === RolesTabIndex &&
                                    <RolesListView />
                                }
                                {
                                    tabIndex === settingTabIndex &&
                                    <AdminSettingPage />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{
                height: barHeight, backgroundColor: appTheme.themeColor, display: 'flex',
                width: "100%",
                justifyContent: "center",
                position: "relative"
            }}>
                {isFullScreen && <TimeDisplay />}
            </div>
        </div>);
}

export default Administration;