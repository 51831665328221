import { appConfig } from '../configurations/appConfig'
import urlJoin from 'url-join';
const buildAssetColorIconUrl = (assetTypeName, fill) => {
    if (fill)
        fill = fill.replace("#", "")
    const method = `AssetTypes/download-icon/${assetTypeName}_${fill}.svg`;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    return urlJoin(server, serverBasePath, method);
}

const buildCustomColorIconUrl = (iconName, fill, stroke) => {
    if (!fill)
        fill = 'black'
    if (!stroke)
        stroke = 'black'
    if (fill)
        fill = fill.replace("#", "")
    const method = `AssetTypes/download-custom-icon/${iconName}_${fill}_${stroke}.svg`;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    return urlJoin(server, serverBasePath, method);
}

const buildSelectionIconUrl = (frameFill, selectionAreaFill, selectionAreaOpacity) => {
    if (!frameFill)
        frameFill = 'black'
    if (!selectionAreaFill)
        selectionAreaFill = 'transparent'
    if (!selectionAreaOpacity)
        selectionAreaOpacity = 1.0

    const method = `AssetTypes/download-selection-icon/${frameFill}_${selectionAreaFill}_${selectionAreaOpacity}.svg`;
    const server = appConfig.data.server;
    const serverBasePath = appConfig.data.serverBasePath;
    return urlJoin(server, serverBasePath, method);
}

export { buildAssetColorIconUrl, buildCustomColorIconUrl, buildSelectionIconUrl }