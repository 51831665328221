import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";
import { createAccount, updateAccount } from "../../../calls/administration/accounts/createAccount";
import DropdownList from "react-widgets/DropdownList";
import { showLoading } from 'react-global-loading';
import fetchAccounts from "../../../calls/administration/accounts/fetchAccounts";
import fetchUsers from '../../../calls/administration/users/fetchUsers';

function AccountCreation(props) {
    const [name, setName] = React.useState("");
    const [error, setError] = React.useState("");

    const [ownerUsers, setOwnerUsers] = React.useState([]);
    const [selectedOwnerUser, setSelectedOwnerUser] = React.useState(null);
    const [ownerUserSearchTerm, setOwnerUserSearchTerm] = React.useState('');
    const [parentAccounts, setParentAccounts] = React.useState([]);
    const [parentAccountSelected, setParentAccountSelected] = React.useState(null);
    const [parentAccountSearchTerm, setParentAccountSearchTerm] = React.useState('');

    const [fuelNotification, setFuelNotification] = React.useState(false);
    const [accidentNotification, setAccidentNotification] = React.useState(false);
    const [ready, setReady] = React.useState(false);

    const editAccount = props.options?.editAccount;
    const open = props.options?.open;

    const updateAccounts = (onResult, filter) => {
        showLoading(true)
        fetchAccounts((e) => {
            showLoading(false)
            if (e.error) {
                if (onResult) onResult(false);
                return;
            }

            setParentAccounts(e?.data?.data);
            if (onResult) onResult(true, e?.data?.data);
        }, filter);
    }

    const updateUsers = (silent = true) => {
        fetchUsers((e) => {
            if (e.error) {
                return;
            }

            setOwnerUsers(e?.data?.data);
        });
    }

    React.useEffect(() => {
        const filter = {
            count: 50,
            additionalIds: editAccount ? [editAccount.id] : null
        };

        updateAccounts((status, accounts) => {
            setReady(true)
            if (editAccount) {
                setName(editAccount.name)
                setAccidentNotification(editAccount.allowAccidentNotification)
                setFuelNotification(editAccount.allowFuelNotification)
                if (accounts) {
                    const selected = accounts.find(x => x.id === editAccount.parentId);
                    setParentAccountSelected(selected);
                }
            }
        }, filter);

        updateUsers();

        return () => {
            setError("")
            setName("")
            setAccidentNotification(false)
            setFuelNotification(false)
        }
    }, []);


    if (!ready) {
        return null;
    }
    return <form open={open}
        style={{
            backgroundColor: "white",
            padding: "8px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
        }}>
        <div style={{ display: "flex", flexDirection: "column", padding: "8px", gap: "1px" }}>
            <div style={{ textAlign: "right" }}>
                {editAccount ? `Account edition (${editAccount.id})` : "Account creation"}
            </div>
            <span style={{ fontSize: "10px", textDecorationLine: "underline", textAlign: "left", color: "GrayText" }}>
                Information du compte
            </span>
            <div style={{ display: "flex", margin: "5px 0px", alignItems: "center" }}>
                <label style={{ display: "block", width: "150px", textAlign: "left" }}
                    htmlFor="account_creation_name">Nom de compte</label>
                <input
                    style={{ display: "block", width: "100%", flex: 1 }}
                    maxlength="80"
                    autoComplete="off"
                    //onFocus={(e) => { e.target.setAttribute("autoComplete", "none") }}
                    type="text" id="account_creation_name" placeholder="Nom du compte" value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", alignItems: "center" }}>
                <label style={{ display: "block", width: "150px", textAlign: "left" }} htmlFor="asset_creation_account">Parent Account</label>
                <span style={{ display: "flex", width: "100%", alignItems: "baseline", flex: 1 }}>
                    <DropdownList
                        id="asset_creation_account"
                        style={{ display: "block", width: "100%" }}
                        data={parentAccounts}
                        dataKey='id'
                        textField='name'
                        filter={false}
                        value={parentAccountSelected}
                        readOnly={editAccount}
                        searchTerm={parentAccountSearchTerm}
                        onSearch={(e) => {
                            setParentAccountSearchTerm(e);
                            const filter = {
                                count: 50,
                                searchTerm: e,
                                additionalIds: editAccount ? [editAccount.id] : null
                            };

                            updateAccounts(() => {
                            }, filter);
                        }}
                        onChange={(value) => {
                            setParentAccountSelected(value);
                        }} />
                    {
                        !editAccount &&
                        parentAccountSelected &&
                        <button
                            onClick={() => { setParentAccountSelected(null); }}
                            style={{ display: "block", height: "20px", marginLeft: "5px", borderColor: "lightgray" }}
                        >
                            X
                        </button>
                    }
                </span>
            </div>
            <div style={{ display: "flex", margin: "5px 0px", alignItems: "center" }}>
                <label style={{ display: "block", width: "150px", textAlign: "left" }} htmlFor="asset_creation_account">Existing Owner</label>
                <span style={{ display: "flex", width: "100%", alignItems: "baseline", flex: 1 }}>
                    <DropdownList
                        id="asset_creation_account"
                        style={{ display: "block", width: "100%" }}
                        data={ownerUsers}
                        dataKey='id'
                        textField='userName'
                        filter={false}
                        value={selectedOwnerUser}
                        readOnly={editAccount}
                        searchTerm={ownerUserSearchTerm}
                        onSearch={(e) => {
                            setOwnerUserSearchTerm(e);
                            const filter = {
                                count: 50,
                                searchTerm: e,
                                additionalIds: editAccount ? [editAccount.id] : null
                            };
                        }}
                        onChange={(value) => {
                            setSelectedOwnerUser(value);
                        }} />
                    {
                        !editAccount &&
                        selectedOwnerUser &&
                        <button
                            onClick={() => { setSelectedOwnerUser(null); }}
                            style={{ display: "block", height: "20px", marginLeft: "5px", borderColor: "lightgray" }}
                        >
                            X
                        </button>
                    }
                </span>
            </div>
            <span style={{ fontSize: "10px", textDecorationLine: "underline", textAlign: "left", color: "GrayText" }}>
                configuation notifications
            </span>
            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "180px", textAlign: "left" }} htmlFor="account_creation_fuel_notification">Fuel notification</label>
                <input
                    style={{ display: "block", width: "100%", flex: 1 }}
                    type="checkbox" id="account_creation_fuel_notification"
                    checked={fuelNotification}
                    onChange={(e) => {
                        setFuelNotification(!fuelNotification)
                    }} />
            </div>

            <div style={{ display: "flex", margin: "5px 0px" }}>
                <label style={{ display: "block", width: "180px", textAlign: "left" }} htmlFor="account_creation_accident_notification">Accident notification</label>
                <input
                    style={{ display: "block", width: "100%", flex: 1 }}
                    type="checkbox" id="account_creation_accident_notification"
                    checked={accidentNotification}
                    onChange={(e) => {
                        setAccidentNotification(!accidentNotification)
                    }} />
            </div>
            {
                checkIfEmptyOrWhiteSpace(error) ?
                    null :
                    <div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "12px" }}>
                        {error}
                    </div>
            }
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={(e) => {
                    e.preventDefault();
                    if (checkIfEmptyOrWhiteSpace(name)) {
                        setError("Account name cannot be empty")
                        return;
                    }
                    if (editAccount) {
                        const accountId = editAccount.id
                        const data = {
                            name: name,
                            //ownerId: selectedOwnerUser?.id ?? null,
                            fuelNotification: fuelNotification,
                            accidentNotification: accidentNotification
                        };

                        showLoading(true)
                        updateAccount((e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props?.onAccountEdited)
                                props.onAccountEdited(e.data)
                        }, accountId, data);
                    }
                    else {
                        const data = {
                            name: name,
                            ownerId: selectedOwnerUser?.id ?? null,
                            parentAccountId: parentAccountSelected ? parentAccountSelected.id : null,
                            fuelNotification: fuelNotification,
                            accidentNotification: accidentNotification
                        };

                        showLoading(true)
                        createAccount((e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (!props?.onAccountCreated) {
                                return;
                            }

                            props?.onAccountCreated(e.data)
                        }, data);
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </form>
}

export default AccountCreation;