import React from 'react'
import { AppContext } from "../../AppContext";
import { isMobile } from 'react-device-detect';
import { lang } from '../../language/lang';
import { hexToRgbaWithAlpha } from '../../utilities/convertColors';
function NoInternet() {
    const { online } = React.useContext(AppContext);

    const mobile = isMobile;
    const fontSize = mobile ? 15 : 20;
    const message = lang?.language?.NoIternetWorning;
    if (online) {
        return null;
    }

    const color = hexToRgbaWithAlpha("#dc3545", 0.75);

    return (
        <dialog open={true} style={{ padding: '15px', color: "white", backgroundColor: color, whiteSpace: "pre-line", fontSize: fontSize, fontWeight: "bold", borderWidth: "0px", zIndex: 20 }}>
            {message}
        </dialog>
    );
}

export default NoInternet;