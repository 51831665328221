import { localTimeFormat } from '../../../utilities/timeHelper'
import React from 'react'
import { showLoading } from 'react-global-loading';
import fetchAccounts from "../../../calls/administration/accounts/fetchAccounts";
import fetchFakeAccounts from '../../../calls/administration/accounts/fetchFakeAccounts';
import fetchAccountContacts from '../../../calls/administration/accounts/fetchAccountContacts';
import retrieveAccountNotificationSettings from '../../../calls/administration/accounts/retrieveAccountNotificationSettings';
import updateAccountNotificationSettings from '../../../calls/administration/accounts/updateAccountNotificationSettings';
import AccountCreation from "./AccountCreation"
import ConfirmBox from '../ConfirmBox';
import AccountSetup from './AccountSetup';
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';
import { deleteAccount } from '../../../calls/administration/accounts/createAccount';
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import { isMobile } from 'react-device-detect';
import './AccountListView.css';
import Paginator from '../../Paginator/Paginator';
import SearchFilter from '../../SearchFilter/SearchFilter';
import { useRefClickOutside } from '../../../utilities/useClickOutside';


function AccountListView(props) {
    const [accounts, setAccounts] = React.useState([]);
    const [data, setData] = React.useState(null);
    const [selectedAccount, setSelectedAccount] = React.useState(null);
    const [accountCreation, setAccountCreation] = React.useState(null);
    const [busy, setBusy] = React.useState(true);
    const [accountDeleteData, setAccountDeleteData] = React.useState(null);
    const [viewContactsData, setViewContactsData] = React.useState(null);
    const [viewContactNotificationData, setViewContactNotificationData] = React.useState(null);
    const [accountSetup, setAccountSetup] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState("");
    const outsideDivRef = React.useRef(null);
    const outsideExceptionDivRef = React.useRef(null);
    const viewContactNotificationRef = React.useRef(null);
    const setupAccountDivRef = React.useRef(null);
    const clickOutside = useRefClickOutside({
        ref: outsideDivRef,
        exceptionRefs: [outsideExceptionDivRef, viewContactNotificationRef, setupAccountDivRef]
    });

    const updateAccounts = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        const filter = {
            pageNumber: pageNumber.value,
            search: searchFilter?.value ?? "",
            pageSize: 40
        }

        fetchAccounts((e) => {
            setBusy(false);
            showLoading(false);
            if (e.error) {
                return;
            }

            const accounts = e?.data.data;
            setData(e?.data)
            setAccounts(accounts);
        }, filter);
    }

    React.useEffect(() => {
        updateAccounts(false);
    }, []);

    React.useEffect(() => {
        if (clickOutside) {
            setSelectedAccount(null)
        }
    }, [clickOutside]);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateAccounts(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateAccounts(true);
        }
    }, [searchFilter]);

    if (busy) return null;
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div id='topbar' style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table ref={outsideDivRef} style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr style={{ cursor: "default" }}>
                                    <th style={{ textAlign: "left", }}>Name</th>
                                    <th style={{ textAlign: "left", }}>Owner</th>
                                    <th style={{ textAlign: "left", }}>Parent Account</th>
                                    <th style={{ textAlign: "left", }}>Active</th>
                                    <th style={{ textAlign: "left" }}>Updated</th>
                                    <th style={{ textAlign: "left" }}>Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    accounts &&
                                    accounts.map((account, index) => {
                                        const finalAccount = account;
                                        const isSelected = (selectedAccount && finalAccount.id === selectedAccount?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent", cursor: "default" }}
                                                onClick={() => {
                                                    setSelectedAccount(finalAccount)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalAccount.name}</td>
                                                <td style={{ textAlign: "left" }}>{finalAccount.owner ?? '__'}</td>
                                                <td style={{ textAlign: "left" }}>{finalAccount.parent ? finalAccount.parent.name : "None"}</td>
                                                <td style={{ textAlign: "left", color: finalAccount.active ? "green" : "red" }}>{String(finalAccount.active)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{localTimeFormat(finalAccount.updatedAt)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{localTimeFormat(finalAccount.createdAt)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    Boolean(data?.pageCount && data?.pageCount > 1) &&
                    <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                        <Paginator
                            changeIndicator={data}
                            initialNumber={pageNumber.value}
                            pageSize={data?.pageSize}
                            pageCount={data?.pageCount}
                            count={data?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }} />
                    </div>
                }
            </div>
            <div ref={outsideExceptionDivRef} style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    onClick={() => {
                        setSelectedAccount(null)
                        setAccountCreation({
                            open: true,
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        if (selectedAccount) {
                            setAccountCreation({
                                open: true,
                                editAccount: selectedAccount
                            });
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setAccountDeleteData({
                            open: true,
                            deleteAccount: selectedAccount
                        })
                    }}
                >
                    Delete
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setViewContactsData({
                            open: true,
                            account: selectedAccount
                        })
                    }}>
                    View contacts
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setViewContactNotificationData({
                            open: true,
                            account: selectedAccount
                        })
                    }}>
                    Contacts notifications
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedAccount}
                    onClick={() => {
                        setAccountSetup({
                            open: true,
                            account: selectedAccount
                        })
                    }}>
                    Setup Account
                </button>
            </div>
            {accountDeleteData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    open={accountDeleteData?.open}
                    title="Account deletion"
                    onCancel={() => {
                        setAccountDeleteData(null)
                    }}
                    onConfirm={() => {
                        const account = accountDeleteData.deleteAccount;
                        const accountId = account.id;
                        if (!accountId) return
                        setAccountDeleteData(null)
                        showLoading(true);
                        deleteAccount((e) => {
                            showLoading(false);
                            if (e.error) {

                                return
                            }

                            updateAccounts(false)
                        }, accountId);
                    }}
                    message={`You are about to delete account ${accountDeleteData?.deleteAccount?.name}`} />
            </div>}
            {
                viewContactsData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ViewAccountContacts account={viewContactsData.account} onClose={() => {
                        setViewContactsData(null)
                    }}></ViewAccountContacts>
                </div>
            }
            {
                accountCreation?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    justifyContent: "center",
                    alignItems: "center",
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <AccountCreation
                        options={accountCreation}
                        onAccountCreated={(data) => {
                            setAccountCreation(null);
                            updateAccounts(false);
                        }}
                        onAccountEdited={(data) => {
                            setAccountCreation(null);
                            updateAccounts(false);
                        }}
                        onCancel={() => {
                            setAccountCreation(null);
                        }} />
                </div>
            }
            {
                viewContactNotificationData?.open && <div ref={viewContactNotificationRef} style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ViewContactsNotifications account={viewContactNotificationData?.account} onClose={() => {
                        setViewContactNotificationData(null)
                    }}></ViewContactsNotifications>
                </div>
            }
            {
                accountSetup?.open && <div ref={setupAccountDivRef} style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <AccountSetup account={accountSetup?.account} onClose={() => {
                        setAccountSetup(null)
                    }}></AccountSetup>
                </div>
            }
        </div>);
}

function ViewAccountContacts(props) {
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [busy, setBusy] = React.useState(true);
    const [contacts, setContacts] = React.useState([]);
    const refrehAccountCotancts = () => {
        setBusy(true);
        showLoading(true);

        fetchAccountContacts((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }

            setContacts(e?.data?.contacts);
        }, props.account.id);
    };

    React.useEffect(() => {
        refrehAccountCotancts();
    }, []);
    if (busy) return null;
    return <dialog open={true}
        style={{
            top: "50%",
            bottom: "50%",
            marginRight: "auto",
            marginLeft: "auto",
            padding: "4px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"

        }}>
        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div style={{ width: "100%", display: "flex", flex: 1 }}>
                    Contact for {props.account.name}
                </div>
                <button onClick={() => {
                    if (props.onClose) {
                        props.onClose();
                    }
                }}>close</button>
            </div>

            <div style={{ width: "100%", height: "100%", position: "relative", flex: 1 }}>
                {
                    contacts &&
                    <table style={{ width: "100%" }}>
                        {
                            contacts.map((contact, index) => {
                                const finalContact = contact;
                                const isSelected = (selectedContact && finalContact.id === selectedContact?.id);
                                return (
                                    <tr key={index}
                                        style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                            setSelectedContact(finalContact)
                                        }}>
                                        <td style={{ textAlign: "left" }}>{finalContact.name}</td>
                                        <td style={{ textAlign: "left" }}>{finalContact.email}</td>
                                        <td style={{ textAlign: "left" }}>{finalContact.number}</td>
                                    </tr>);
                            })}
                    </table>
                }
            </div>
        </div>
    </dialog>
}

function ViewContactsNotifications(props) {
    const [account, setAccount] = React.useState(null);

    const [fuelNotification, setFuelNotification] = React.useState(false);
    const [accidentNotification, setAccidentNotification] = React.useState(false);
    const [inputOutputNotification, setInputOutputNotification] = React.useState(false);
    const [contacts, setContacts] = React.useState([]);
    const [selectedContact, setSelectedContact] = React.useState(null);
    const [busy, setBusy] = React.useState(true);

    const refreshAccountNotificationSettings = () => {
        setBusy(true);
        showLoading(true);

        retrieveAccountNotificationSettings((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }

            const ac = e?.data?.account;
            setAccount(e?.data?.account)
            setContacts(e?.data?.contacts)
            setAccidentNotification(ac?.allowAccidentNotification)
            setFuelNotification(ac?.allowFuelNotification)
            setInputOutputNotification(ac?.allowInputOutputNotification)
        }, props.account.id);
    }

    const ApplyAccountNotificationSettings = (notificationsData) => {
        setBusy(true);
        showLoading(true);

        updateAccountNotificationSettings((e) => {
            setBusy(false);
            showLoading(false);

            if (e.error) {
                return;
            }
        }, notificationsData);
    }

    React.useEffect(() => {
        refreshAccountNotificationSettings();
    }, []);

    if (busy) return null;
    const awesomeSize = isMobile ? 18 : 14;
    return (
        <dialog open={true}
            style={{
                top: "50%", bottom: "50%", marginRight: "auto", marginLeft: "auto",
                padding: "10px", borderWidth: "1px", borderRadius: "5px", minWidth: "450px"
            }}>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Notification Settings for Account {props.account.name}
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: awesomeSize }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px 15px 0px" }}></hr>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ textAlign: "left", textDecorationLine: "underline" }}>
                        Account
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="fuel_notification_label">
                            Allow fuel notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="fuel_notification_label"
                            checked={fuelNotification}
                            onChange={(e) => {
                                setFuelNotification(e.target.checked)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="accident_notification_label">
                            Allow accident notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="accident_notification_label"
                            checked={accidentNotification}
                            onChange={(e) => {
                                setAccidentNotification(e.target.checked)
                            }} />
                    </div>
                    <div style={{ display: "flex", margin: "5px 0px" }}>
                        <label style={{ display: "block", width: "250px", textAlign: "left" }} htmlFor="io_notification_label">
                            Allow I/O notification
                        </label>
                        <input
                            style={{ display: "block", width: "100%", flex: 1 }}
                            type="checkbox" id="io_notification_label"
                            checked={inputOutputNotification}
                            onChange={(e) => {
                                setInputOutputNotification(e.target.checked)
                            }} />
                    </div>
                </div>
                {/* <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px 2px 0px" }}></hr> */}
                <div style={{ width: "100%", height: "100%", position: "relative", flex: 1 }}>
                    <div style={{ textAlign: "left", textDecorationLine: "underline", color: "purple" }}>
                        Contacts
                    </div>
                    {
                        contacts &&
                        contacts.map((contact, index) => {
                            const finalContact = contact;
                            const isSelected = (selectedContact && finalContact.id === selectedContact?.id);
                            return (
                                <tr key={index}
                                    style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                        setSelectedContact(finalContact)
                                    }}>
                                    <td style={{ textAlign: "left" }}>{finalContact.name}</td>
                                    <td style={{ textAlign: "left" }}>
                                        <input
                                            style={{ display: "block", width: "100%", flex: 1 }}
                                            type="checkbox"
                                            checked={finalContact.allowFuelNotification}
                                            disabled={!fuelNotification}
                                            onChange={(e) => {
                                                const newContacts = [...contacts];
                                                var entry = newContacts.find(x => x.id == finalContact.id);
                                                if (!entry) return;
                                                entry.allowFuelNotification = e.target.checked
                                                setContacts(newContacts)
                                            }} ></input>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                        <input
                                            style={{ display: "block", width: "100%", flex: 1 }}
                                            type="checkbox"
                                            checked={finalContact.allowAccidentNotification}
                                            disabled={!accidentNotification}
                                            onChange={(e) => {
                                                const newContacts = [...contacts];
                                                var entry = newContacts.find(x => x.id == finalContact.id);
                                                if (!entry) return;
                                                entry.allowAccidentNotification = e.target.checked
                                                setContacts(newContacts)
                                            }} ></input>
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                        <input
                                            style={{ display: "block", width: "100%", flex: 1 }}
                                            type="checkbox"
                                            checked={finalContact.allowInputOutputNotification}
                                            disabled={!inputOutputNotification}
                                            onChange={(e) => {
                                                const newContacts = [...contacts];
                                                var entry = newContacts.find(x => x.id == finalContact.id);
                                                if (!entry) return;
                                                entry.allowInputOutputNotification = e.target.checked
                                                setContacts(newContacts)
                                            }} ></input>
                                    </td>
                                </tr>);
                        })}
                </div>
                <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>

                    </div>
                    <button onClick={() => {
                        var result = [];
                        for (var contact of contacts) {
                            result.push({
                                contactId: contact.id,
                                allowFuelNotification: contact.allowFuelNotification ?? false,
                                allowAccidentNotification: contact.allowAccidentNotification ?? false,
                                allowInputOutputNotification: contact.allowInputOutputNotification ?? false,
                            })
                        }

                        ApplyAccountNotificationSettings({
                            accountId: account?.id,
                            allowFuelNotification: fuelNotification,
                            allowAccidentNotification: accidentNotification,
                            allowInputOutputNotification: inputOutputNotification,
                            contacts: result
                        });

                    }}>Apply</button>
                </div>
            </div>
        </dialog>);
}

export default AccountListView;