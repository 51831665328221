import React from 'react'
import { useEffect, useContext } from 'react';
import dateFormat from "dateformat";
import GoogleMapComponent from '../GoogleMapComponent';
import TimeFrameComponent from '../TimeFrameComponent';
import EventsGridComponent from '../EventsGridComponent';
import UserPanelComponent from '../UserPanelComponent';
import TopBarComponenent from '../TopBarComponenent';
import TimeDisplay from '../TimeDisplay/TimeDisplay';
import AccountBar from '../toolbars/AccountBar';
import GridBar from '../toolbars/GridBar';

import fetchRequestCurrentPosition from '../../calls/positions/fetchRequestCurrentPosition'
import fetchLastPositions from '../../calls/positions/fetchLastPositions';
import fetchPositions from '../../calls/positions/fetchPositions';
import fetchActiveAccountTree from '../../calls/accounts/fetchActiveAccountTree';
import isLogin from '../../calls/authentication/isLogin';
import fetchActiveAssets from '../../calls/assets/fetchActiveAssets'
import fetchGoogleApiKey from '../../calls/identity/fetchGoogleApiKey';
import { convertTimeSent, toLocalTime } from '../../utilities/timeHelper'
import { getGoogleMapLocationsCenter } from '../../utilities/googleMapHelper'
import { AppContext } from "../../AppContext";
import { showLoading } from 'react-global-loading';
import Notificator from '../menus/Notificator';
import { lang } from '../../language/lang';

import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import UpdaterDialog from '../dialogs/UpdaterDialog';
import FtpReportDialog from '../ftp/FtpReportDialog';
import { useSearchParams } from 'react-router-dom';
import { buildAssetColorIconUrl, buildCustomColorIconUrl } from '../../utilities/assetHelper'
import { miniIconSize, iconSize } from '../../configurations/mapConfig';
import { extraIconIndexLayer } from '../../configurations/mapConfig';
import SearchFilter from '../SearchFilter/SearchFilter';
import Paginator from '../Paginator/Paginator';
import useLocalStorage from '../../utilities/useLocalStorage';
import './WebtraceDashboard.css'

function WebtraceDashboard(props) {
    const navigate = useNavigate();
    const { googleApiKey, setGoogleApiKey } = React.useContext(AppContext);
    const { busy, setBusy } = useContext(AppContext);
    const { accountBarData, setAccountBarData } = React.useContext(AppContext);
    const { gridBarData } = React.useContext(AppContext);
    const { setSelectionWarning } = React.useContext(AppContext);
    const { setGridSelectedRowId } = React.useContext(AppContext);

    const { activeUser } = React.useContext(AppContext);
    const { appTheme } = useContext(AppContext);


    const [zoom, setZoom] = React.useState(15);
    const { portrait } = React.useContext(AppContext);
    const [mapData, setMapData] = React.useState({});
    const [eventsGridData, setEventsGridData] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [selectedAccount, setSelectedAccount] = React.useState(null);

    const [userActionResizeData, setUserActionResizeData] = React.useState({ width: 250 });
    const [gridResizeData, setGridResizeData] = React.useState({ height: 160 });

    const [activeAccounts, setActiveAccounts] = React.useState([]);
    const [activeAssets, setActiveAssets] = React.useState([]);
    const [ready, setReady] = React.useState(false);
    const { isFullScreen } = React.useContext(AppContext);

    const [setShowAbout] = React.useState(false);
    const [updaterData, setUpdaterData] = React.useState(null);
    const [ftpReportData, setFtpReportData] = React.useState(null);

    const [positionsPaginationData, setPositionsPaginationData] = React.useState(null);
    const [currentRange, setCurrentRange] = React.useState(null);

    const [searchFilter, setSearchFilter] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [userLocalData, setUserLocalData] = useLocalStorage(activeUser?.id);


    const midlleContainerDivRef = React.useRef(null);
    const eventGridRef = React.useRef(null);
    const topBarDivRef = React.useRef(null);
    const bottomBarDivRef = React.useRef(null);
    const AssetDivRef = React.useRef(null);
    const AccountDivRef = React.useRef(null);
    const notificatorRef = React.useRef(null);
    const dataGridEventsGridRef = React.useRef(null);
    const googleMapCompRef = React.useRef(null);
    const timeFramePanelRef = React.useRef(null);
    const searchFilterRef = React.useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();

    const ensureGoogleLoaded = () => {
        return new Promise(waitForGoogle);
        function waitForGoogle(resolve, reject) {
            if (window.google && window.google.maps) {
                resolve(window.google.maps);
            }
            else {
                if (navigator.geolocation) {
                    navigator.geolocation.watchPosition(function (position) {
                        console.log('latitude >>>>>>>>', position.coords.latitude);
                        console.log('longitude >>>>>>>>', position.coords.longitude);
                    });
                }
                setTimeout(waitForGoogle.bind(this, resolve, reject), 100);
            }
        }
    }

    const retrieveLastPositions = (assetId) => {
        setPositionsPaginationData(null)
        setBusy(true);
        showLoading(true);
        const filter = {
            assetId: assetId,

        };

        fetchLastPositions((e) => {
            showLoading(false);
            setBusy(false);

            if (e.error) {
                let m = { ...mapData };
                m.userError = e.error;
                m.markers = [];
                m.pathCoordinates = [];
                setMapData(m);
                setEventsGridData([]);
                return;
            }

            const p = e.data;
            var time = toLocalTime(p.timeSent);
            var date = dateFormat(time, "dd mmm yyyy");
            var hour = dateFormat(time, "HH:MM:ss");

            const gridEvent = {
                key: p.id,
                date: date,
                hour: hour,
                asset: p.asset,
                assetType: p.assetType,
                assetTypeIconColor: p?.assetTypeIconColor,
                event: p?.eventDescription,
                eventCode: p?.event,
                heading: p.heading,
                speed: p.speed,
                location: p.locationText,
                state: p.speed > 0 ? lang.language.moving : lang.language.stationary,
                timesent: convertTimeSent(p.timeSent),
                timeIndex: time.getTime(),
                idx: 1,
                id: p.id,
                positionData: p
            };

            const marker = {
                id: p.id,
                type: "map",
                positionData: p,
                position: { lat: p.latitude, lng: p.longitude },
                icon: {
                    url: buildAssetColorIconUrl(p.assetType, p.assetTypeIconColor),
                    origin: new window.google.maps.Point(0, 0),
                    size: new window.google.maps.Size(iconSize, iconSize),
                    scaledSize: new window.google.maps.Size(iconSize, iconSize)
                },
                title: `${p.asset}\r\n${p.locationText}\r\n${date}, ${hour}\r\n${p.latitude}, ${p.longitude}`,
                onClick: () => {
                    const map = googleMapCompRef.current.getInternalMap();
                    googleMapCompRef.current.setMarkerSelection({
                        id: p.id
                    });
                    setGridSelectedRowId(p.id);
                    dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                    if (map) {
                        map.panTo(marker.position);
                    }
                }
            };

            const coordinate = { lat: p.latitude, lng: p.longitude, id: p.id };
            const positionCenter = { lat: p.latitude, lng: p.longitude };

            setEventsGridData([gridEvent]);
            setMapData({
                center: positionCenter,
                markers: [marker],
                pathCoordinates: [coordinate],
            });
        }, filter)
    };

    const retrievePositions = (assetId, range) => {
        setBusy(true);
        setPositionsPaginationData(null)
        showLoading(true);
        const filter = {
            assetId: assetId,
            end: range.end,
            start: range.start,
            pageNumber: pageNumber?.value
        }

        fetchPositions((e) => {
            showLoading(false);
            setBusy(false);
            if (e.error) {
                const m = { ...mapData };
                m.userError = e.error;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }

            const positions = e.data.data;
            setPositionsPaginationData(e.data)

            const gridEvents = [];
            const markers = [];
            const markerExtras = []
            const pathCoordinates = [];
            for (const p of positions) {

                var time = toLocalTime(p.timeSent);
                var date = dateFormat(time, "dd mmm yyyy");
                var hour = dateFormat(time, "HH:MM:ss");
                var state = p.speed > 0 ? lang.language.moving : lang.language.stationary;
                const timeIndex = time.getTime();
                const eventDesc = p.fuelLevel ? `${p?.eventDescription} (${p.fuelLevel}L)` : `${p?.eventDescription}`
                const isTripStart = p?.event === 14;
                const isTripEnd = p?.event === 15;
                const gridEvent = {
                    key: p.id,
                    date: date,
                    hour: hour,
                    asset: p.asset,
                    event: eventDesc,
                    eventCode: p?.event,
                    heading: p.heading,
                    speed: p.speed,
                    location: p.locationText,
                    state: state,
                    assetType: p.assetType,
                    assetTypeIconColor: p.assetTypeIconColor,
                    timesent: convertTimeSent(p.timeSent),
                    timeIndex: timeIndex,
                    isSelected: false,
                    id: p.id,
                    positionData: p
                };

                const marker = {
                    id: p.id,
                    type: "map",
                    positionData: p,
                    position: { lat: p.latitude, lng: p.longitude },
                    icon: {
                        url: buildAssetColorIconUrl(p.assetType, p.assetTypeIconColor),
                        origin: new window.google.maps.Point(0, 0),
                        //anchor: new window.google.maps.Point(0, iconSize),
                        size: new window.google.maps.Size(iconSize, iconSize),
                        scaledSize: new window.google.maps.Size(iconSize, iconSize)
                    },
                    title: `${p.asset}\r\n${p.locationText}\r\n${date}, ${hour}\r\n${p.latitude}, ${p.longitude}`,
                    onClick: () => {
                        const map = googleMapCompRef.current.getInternalMap();
                        googleMapCompRef.current.setMarkerSelection({
                            id: p.id
                        });
                        setGridSelectedRowId(p.id);
                        dataGridEventsGridRef.current.scrollToRow(gridEvent?.idx ?? 0);
                        if (map) {
                            map.panTo(marker.position);
                        }
                    }
                };

                if (isTripStart) {
                    const tripPlayMarker = {
                        type: "overlay",
                        id: "ts_" + p.id,
                        zIndex: extraIconIndexLayer,
                        positionData: p,
                        clickable: false,
                        position: { lat: p.latitude, lng: p.longitude },
                        icon: {
                            url: buildCustomColorIconUrl('start', 'orange'),
                            origin: new window.google.maps.Point(0, 0),
                            //anchor: new window.google.maps.Point(0, miniIconSize),
                            size: new window.google.maps.Size(miniIconSize, miniIconSize),
                            scaledSize: new window.google.maps.Size(miniIconSize, miniIconSize),
                        }
                    };

                    markerExtras.push(tripPlayMarker)
                }

                if (isTripEnd) {
                    const tripPlayMarker = {
                        id: "te_" + p.id,
                        type: "overlay",
                        zIndex: extraIconIndexLayer,
                        positionData: p,
                        clickable: false,
                        position: { lat: p.latitude, lng: p.longitude },
                        icon: {
                            url: buildCustomColorIconUrl('stop', 'red', "blue"),
                            size: new window.google.maps.Size(12, 12),
                            origin: new window.google.maps.Point(0, 0),
                            scaledSize: new window.google.maps.Size(12, 12),
                            //anchor: new window.google.maps.Point(10, 5)
                        }
                    };

                    markerExtras.push(tripPlayMarker)
                }

                const coordinate = {
                    lat: p.latitude,
                    lng: p.longitude,
                    id: p.id, timeIndex,
                    inTrip: p.inTrip || isTripStart || isTripEnd
                };

                markers.push(marker);
                gridEvents.push(gridEvent);
                pathCoordinates.push(coordinate);
            }

            for (const m of markerExtras) {
                markers.push(m)
            }

            const positionCenter = getGoogleMapLocationsCenter(markers.map(x => x.position));
            gridEvents.sort((b, a) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            pathCoordinates.sort((a, b) => (a.timeIndex > b.timeIndex) ? 1 : ((b.timeIndex > a.timeIndex) ? -1 : 0))
            for (var i = 0; i < gridEvents.length; i++) {
                gridEvents[i].idx = i;
                gridEvents[i].prevNode = i > 0 ? gridEvents[i - 1] : null;
                gridEvents[i].nextNode = i < (gridEvents.length - 1) ? gridEvents[i + 1] : null;
            }

            if (markers.length <= 0) {
                const m = { ...mapData };
                m.userError = `${lang.language.eventGridNoRecord}\n(${range.description})`;
                m.markers = [];
                m.pathCoordinates = [];
                setEventsGridData([]);
                setMapData(m);
                return;
            }


            setEventsGridData(gridEvents);

            const filteredPathCoordinates = [];
            for (var i = 0; i < pathCoordinates.length; i++) {
                if (pathCoordinates[i].inTrip) {
                    filteredPathCoordinates.push(pathCoordinates[i]);
                }
            }

            setMapData({
                center: positionCenter,
                markers: markers,
                pathCoordinates: filteredPathCoordinates,
            });

            fitMapBounds(pathCoordinates);
        }, filter)
    }

    const retrieveGoogleApiKeys = () => {
        fetchGoogleApiKey((apiResult) => {
            if (apiResult.error) {
                return;
            }

            if (!googleApiKey)
                setGoogleApiKey(apiResult.data);
        });
    }

    const onGo = (range) => { // checked
        setCurrentRange(range)
        searchFilterRef?.current?.clear();
        var assetId = selectedAsset?.id;
        if (!assetId) {
            const assets = activeAssets;
            if (!assets || assets.length < 1) {
                return;
            }

            const firstAsset = assets[0];
            assetId = firstAsset.id;
            setSelectedAsset(firstAsset);
        }

        setSelectionWarning(false);
        if (!range) {
            retrieveLastPositions(assetId);
            return;
        }

        retrievePositions(assetId, range);
    }

    const getBoundsWidthHeight = (bounds) => {
        var spherical = window.google.maps.geometry.spherical;
        const cor1 = bounds.getNorthEast();
        const cor2 = bounds.getSouthWest();
        const cor3 = new window.google.maps.LatLng(cor2.lat(), cor1.lng());
        const cor4 = new window.google.maps.LatLng(cor1.lat(), cor2.lng());

        const width = spherical.computeDistanceBetween(cor1, cor3);
        const height = spherical.computeDistanceBetween(cor1, cor4);
        return { w: width, h: height };
    }


    const fitMapBounds = (coordinates) => {
        const internalMap = googleMapCompRef.current.getInternalMap();
        if (!internalMap) {
            return;
        }

        if (coordinates.length === 1) {
            internalMap.panTo(coordinates[0]);
            return;
        }

        var containAll = true;
        const mapBounds = internalMap.getBounds();
        const bounds = new window.google.maps.LatLngBounds();
        for (var point of coordinates) {
            const loc = new window.google.maps.LatLng(point.lat, point.lng);
            bounds.extend(loc);
            if (!mapBounds.contains(loc)) {
                containAll = false;
            }
        }

        if (containAll) {
            return;
        }

        const mSize = getBoundsWidthHeight(mapBounds);
        const cSize = getBoundsWidthHeight(bounds);
        if (mSize.w > cSize.w && mSize.h > cSize.h) {
            internalMap.panTo(bounds.getCenter());
            return;
        }

        internalMap.fitBounds(bounds);
    }

    const onAssetSelection = (e) => {
        const assets = activeAssets;
        if (!assets || assets.length < 1) {
            return;
        }

        const assetId = e.id;
        var asset = activeAssets.find(x => x.id === assetId);
        if (!asset) {
            return;
        }

        searchFilterRef?.current?.clear();
        setSelectionWarning(false);
        setSelectedAsset(asset);
        retrieveLastPositions(assetId);
    }

    const onGridRowSelection = (rowId) => {
        const map = googleMapCompRef.current.getInternalMap();
        if (!map) return;
        const markers = mapData?.markers;
        if (!markers) return;
        const marker = markers.find(x => x.id === rowId);
        const location = marker?.position;
        if (!location) return;
        map.panTo(location);
        googleMapCompRef.current.setMarkerSelection({
            id: rowId,
            activateSelection: true,
        });
    };

    const retrieveActiveAssets = (onComplete, accountId) => {//checked
        fetchActiveAssets(accountId, (e) => {
            if (e.error) {
                if (onComplete)
                    onComplete(false, null)
            }

            var assets = [...e.data];
            setActiveAssets(assets);
            if (onComplete)
                onComplete(true, e.data)
        })
    }

    const retrieveActiveAccounts = (onComplete) => {//checked
        setReady(false)
        fetchActiveAccountTree((e) => {
            if (e.error) {
                if (onComplete) onComplete(false)
                return;
            }

            const activeAccountPackage = e.data;
            const accounts = activeAccountPackage
                .accountEntries.map(a => {
                    return a.account
                });

            setActiveAccounts(accounts)
            if (accounts.length <= 0) {
                if (onComplete) onComplete(false)
                return;
            }

            let firstAccount = accounts[0];
            if (userLocalData) {
                const lastAccessedAccountId = userLocalData?.lastAccessedAccountId;
                if (lastAccessedAccountId) {
                    var lastAccessedAccount = accounts.find(x => x.id === lastAccessedAccountId);
                    if (lastAccessedAccount) {
                        firstAccount = lastAccessedAccount
                    }
                }
            }

            setSelectedAccount(firstAccount)
            retrieveActiveAssets((state, assets) => {
                if (!assets || assets.length <= 0) {
                    if (onComplete) onComplete(false)
                    return;
                }

                let firstAsset = assets[0];
                if (userLocalData) {
                    const lastAccessedAssetId = userLocalData?.lastAccessedAssetId;
                    if (lastAccessedAssetId) {
                        var lastAccessedAsset = assets.find(x => x.id === lastAccessedAssetId);
                        if (lastAccessedAsset) {
                            firstAsset = lastAccessedAsset
                        }
                    }
                }
                setSelectedAsset(firstAsset)
                //setReady(true)
                ensureGoogleLoaded().then(() => {
                    if (searchParams.size <= 0) {
                        retrieveLastPositions(firstAsset.id)
                    }
                    else {
                        loadQueryParameters(assets)
                    }

                    retrieveLastPositions(firstAsset.id)
                    if (onComplete) onComplete(state)
                });
            }, firstAccount.id);
        });
    }

    const loadQueryParameters = (assets) => {//checked
        searchFilterRef?.current?.clear()
        const assetId = searchParams.get("uid");
        if (assetId) {
            const id = parseInt(assetId);
            if (assets && assets.length > 0) {
                var asset = assets.find(x => x.id === id);
                setSelectedAsset(asset)
            }
        }
        const value = searchParams.get("idx");
        if (!value || parseInt(value) === 0) {
            retrieveLastPositions(assetId)
            return;
        }

        const startDate = searchParams.get("start");
        const endDate = searchParams.get("end");

        const precision = searchParams.get("pr");
        const expandAccountBar = searchParams.get("ab");

        if (assetId) {
            const copy = { ...accountBarData };
            copy.expanded = expandAccountBar ? true : false;
            setAccountBarData(copy);
        }

        if (assetId && startDate && endDate && value) {
            const timeConfig = {
                valueIndex: value,
                start: new Date(startDate),
                end: new Date(endDate),
                precision: precision ? true : false
            };

            timeFramePanelRef?.current?.configureTimeFrame(timeConfig);

            setSelectionWarning(false);
            const range = {
                end: timeConfig.end,
                start: timeConfig.start,
                description: "Query range"
            }

            retrievePositions(assetId, range);
        }
    }

    const checkIfReady = () => {//checked
        showLoading(true)
        setBusy(true)
        isLogin((e) => {
            if (e.error) {
                showLoading(false)
                setBusy(false)
                navigate("/home", { replace: true });
                return;
            }

            retrieveActiveAccounts((e) => {
                setReady(true)
                showLoading(false)
                setBusy(false)
            });
        })
    }

    const onResize = () => {
        const newGridResizeData = { ...gridResizeData };
        newGridResizeData.height = eventGridRef?.current?.clientHeight;
        setGridResizeData(newGridResizeData);
    }

    useEffect(() => {
        checkIfReady()
        retrieveGoogleApiKeys()
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            var assetId = selectedAsset?.id;
            if (!assetId) {
                return;
            }

            var range = currentRange;
            if (!range) {
                retrieveLastPositions(assetId);
                return;
            }

            retrievePositions(assetId, range);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            if (props.onSearch)
                props.onSearch(filter.value)
        }
    }, [searchFilter]);

    useEffect(() => {
        setUserLocalData(
            {
                lastAccessedAssetId: selectedAsset?.id,
                lastAccessedAccountId: selectedAccount?.id
            })
    }, [selectedAsset, selectedAccount]);


    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "25px";
    const timeFrameHeight = mobile ? "110px" : "150px";

    const minGridHeight = mobile ? "40px" : "150px";
    const mapMinWidth = mobile ? "80px" : "200px";
    const mapMinHeight = mobile ? "80px" : "150px";

    const sideBorderWidth = mobile ? "2px" : "5px";

    const userActionWidth = mobile ?
        (accountBarData?.expanded ? "150px" : "30px") :
        (userActionResizeData.width + 'px');
    const accountPanelMinWidth = mobile ? "30px" : "150px";
    const userActionExpended = accountBarData?.expanded;
    const userActionMinHeight = 160;

    const middleDivHeight = midlleContainerDivRef?.current?.clientHeight;
    const maxEventsgridHeight = middleDivHeight ? middleDivHeight * 3 / 4 : undefined;
    const maxEventsgridHeightPx = maxEventsgridHeight ? `${maxEventsgridHeight}px` : "50%";
    const timeFrameFollowSize = eventGridRef?.current?.clientHeight ?
        `${eventGridRef?.current?.clientHeight}px` :
        `${minGridHeight}px`;


    const innerHeight = window.innerHeight;
    const gridBarExpanded = gridBarData?.expanded;

    const gridHeight = mobile ? (gridBarExpanded ? `${innerHeight / 2}px` : "80px") : (gridResizeData.height + "px");

    return (
        <div className='full-screen'>
            <div ref={topBarDivRef} style={{ width: "100%", height: barHeight }}>
                <TopBarComponenent
                    showHome={true}
                    userMenuControlOptions={{
                        userMenuControl: true,
                        showUserMenuAdministration: true,
                    }}
                    showScreenControl={true}
                    showInfo={true}
                    showRefresh={false}
                    showFullQuery={false}
                    user={activeUser}
                    onAbout={() => {
                        setShowAbout(true)
                    }}
                    onShowHome={() => {
                        showLoading(true);
                        setTimeout(() => {
                            navigate("/selector", { replace: true })
                        }, 1000);
                    }}
                    onShowFullQuery={() => {
                        const timeConfig = timeFramePanelRef.current.getTimeFrameConfiguration();
                        const assetId = selectedAsset?.id;

                        setSearchParams({
                            uid: assetId,
                            start: timeConfig?.start?.toJSON(),
                            end: timeConfig?.end?.toJSON(),
                            idx: timeConfig?.valueIndex,
                            pr: timeConfig.precision,
                            pop: false,
                            gr: gridBarData?.expanded
                        });

                    }} />
            </div>
            <div className='middle-frame'>
                <div className='middle-side-bar' style={{ backgroundColor: appTheme.themeColor, width: sideBorderWidth }}>
                </div>
                <div ref={midlleContainerDivRef} style={{ display: 'flex', width: "100%", flexDirection: 'column', flex: 1 }}>
                    <div name="map_division" style={{ width: "100%", display: 'flex', flexDirection: 'column', flex: 1, position: "relative" }}>
                        <div style={{ display: 'flex', width: "100%", height: "100%", position: "relative", minWidth: mapMinWidth, minHeight: mapMinHeight }}>
                            {
                                googleApiKey &&
                                <GoogleMapComponent
                                    ref={googleMapCompRef}
                                    zoom={zoom}
                                    center={mapData?.center}
                                    markers={mapData?.markers}
                                    pathCoordinates={mapData?.pathCoordinates} />
                            }
                            {
                                ready ?
                                    null :
                                    <div style={{ float: 'left', height: "100%", width: "100%", position: "absolute" }}>
                                    </div>
                            }
                        </div>
                        <div style={{
                            bottom: 0,
                            marginLeft: "auto",
                            marginRight: "auto",
                            left: 0,
                            right: 0,
                            position: "absolute",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Notificator ref={notificatorRef} />
                        </div>
                    </div>
                    <GridBar />
                    <div ref={eventGridRef} style={{ width: "100%", minHeight: minGridHeight, height: gridHeight, maxHeight: maxEventsgridHeightPx, display: "flex", flexDirection: "column" }}>
                        <div name="grid_division_resizer"
                            style={{
                                display: mobile ? "none" : undefined,
                                minHeight: "5px", height: "5px", width: "100%", cursor: "row-resize", backgroundColor: "whitesmoke"
                            }}
                            draggable={true}
                            onDragStart={(e) => {
                                const initialHeight = gridResizeData.height;
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.initialPos = e.clientY;
                                newGridResizeData.initialHeight = initialHeight;
                                setGridResizeData(newGridResizeData);
                            }}
                            onDrag={(e) => {
                                if (e.clientY <= 0) { return; }
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);

                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                if (newHeight > maxEventsgridHeight) {
                                    newHeight = maxEventsgridHeight;
                                }

                                setGridResizeData(newGridResizeData);
                            }}

                            onDragEnd={(e) => {
                                const initialPos = gridResizeData.initialPos;
                                const initialHeight = gridResizeData.initialHeight;
                                var newHeight = parseInt(initialHeight) - parseInt(e.clientY - initialPos);
                                const newGridResizeData = { ...gridResizeData };
                                newGridResizeData.height = newHeight;
                                setGridResizeData(newGridResizeData);
                            }}>
                        </div>
                        <div style={{ display: "flex", width: "100%", flex: 1, position: "relative" }}>
                            {
                                ready ?
                                    <EventsGridComponent
                                        filter={searchFilter?.value}
                                        ref={dataGridEventsGridRef}
                                        rows={eventsGridData}
                                        message={mapData?.userError}
                                        disabled={busy}
                                        gridHeight={gridHeight}
                                        onRowSelection={onGridRowSelection}>
                                        message={mapData?.userError}
                                    </EventsGridComponent> : null}
                        </div>
                    </div>
                </div>
                <div id='user_actions_resizable' name="user_actions_division"
                    style={{
                        width: userActionWidth, minWidth: accountPanelMinWidth, display: "flex", flexDirection: 'row',
                        height: "100%"
                    }}>
                    <div name="user_actions_division_resizer"
                        style={{
                            display: mobile ? "none" : undefined, backgroundColor: "whitesmoke",
                            minWidth: "5px", width: "5px", height: "100%", cursor: "col-resize"
                        }}
                        draggable={true}
                        onDragStart={(e) => {
                            const initialWidth = userActionResizeData.width;
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.initialPos = e.clientX;
                            newUserActionResizeData.initialWidth = initialWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}
                        onDrag={(e) => {
                            if (e.clientX <= 0) { return; }
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            console.log(`${newWidth},${e.clientX}, ${newWidth}`)
                            setUserActionResizeData(newUserActionResizeData);
                        }}

                        onDragEnd={(e) => {
                            const initialPos = userActionResizeData.initialPos;
                            const initialWidth = userActionResizeData.initialWidth;
                            const newWidth = parseInt(initialWidth) - parseInt(e.clientX - initialPos);
                            const newUserActionResizeData = { ...userActionResizeData };
                            newUserActionResizeData.width = newWidth;
                            setUserActionResizeData(newUserActionResizeData);
                        }}>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: "100%", overflowY: "hidden", }}>
                        <div ref={AccountDivRef} style={{ Height: "25px", width: "100%" }}>
                            <AccountBar />
                        </div>
                        <div ref={AssetDivRef} style={{ display: 'flex', minHeight: `${userActionMinHeight}px`, width: "100%", height: "100%", flex: 1 }}>
                            <UserPanelComponent
                                assets={activeAssets}
                                onSelection={onAssetSelection}
                                visible={isMobile ? userActionExpended : true}
                                accounts={activeAccounts}
                                onRequestCurrentPosition={(e) => {
                                    const asset = e.item;
                                    if (!asset)
                                        return;
                                    const filter = {
                                        assetId: asset.id
                                    }

                                    fetchRequestCurrentPosition((e) => {
                                        if (e.error)
                                            return;
                                        notificatorRef.current.pushNotification({
                                            notification: "Current position request was issued",
                                            color: "green"
                                        });
                                    }, filter);
                                }}
                                onRequestUpdate={(e) => {
                                    setUpdaterData({
                                        open: true,
                                        asset: e.item
                                    });
                                }}
                                onRequestFtpReport={(e) => {
                                    setFtpReportData({
                                        open: true,
                                        asset: e.item
                                    });
                                }}
                                selectedAsset={selectedAsset}
                                selectedAccount={selectedAccount}
                                onAccountChange={(account) => {
                                    showLoading(true)
                                    setBusy(true)
                                    const m = { ...mapData };
                                    m.userError = "";
                                    m.markers = [];
                                    m.pathCoordinates = [];
                                    setEventsGridData([]);
                                    setMapData(m);
                                    setSelectedAccount(account)
                                    const accountId = account?.id;
                                    if (!accountId)
                                        return;
                                    retrieveActiveAssets((state, assets) => {
                                        showLoading(false)
                                        setBusy(false)
                                        if (assets.length > 0) {
                                            const firstAsset = assets[0];
                                            setSelectedAsset(firstAsset)
                                            retrieveLastPositions(firstAsset.id)
                                        }
                                    }, accountId);
                                }}>
                            </UserPanelComponent>
                        </div>
                        <div style={{ display: "flex", height: timeFrameFollowSize, minHeight: timeFrameHeight }}>
                            <TimeFrameComponent
                                demo={false}
                                ref={timeFramePanelRef}
                                visible={userActionExpended}
                                onGo={onGo}
                                disabled={busy || !selectedAccount}>
                            </TimeFrameComponent>
                        </div>
                    </div>
                </div>
                {
                    updaterData &&
                    <UpdaterDialog
                        onClickOutside={() => {
                            setUpdaterData(null)
                        }}
                        open={updaterData.open}
                        asset={updaterData.asset} >
                    </UpdaterDialog>
                }
                {
                    ftpReportData &&
                    <FtpReportDialog
                        onClickOutside={() => {
                            setFtpReportData(null)
                        }}
                        onFtpRequested={(e) => {
                            if (e.success) {
                                notificatorRef.current.pushNotification({
                                    notification: `FTP ${e.modeName} report requested for ${e.asset.name}`,
                                    color: "green",
                                    lifetime: 4
                                });
                            }
                            else {
                                notificatorRef.current.pushNotification({
                                    notification: "Error occurred while issuing request",
                                    color: "red"
                                });
                            }
                        }}
                        open={ftpReportData.open}
                        asset={ftpReportData.asset} ></FtpReportDialog>
                }

                <div className='middle-side-bar' style={{ backgroundColor: appTheme.themeColor, width: sideBorderWidth }}>
                </div>
            </div>
            <div ref={bottomBarDivRef} style={{ backgroundColor: appTheme.themeColor, height: barHeight, minHeight: barHeight, display: 'flex' }}>
                <div style={{ display: "flex", flexDirection: "row", flex: 1, gap: "10px", paddingLeft: "5px", fontSize: appTheme.statusFontSize }}>
                    <span style={{ alignSelf: "center", color: "wheat" }}>Count {eventsGridData?.length ?? 0}</span>
                    {
                        !mobile &&
                        <SearchFilter
                            style={{ width: "150px" }}
                            ref={searchFilterRef}
                            initialValue={searchFilter?.value}
                            onFilterChange={(text) => {
                                setSearchFilter({
                                    updated: true,
                                    value: text
                                })
                            }}>
                        </SearchFilter>
                    }
                    {
                        Boolean(positionsPaginationData?.pageSize && positionsPaginationData?.pageCount > 1) &&
                        <Paginator
                            fontSize={appTheme.statusFontSize}
                            color="white"
                            changeIndicator={positionsPaginationData}
                            initialNumber={pageNumber.value}
                            pageSize={positionsPaginationData?.pageSize}
                            pageCount={positionsPaginationData?.pageCount}
                            count={positionsPaginationData?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }}>
                        </Paginator>
                    }
                </div>
                {
                    isFullScreen &&
                    <TimeDisplay fontSize={appTheme.statusFontSize} />
                }
                <div style={{ flex: 1 }}></div>
            </div>
        </div>);
};

export default WebtraceDashboard;