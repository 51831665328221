const errorEventType = "webtrace_call_error"
const errorEvents = {
    on(callback) {
        document.addEventListener(errorEventType, (e) => callback(e.detail));
    },
    dispatch(data) {
        document.dispatchEvent(new CustomEvent(errorEventType, { detail: data }));
    },
    remove(callback) {
        document.removeEventListener(errorEventType, callback);
    },
};

const dispatchResponseError = (response, text, args) => {

    const errorData = {
        status: response.status,
        error: `${response.statusText} (${response.status}): ${text}`,
        ...args
    }

    errorEvents.dispatch(errorData);
}

const dispatchError = (error, args) => {

    const errorData = {
        error: `${error}`,
        ...args
    }

    errorEvents.dispatch(errorData);
}

export { errorEvents, dispatchResponseError, dispatchError } 