const iconSize = 25
const selectedIconSize = 28
const miniIconSize = 10

const iconIndexLayer = 1
const selectionAreaMarkerIndexLayer = 10
const selectedMarkerIconIndexLayer = 20
const extraIconIndexLayer = 50

const setNormalIconSize = (instance) => {
    if (!instance) return;
    var icon = instance.getIcon();
    if (icon) {
        if (icon.size.width !== iconSize ||
            icon.size.height !== iconSize ||
            icon.scaledSize.width !== iconSize ||
            icon.scaledSize.height !== iconSize) {
            //const newIcon = { ...icon }
            const newIcon = icon
            newIcon.scaledSize = new window.google.maps.Size(iconSize, iconSize)
            newIcon.size = new window.google.maps.Size(iconSize, iconSize)
            instance.setIcon(newIcon)
        }
        else {
            console.log("Skipped ...")
        }
    }
}

const setSelectedIconSize = (instance) => {
    if (!instance) return;
    var icon = instance.getIcon();
    if (icon) {
        //const newIcon = { ...icon }
        const newIcon = icon
        newIcon.scaledSize = new window.google.maps.Size(selectedIconSize, selectedIconSize)
        newIcon.size = new window.google.maps.Size(selectedIconSize, selectedIconSize)
        instance.setIcon(newIcon)
    }
}

export {
    iconSize,
    selectedIconSize,
    miniIconSize,
    iconIndexLayer,
    selectedMarkerIconIndexLayer,
    extraIconIndexLayer,
    setNormalIconSize,
    selectionAreaMarkerIndexLayer,
    setSelectedIconSize
}


