import React from 'react'
import { checkIfEmptyOrWhiteSpace } from "../../../utilities/stringHelper";

import createRole from '../../../calls/administration/roles/createRole';
import updateRole from '../../../calls/administration/roles/updateRole';

import DropdownList from "react-widgets/DropdownList";
import { showLoading } from 'react-global-loading';

const levels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
function RoleCreation(props) {

    const [roleName, setRoleName] = React.useState("");
    const [roleDescription, setRoleDescription] = React.useState("");
    const [roleLevel, setRoleLevel] = React.useState(1);
    const [error, setError] = React.useState("");

    const editRole = props?.options?.editRole;
    const open = props.options?.open;

    React.useEffect(() => {
        if (editRole) {
            setRoleName(editRole.name)
            setRoleDescription(editRole.description)
            setRoleLevel(editRole.level)
        }
    }, []);
    return <dialog open={open} style={{
        top: "50%",
        bottom: "50%",
        marginRight: "auto",
        marginLeft: "auto",
        padding: "8px",
        borderWidth: "1px",
        borderRadius: "5px",
        minWidth: "450px"
    }}>
        <div>
            <div style={{ textAlign: "right" }}>
                {editRole ? `Role edition (${editRole.id})` : "Role creation"}
            </div>
            <hr />
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", minWidth: "120px" }} htmlFor="role_creation_name">Name</label>
                <input
                    style={{ display: "block", width: "100%" }}
                    type="text"
                    autoComplete='off'
                    id="role_creation_name"
                    placeholder="Role Name"
                    value={roleName}
                    onChange={(e) => {
                        setRoleName(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                <label style={{ display: "block", minWidth: "120px" }} htmlFor="role_creation_description">Name</label>
                <input
                    style={{ display: "block", width: "100%" }}
                    type="text"
                    autoComplete='off'
                    id="role_creation_description"
                    placeholder="Role Description"
                    value={roleDescription}
                    onChange={(e) => {
                        setRoleDescription(e.target.value)
                    }} />
            </div>
            <div style={{ display: "flex", margin: "5px 0px", width: "100%" }}>
                <div style={{ display: "flex", margin: "5px 0px", flex: 1 }}>
                    <label style={{ display: "block", minWidth: "120px", textAlign: "left" }} htmlFor="role_creation_level">Level</label>
                    <DropdownList
                        id="role_creation_level"
                        style={{ display: "block", width: "100%" }}
                        data={levels}
                        dataKey='id'
                        textField='name'
                        value={roleLevel}
                        onChange={(value) => {
                            setRoleLevel(value)
                        }}
                    // renderValue={({ item }) => (
                    //     <div style={{ display: "flex", minWidth: "60px" }}>
                    //         <strong style={{ flex: 1 }}>{item?.name}</strong>
                    //     </div>
                    // )}
                    />
                </div>
            </div>
            {checkIfEmptyOrWhiteSpace(error) ? null : (<div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "8", whiteSpace: "pre-line" }}>
                {error}
            </div>)}
            <div style={{ textAlign: "left", display: "flex", flexDirection: "row", margin: "10px 0px 0px 0px" }}>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", borderRadius: "2px", height: "100%",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (checkIfEmptyOrWhiteSpace(roleName)) {
                        setError("Role name cannot be empty")
                        return;
                    }

                    if (editRole) {
                        const roleId = editRole?.id;
                        if (!roleId)
                            return;
                        const data = {
                            name: roleName,
                            description: roleDescription,
                            level: roleLevel,
                        };

                        showLoading(true)
                        updateRole((e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props?.onRoleEdited)
                                props.onRoleEdited(e.data)
                        }, roleId, data);
                    }
                    else {
                        const data = {
                            name: roleName,
                            description: roleDescription,
                            level: roleLevel,
                        };

                        showLoading(true)
                        createRole((e) => {
                            showLoading(false)
                            if (e.error) {
                                setError(e.error)
                                return;
                            }

                            if (props?.onRoleCreated)
                                props.onRoleCreated(e.data)
                        }, data);
                    }
                }}>confirmer</button>
                <span style={{ flexGrow: 1, display: "flex" }}></span>
                <button style={{
                    width: "100px", backgroundColor: "whitesmoke", color: "black", height: "100%", borderRadius: "2px",
                    borderWidth: "2px", margin: "5px 0px 1px 0px", borderColor: 'lightgray', cursor: "pointer"
                }} onClick={() => {
                    if (!props?.onCancel) {
                        return;
                    }
                    props.onCancel();
                }}>Annuler</button>
            </div>
        </div>
    </dialog>
}

export default RoleCreation;