import React from 'react'
import { isMobile } from 'react-device-detect';
import { showLoading } from 'react-global-loading';
import { localTimeFormat, toLocalTime, toLocalTimeFormatted } from '../../../utilities/timeHelper'
import fetchUnits from "../../../calls/administration/units/fetchUnits";
import fetchFakeUnits from '../../../calls/administration/units/fetchFakeUnits';
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';
import ConfirmBox from '../ConfirmBox';
import UnitCreation from "./UnitCreation"
import deleteUnit from "../../../calls/administration/units/deleteUnit"
import UnitConfigsTable from '../unit_configs/UnitConfigsTable';
import Paginator from '../../Paginator/Paginator';
import SearchFilter from '../../SearchFilter/SearchFilter';

function UnitsListView(props) {
    const [data, setData] = React.useState(null);
    const [selectedUnit, setSelectedUnit] = React.useState(null);
    const [units, setUnits] = React.useState([]);
    const [busy, setBusy] = React.useState(true);
    const [unitCreation, setUnitCreation] = React.useState(null);
    const [unitDeletion, setUnitDeletion] = React.useState(null);
    const [unitConfigsOption, setUnitConfigsOption] = React.useState(false);

    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const updateUnits = (silent = true) => {
        const filter = {
            pageNumber: pageNumber.value,
            search: searchFilter?.value ?? ""
        }

        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchUnits((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }
            if (e.error) {
                return;
            }

            setData(e.data)
            setUnits(e?.data?.data)
        }, filter);
    }

    React.useEffect(() => {
        updateUnits(false);
        return () => {
        };
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateUnits(true);
        }

    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateUnits(true);
        }
    }, [searchFilter]);

    if (busy) return null;
    return (
        <div style={{
            width: "100%",
            height: "100%",
            display: "flex", overflow: "auto", flexDirection: "column"
        }}>
            <div id='topbar' style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Hardware Serial</th>
                                    <th style={{ textAlign: "left", }}>Model</th>
                                    <th style={{ textAlign: "left", }}>Alias</th>
                                    <th style={{ textAlign: "left", }}>Linked Asset</th>
                                    <th style={{ textAlign: "left", }}>Last Seen</th>
                                    <th style={{ textAlign: "left", }}>Active</th>
                                    <th style={{ textAlign: "left" }}>Updated</th>
                                    <th style={{ textAlign: "left" }}>Created</th>

                                </tr>
                            </thead>
                            <tbody>
                                {
                                    units &&
                                    units.map((unit, index) => {
                                        const finalUnit = unit;
                                        const isSelected = (selectedUnit && finalUnit.id === selectedUnit?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                                    setSelectedUnit(finalUnit)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalUnit.hwSerial}</td>
                                                <td style={{ textAlign: "left" }}>{finalUnit.unitType}</td>
                                                <td style={{ textAlign: "left" }}>{finalUnit.alias ?? "_"}</td>
                                                <td style={{ textAlign: "left" }}>{finalUnit.asset ?? "_"}</td>
                                                <td style={{ textAlign: "left" }}>{toLocalTimeFormatted(finalUnit.lastSeen)}</td>
                                                <td style={{ textAlign: "left", color: finalUnit.active ? "green" : "red" }}>{String(finalUnit.active)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{toLocalTimeFormatted(finalUnit.updatedAt)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{toLocalTimeFormatted(finalUnit.createdAt)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    Boolean(data?.pageCount && data?.pageCount > 1) &&
                    <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                        <Paginator
                            changeIndicator={data}
                            initialNumber={pageNumber.value}
                            pageSize={data?.pageSize}
                            pageCount={data?.pageCount}
                            count={data?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }} />
                    </div>
                }
            </div>
            <div style={{ height: "30px", width: "100%", display: "flex", padding: "10px 0px" }}>

                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    //disabled={!selectedUnit}
                    onClick={() => {
                        setUnitCreation({
                            open: true
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        const unit = units.find(x => x.id === selectedUnit.id);
                        setUnitCreation({
                            open: true,
                            editUnit: unit
                        });
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        setUnitDeletion({
                            open: true,
                            deleteUnit: selectedUnit
                        })
                    }}>
                    Delete
                </button>
                <button style={{ padding: "2px", margin: "0px 5px" }}
                    disabled={!selectedUnit}
                    onClick={() => {
                        setUnitConfigsOption({
                            open: true,
                            unit: selectedUnit
                        })
                    }}>
                    Configs Table
                </button>
            </div>
            {
                unitCreation?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <UnitCreation
                        //open={unitCreation?.open}
                        {...unitCreation}
                        onUnitEdited={() => {
                            setUnitCreation(null)
                            updateUnits(false)
                        }}
                        onUnitCreated={() => {
                            setUnitCreation(null)
                            updateUnits(false)
                        }}
                        onCancel={() => {
                            setUnitCreation(null)
                        }} />
                </div>
            }
            {
                unitConfigsOption?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <UnitConfigsTable
                        open={unitConfigsOption?.open}
                        unit={unitConfigsOption?.unit}
                        onClose={() => {
                            setUnitConfigsOption(false)
                        }}
                    />
                </div>
            }
            {
                unitDeletion?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        open={unitDeletion?.open}
                        title="Unit deletion"
                        onCancel={() => {
                            setUnitDeletion(null)
                        }}
                        onConfirm={() => {
                            const unit = unitDeletion.deleteUnit;
                            setUnitDeletion(null)
                            showLoading(true);
                            deleteUnit((e) => {
                                showLoading(false);
                                if (e.error) {
                                    return
                                }

                                updateUnits(false)
                            }, unit?.id);
                        }}
                        message={`You are about to delete account ${unitDeletion?.deleteAsset?.name}`} />
                </div>
            }
        </div>);
}

export default UnitsListView;