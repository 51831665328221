import React from 'react'
import { AppContext } from '../AppContext';
import Listbox from "react-widgets/Listbox";
import { isMobile } from 'react-device-detect';
import DropdownList from "react-widgets/DropdownList";
import { buildAssetColorIconUrl } from '../utilities/assetHelper'

import { IconContext } from "react-icons";
import { AiOutlineFilter, AiFillFilter } from "react-icons/ai";
import ContextMenu from './menus/ContextMenu';


function UserPanelComponent(props) {
    const { appTheme } = React.useContext(AppContext);
    const [filterVisible, setFilterVisible] = React.useState(false);
    const [filterOn, setFilterOn] = React.useState(false);
    const [contextMenuData, setContextMenuData] = React.useState(null);

    const onChange = (value) => {
        if (!props.onSelection) return;
        props.onSelection(value);
    }

    const mobile = isMobile;
    const titleFontSize = mobile ? 10 : undefined;
    const panelPadding = mobile ? "0px 4px" : "4px 8px";

    if (!props.visible) {
        return null;
    }

    const contextMenuActor = contextMenuData?.actor;
    const accounts = props.accounts;
    const noAccount = Boolean(!accounts || accounts.length < 1)
    const oneAccount = Boolean(accounts && accounts.length === 1)
    const multiAccount = Boolean(accounts && accounts.length > 1)
    return (
        <div style={{ padding: panelPadding, display: "flex", flexFlow: "column", width: "100%", height: "100%" }}>
            <div style={{ margin: "2px 0px 2px 3px" }}>
                <div style={{ textAlign: "right", fontWeight: "bold", fontSize: titleFontSize, margin: "0px 0px 2px 0px" }}>Compte</div>
                <div style={{ textAlign: "center", fontSize: titleFontSize }}>
                    {
                        noAccount &&
                        <div style={{ borderStyle: "solid", borderWidth: "1px", borderRadius: "3px", borderColor: "lightgray", padding: "2px 2px" }}>
                            You do not have any linked account
                        </div>
                    }
                    {
                        oneAccount &&
                        <div style={{ borderStyle: "solid", borderWidth: "1px", borderRadius: "3px", borderColor: "lightgray", padding: "2px 2px" }}>
                            {props?.selectedAccount?.name}
                        </div>
                    }
                    {
                        multiAccount &&
                        <DropdownList
                            onBlur={() => {
                                setFilterOn(false)
                                setFilterVisible(false)
                            }}
                            autoFocus={false}
                            filter={filterOn}
                            style={{ display: "block", width: "100%" }}
                            data={props.accounts}
                            dataKey='id'
                            onToggle={(e) => {
                                if (e) {
                                    setFilterVisible(true)
                                }
                            }}

                            placeholder='Select an account'
                            textField='name'
                            value={props.selectedAccount}
                            renderValue={({ item }) => {
                                return (
                                    <span style={{ display: "flex" }}>
                                        <span style={{ width: "100%" }}>{item?.name}</span>
                                        {
                                            filterVisible ?
                                                <button style={{ backgroundColor: "transparent", height: "100%", borderWidth: "0px", alignSelf: "center" }}
                                                    onClick={() => {
                                                        setFilterOn(!filterOn)
                                                    }}>
                                                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                                        <IconContext.Provider value={{ size: 15 }}>
                                                            {
                                                                filterOn ?
                                                                    <AiFillFilter /> :
                                                                    <AiOutlineFilter />
                                                            }
                                                        </IconContext.Provider>
                                                    </div>
                                                </button> :
                                                null
                                        }
                                    </span>)
                            }}
                            onChange={(value) => {
                                setFilterOn(false)
                                setFilterVisible(false)
                                if (props.onAccountChange)
                                    props.onAccountChange(value);
                            }}
                        />
                    }
                </div>
            </div>
            <hr />
            <div style={{ height: "100%", display: "flex", width: "100%", flexFlow: "column", }}>
                <div style={{ textAlign: "right", fontWeight: "bold", fontSize: titleFontSize, margin: "0px 0px 5px 0px" }}>
                    Balises
                </div>
                <div style={{ height: "100%", overflow: "auto", display: "flex", width: "100%", flex: 1, position: "relative", bottom: "5px" }}>
                    <Listbox
                        dataKey="id"
                        data={props.assets}
                        value={props.selectedAsset}
                        style={{ width: "100%", position: "absolute", fontSize: appTheme.userGridFontSize }}
                        onChange={onChange}
                        renderItem={(item) => {
                            const asset = item?.item;
                            const src = buildAssetColorIconUrl(asset.assetType, asset.assetTypeIconColor);
                            return (
                                <div
                                    onContextMenu={(e) => {
                                        e.preventDefault(); // prevent the default behaviour when right clicked
                                        console.log("Right Click");
                                        setContextMenuData({
                                            x: e.pageX,
                                            y: e.pageY,
                                            actor: item,
                                            id: item.id
                                        });
                                    }}
                                    style={{
                                        alignItems: "center",
                                        width: "100%",
                                        height: "100%",
                                        justifyContent: "left",
                                        display: "flex",
                                        flexFlow: "row",
                                        gap: "10px"
                                    }}>
                                    {src ? <img style={{ width: "15px" }} src={src} alt="Logo" /> : null}
                                    <span style={{ flex: 1, textAlign: "left" }}>{asset.name}</span>

                                </div>
                            )
                        }} />

                </div>
                {contextMenuData && (
                    <ContextMenu top={contextMenuData.y} left={contextMenuData.x}
                        onClickOutside={() => {
                            setContextMenuData(null)
                        }}>
                        <div style={{
                            backgroundColor: "white",
                            borderRadius: "5px",
                            padding: "5px",
                            borderWidth: "2px",
                            borderStyle: "solid", fontSize: "14px",
                            borderColor: "lightgray", display: "flex", flexDirection: "column"
                        }}>
                            <span>
                                {contextMenuActor?.item?.name}
                            </span>
                            <div style={{ height: "1px", width: "100%", margin: "5px 0px", backgroundColor: "lightgray" }}></div>
                            <button
                                onClick={() => {
                                    if (props.onRequestCurrentPosition) {
                                        props.onRequestCurrentPosition(contextMenuActor)
                                    }
                                }}
                                style={{ margin: "2px 0px", borderColor: "lightgray", }}>
                                Request current position
                            </button>
                            <button
                                onClick={() => {
                                    if (props.onRequestUpdate) {
                                        props.onRequestUpdate(contextMenuActor)
                                    }
                                }}
                                style={{ margin: "2px 0px", borderColor: "lightgray", }}>
                                Update
                            </button>
                            <button
                                onClick={() => {
                                    if (props.onRequestFtpReport) {
                                        props.onRequestFtpReport(contextMenuActor)
                                    }
                                }}
                                style={{ margin: "2px 0px", borderColor: "lightgray", }}>
                                Ftp Report
                            </button>
                        </div>
                    </ContextMenu>
                )}
            </div>
        </div>
    );
}

export default UserPanelComponent;