import React from 'react'
import { forwardRef, useImperativeHandle } from 'react';
import './Paginator.css'
function Paginator(props, ref) {
    const [selectedPage, setSelectedPage] = React.useState({
        number: 1
    });
    const [pages, setPages] = React.useState([]);

    const buildPages = () => {
        const maxPageCount = props.pageCount;
        const visibleCount = props.visibleCount ?? 6;
        const half = visibleCount / 2
        const currentNumber = selectedPage.number

        const indices = []
        indices.push(currentNumber)
        var left = currentNumber
        var right = currentNumber
        var counter = visibleCount
        while (counter > 0) {
            let noAction = true
            if (left > 1) {
                noAction = false
                counter--;
                left--;
                indices.push(left)
            }

            if (right < maxPageCount) {
                noAction = false
                counter--;
                right++;
                indices.push(right)
            }

            if (noAction) break;
        }

        const pages = []
        for (const index of indices.sort(function (a, b) { return a - b })) {
            pages.push({
                number: index
            })
        }

        return pages;
    }

    const refresh = () => {

    }

    useImperativeHandle(ref, () => ({
        refresh: refresh,
    }));

    React.useEffect(() => {
        setSelectedPage({
            number: props.initialNumber ?? 1,
            updated: true
        })
    }, []);

    React.useEffect(() => {
        if (props.pageCount) {
            setPages(buildPages())
        }
        else {
            setPages([])
        }

    }, [props.changeIndicator]);

    React.useEffect(() => {
        if (selectedPage?.updated) {
            if (props.pageCount) {
                setPages(buildPages())
            }
            else {
                setPages([])
            }
        }
    }, [selectedPage]);

    const isPaginatorValid = pages && (pages.length > 0);
    return (
        <div className='pagination'>
            <a href="#"
                style={{ fontSize: props.fontSize, color: props.color }}
                className={isPaginatorValid ? 'chsft_normal_pointer' : 'chsft_no_pointer'}
                onClick={(e) => {
                    e.preventDefault();
                    const page = {
                        number: 1,
                        updated: true
                    };

                    setSelectedPage({ ...page, updated: true })
                    if (props.onPageSelected)
                        props.onPageSelected(page)
                }}>&laquo;</a>
            {
                isPaginatorValid &&
                pages.map((page, index) => {
                    const finalPage = page;
                    const isSelected = (selectedPage && selectedPage.number === finalPage.number);
                    return (
                        <a key={index} href="#"
                            style={{ fontSize: props.fontSize, color: props.color }}
                            className={isSelected ? "active" : ""}
                            onClick={(e) => {
                                const p = { ...page, updated: true }
                                e.preventDefault();
                                console.log(p);
                                setSelectedPage(p)
                                if (props.onPageSelected)
                                    props.onPageSelected(p)
                            }}>
                            {finalPage.number}
                        </a>);
                })
            }
            <a href="#"
                className={isPaginatorValid ? 'chsft_normal_pointer' : 'chsft_no_pointer'}
                style={{ fontSize: props.fontSize, color: props.color }}
                onClick={(e) => {

                    e.preventDefault();
                    const maxPageCount = props.pageCount;
                    const page = {
                        number: maxPageCount
                    };

                    setSelectedPage({ ...page, updated: true })
                    if (props.onPageSelected)
                        props.onPageSelected(page)
                }}>&raquo;</a>
        </div>
    );
}

export default forwardRef(Paginator)