import { isMobile } from 'react-device-detect';
import { showLoading } from 'react-global-loading';
import React from 'react'
import fetchEvents from "../../../calls/administration/events/fetchEvents";
import deleteEvent from "../../../calls/administration/events/deleteEvent";

import ConfirmBox from '../ConfirmBox'
import Paginator from '../../Paginator/Paginator'

import { hexToRgbaWithAlpha } from '../../../utilities/convertColors'
import EventCreation from './EventCreation'
import SearchFilter from '../../SearchFilter/SearchFilter'
import "./EventsListView.css"

function EventsListView(props) {
    const [eventDeletion, setEventDeletion] = React.useState(null);
    const [data, setData] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [busy, setBusy] = React.useState(false);
    const [eventCreation, setEventCreation] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState("");

    const updateEventsList = (silent, onResult) => {
        if (silent) {
            setBusy(true)
            showLoading(true)
        }

        const filter = {
            pageNumber: pageNumber?.value,
            search: searchFilter?.value ?? "",
            pageSize: 30
        }

        fetchEvents((e) => {
            if (silent) {
                setBusy(false)
                showLoading(false)
            }
            if (e.error) {
                if (onResult) onResult(false)
                return;
            }

            //setEvents(e.data.data)
            setData(e.data)
            if (onResult) onResult(true)
        }, filter);
    }

    React.useEffect(() => {
        updateEventsList(true);
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateEventsList(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateEventsList(false);
        }
    }, [searchFilter]);

    const mobile = isMobile;
    if (busy) {
        return null;
    }

    const events = data?.data;
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}
            onClick={(e) => {
                console.log("Empty click ...")
            }}
        >

            <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%", padding: "5px" }}>
                <div id='topbar' style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                    <div style={{ flex: 1 }}></div>
                    <SearchFilter
                        initialValue={searchFilter?.value}
                        onFilterChange={(s) => {
                            setSearchFilter({
                                updated: true,
                                value: s
                            })
                        }}>
                    </SearchFilter>
                </div>
                <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                    <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                        <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                            <table style={{ width: "100%", fontSize: "14px" }}>
                                <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                    <tr>
                                        <th style={{ textAlign: "left", }}>Code</th>
                                        <th style={{ textAlign: "left", }}>Description</th>
                                        <th style={{ textAlign: "left" }}>Comments</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        events &&
                                        events.map((event, index) => {
                                            const finalEvent = event;
                                            const isSelected = (selectedEvent && finalEvent.id === selectedEvent?.id);
                                            return (
                                                <tr key={index}
                                                    style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                                    onClick={() => {
                                                        setSelectedEvent(finalEvent)
                                                    }}>
                                                    <td style={{ textAlign: "left" }}>{finalEvent.code}</td>
                                                    <td style={{ textAlign: "left" }}>{finalEvent.description}</td>
                                                    <td style={{ textAlign: "left" }}>{finalEvent.comment}</td>
                                                </tr>);
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {
                        Boolean(data?.pageCount && data?.pageCount > 1) &&
                        <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                            <Paginator
                                changeIndicator={data}
                                initialNumber={pageNumber.value}
                                pageSize={data?.pageSize}
                                pageCount={data?.pageCount}
                                count={data?.count}
                                onPageSelected={(page) => {
                                    console.log(page)
                                    setPageNumber({
                                        value: page.number,
                                        updated: true
                                    })
                                }} />
                        </div>
                    }
                </div>
                <div style={{ padding: "10px", display: "flex", flexDirection: "row" }}>
                    <button style={{ width: "120px" }} onClick={() => {
                        setEventCreation({
                            open: true
                        })
                    }}>
                        Add Event
                    </button>
                    <button style={{ padding: "2px", width: "120px", margin: "0px 5px" }}
                        disabled={!selectedEvent}
                        onClick={() => {
                            if (selectedEvent) {
                                //setEditEvent(selectedEvent)
                                setEventCreation({
                                    open: true,
                                    editEvent: selectedEvent
                                })
                            }
                        }}>
                        Edit
                    </button>
                    <button style={{ padding: "2px", width: "120px", margin: "0px 5px" }}
                        disabled={!selectedEvent}
                        onClick={() => {
                            setEventDeletion({
                                open: true,
                                title: "Confirm Delete Action",
                                message: `You are about to delete event: code ${selectedEvent.code}, desc ${selectedEvent.description}`,
                                event: selectedEvent
                            });
                        }}>
                        Delete
                    </button>
                    <div style={{ flex: 1 }}></div>
                </div>
            </div>
            {eventCreation?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <EventCreation
                    open={eventCreation?.open}
                    onCancel={() => {
                        setEventCreation(null)
                    }}
                    onEventCreated={() => {
                        setEventCreation(null)
                        updateEventsList();
                    }}
                    editEvent={eventCreation?.editEvent}
                />
            </div>}
            {eventDeletion?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    onCancel={() => {
                        setEventDeletion(null)
                    }}
                    onConfirm={(event) => {
                        const data = {
                            eventId: event.id,
                        };

                        showLoading(true)
                        deleteEvent(data, (e) => {
                            showLoading(false)
                            if (e.error) {
                                return;
                            }

                            setEventDeletion(null)
                        });
                    }}
                    title={eventDeletion?.title}
                    data={eventDeletion?.event}
                    open={eventDeletion?.open}
                    message={eventDeletion?.message} />
            </div>}
        </div>);
}

export default EventsListView;

