import React from 'react'
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import { hexToRgbaWithAlpha } from "../../../utilities/convertColors.js";
import { AppContext } from '../../../AppContext.js';
import {
    fetchUssdStoredCodes,
    createUssdStoredCode,
    deleteUssdStoredCode,
    editUssdStoredCode
} from "../../../calls/administration/ussd/fetchUssd.js";
import ConfirmBox from '../ConfirmBox.js';

function UssdSavedCode(props) {
    const [codeCreationOption, setCodeCreationOption] = React.useState(null);
    const [codeDeletionOption, setCodeDeletionOption] = React.useState(null);
    const [storedCodes, setStoredCodes] = React.useState([]);
    const [selectedStoredCode, setSelectedStoredCode] = React.useState(null);
    const [refresh, setRefresh] = React.useState(true);

    const updateStoredUssdCodes = () => {
        fetchUssdStoredCodes((e) => {
            if (e.error) {
                return;
            }

            setStoredCodes(e.data.data)
        })
    }

    React.useEffect(() => {
        if (refresh) {
            setRefresh(false)
            updateStoredUssdCodes();
        }
    }, [refresh]);

    return (
        <dialog open={true}
            style={{ padding: "2px", borderWidth: "1px", borderRadius: "5px", width: "80%", height: "70%" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Ussd Saved Codes
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: 14 }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px" }}></hr>
                <div style={{ display: "flex", flex: 1 }} onClick={() => {
                    setSelectedStoredCode(null)
                }}>
                    <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                        <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                            <table style={{ width: "100%", fontSize: "12px" }} onClick={(e) => { e.stopPropagation(); }}>
                                <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                    <tr>
                                        <th style={{ textAlign: "left", }}>Code</th>
                                        <th style={{ textAlign: "left", }}>Description</th>
                                        <th style={{ textAlign: "left" }}>Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        storedCodes &&
                                        storedCodes.map((code, index) => {
                                            const finalCode = code;
                                            const isSelected = (selectedStoredCode && finalCode.id === selectedStoredCode?.id);
                                            return (
                                                <tr key={index}
                                                    style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                                    onClick={() => {
                                                        setSelectedStoredCode(finalCode)
                                                    }}>
                                                    <td style={{ textAlign: "left" }}>{finalCode.code}</td>
                                                    <td style={{ textAlign: "left" }}>{finalCode.description}</td>
                                                    <td style={{ textAlign: "left" }}>{finalCode.comment}</td>
                                                </tr>);
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px" }}></hr>
                <div style={{ justifyContent: "space-between", display: "flex", gap: "10px", fontSize: "12px" }}>
                    <button style={{ width: "100%", fontSize: "12px" }} onClick={() => {
                        setCodeCreationOption({
                            open: true
                        })
                    }}>Store Code</button>
                    <button style={{ width: "100%", fontSize: "12px" }} disabled={!selectedStoredCode} onClick={() => {
                        const selected = selectedStoredCode;
                        if (selected && props.onCopy)
                            props.onCopy(selected)
                    }}>Copy</button>
                    <button style={{ width: "100%", fontSize: "12px" }} disabled={!selectedStoredCode} onClick={() => {
                        setCodeCreationOption({
                            open: true,
                            editStoredCode: selectedStoredCode
                        })
                    }}>Edit</button>
                    <button style={{ width: "100%", fontSize: "12px" }} disabled={!selectedStoredCode} onClick={() => {
                        setCodeDeletionOption({
                            open: true,
                            deleteStoredCode: selectedStoredCode
                        })
                    }}>Delete</button>
                </div>
                {
                    codeCreationOption?.open &&
                    <div
                        style={{
                            color: "orangered",
                            alignItems: "center", justifyContent: "center",
                            display: "flex", width: "100%", height: "100%", position: "absolute",
                            backgroundColor: hexToRgbaWithAlpha("black", 0.25)
                        }}>
                        <UssdSavedCodeCreation
                            editStoredCode={codeCreationOption.editStoredCode}
                            onCreation={() => {
                                setCodeCreationOption(null)
                                setRefresh(true);
                            }}
                            onClose={() => {
                                setCodeCreationOption(null)
                            }} />
                    </div>
                }
                {
                    codeDeletionOption?.open &&
                    <div style={{
                        width: "100%", height: "100%", display: "flex",
                        position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                        right: 0, left: 0, top: 0, bottom: 0
                    }}>
                        <ConfirmBox
                            open={codeDeletionOption?.open}
                            title="Stored code deletion"
                            onCancel={() => {
                                setCodeDeletionOption(null)
                            }}
                            onConfirm={() => {
                                const code = codeDeletionOption.deleteStoredCode;
                                setCodeDeletionOption(null)
                                //showLoading(true);
                                deleteUssdStoredCode((e) => {
                                    //showLoading(false);
                                    if (e.error) {
                                        return;
                                    }

                                    setRefresh(true);
                                }, code?.id);
                            }}
                            message={`You are about to delete stored code ${codeDeletionOption.deleteStoredCode?.code}`} />
                    </div>
                }
            </div>
        </dialog>);
};

function UssdSavedCodeCreation(props) {
    const [code, setCode] = React.useState("");
    const [description, setDescription] = React.useState("");
    React.useEffect(() => {
        const editStoredCode = props.editStoredCode;
        if (editStoredCode) {
            setCode(editStoredCode.code)
            setDescription(editStoredCode.description)
        }
    }, []);

    const isEdit = props.editStoredCode;
    return (
        <dialog open={true}
            style={{ borderWidth: "1px", borderRadius: "5px", width: "80%", padding: "5px" }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", padding: "2px" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Ussd Saved Codes
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: 14 }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px" }}></hr>
                <div style={{ display: "flex", flex: 1, width: "100%" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="stored_code_creation_code">Code</label>
                            <input style={{ display: "block", width: "100%" }}
                                readOnly={isEdit}
                                type="text" id="stored_code_creation_code"
                                placeholder="Enter code" value={code}
                                autoComplete="off"
                                onChange={(e) => {
                                    setCode(e.target.value)
                                }} />
                        </div>
                        <div style={{ display: "flex", margin: "5px 0px", textAlign: "left" }}>
                            <label style={{ display: "block", width: "140px" }} htmlFor="stored_code_creation_desc">Description</label>
                            <textarea style={{ display: "block", width: "100%", resize: "none" }}
                                type="text"
                                id="stored_code_creation_desc"
                                placeholder="Enter Code description" value={description}
                                autoComplete="off"
                                onChange={(e) => {
                                    setDescription(e.target.value)
                                }} />
                        </div>
                    </div>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px" }}></hr>
                <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <button onClick={() => {
                        if (!description || !code) {
                            return;
                        }

                        const data = {
                            description: description,
                            code: code
                        };

                        const edit = props.editStoredCode;
                        if (edit) {
                            editUssdStoredCode((e) => {
                                if (e.error) {
                                    console.log(e.error);
                                    return;
                                }

                                if (props.onCreation) {
                                    props.onCreation();
                                }
                            }, edit.id, data);

                        } else {
                            createUssdStoredCode((e) => {
                                if (e.error) {
                                    console.log(e.error);
                                    return;
                                }

                                if (props.onCreation) {
                                    props.onCreation();
                                }
                            }, data);
                        }
                    }}>Confirm</button>
                </div>
            </div>
        </dialog>);
};

export default UssdSavedCode;