import React from 'react'
import { IconContext } from "react-icons";
import { GrClose } from "react-icons/gr";
import { isMobile } from 'react-device-detect';
import fetchAccount from '../../../calls/administration/accounts/fetchAccount';
import fetchFreeAssets from '../../../calls/administration/assets/fetchFreeAssets';
import unlinkAsset from '../../../calls/administration/assets/unlinkAsset';
import linkAsset from '../../../calls/administration/assets/linkAsset';
import ConfirmBox from '../ConfirmBox';
import { getEmailOrPhoneNumber } from "../../../utilities/userHelper"
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';
import './AccountSetup.css';

const general = "general"
function AccountSetup(props) {
    const [selectedTab, setSelectedTab] = React.useState(general);
    const [account, setAccount] = React.useState(null);
    const awesomeSize = isMobile ? 18 : 14;

    const refreshAccount = () => {
        const accountId = props.account?.id;
        if (!accountId) return;
        fetchAccount((e) => {
            if (e.error) {
                return;
            }

            console.log(e.data);
            setAccount(e.data);

        }, accountId);
    }

    React.useEffect(() => {
        refreshAccount();
    }, []);

    return (
        <dialog open={true}
            style={{
                width: "480px", height: "430px",
                top: "50%", bottom: "50%", marginRight: "auto", marginLeft: "auto",
                padding: "4px", borderWidth: "1px", borderRadius: "5px"
            }}>

            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", position: "relative" }}>
                <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                    <div style={{ width: "100%", display: "flex", flex: 1 }}>
                        Account Setup for {props.account.name}
                    </div>
                    <button style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose();
                            }
                        }}>
                        <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                            <IconContext.Provider value={{ size: awesomeSize }}>
                                <GrClose />
                            </IconContext.Provider>
                        </div>
                    </button>
                </div>
                <hr style={{ height: "1px", width: "100%", padding: "0px", margin: "2px 0px 2px 0px" }}></hr>
                <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "grid", gap: "5px", gridTemplateColumns: "repeat(4, 1fr)" }}>
                        <button
                            className={selectedTab === general ? "account_setup_active_tab_header" : "account_setup_tab_header"}
                            onClick={() => {
                                setSelectedTab(general);
                            }}>
                            General
                        </button>
                        <button
                            className={selectedTab === 'users' ? "account_setup_active_tab_header" : "account_setup_tab_header"}
                            onClick={() => {
                                setSelectedTab("users");
                            }}>
                            Users
                        </button>
                        <button
                            className={selectedTab === 'assets' ? "account_setup_active_tab_header" : "account_setup_tab_header"}
                            onClick={() => {
                                setSelectedTab("assets");
                            }}>
                            Assets
                        </button>
                        <button
                            className={selectedTab === 'contacts' ? "account_setup_active_tab_header" : "account_setup_tab_header"}
                            onClick={() => {
                                setSelectedTab("contacts");
                            }}>
                            Contacts
                        </button>
                    </div>
                </div>
                <div style={{ display: "flex", height: "100%", width: "100%" }}>
                    {
                        (selectedTab === "general") &&
                        <AccountGeneral account={account}></AccountGeneral>
                    }
                    {
                        selectedTab === "users" &&
                        <AccountUsers account={account}></AccountUsers>
                    }
                    {
                        selectedTab === "assets" &&
                        <AccountAssets account={account}
                            onRequireRefreh={() => {
                                refreshAccount();
                            }}></AccountAssets>
                    }
                    {
                        selectedTab === "contacts" &&
                        <div style={{ backgroundColor: "pink", height: "100%", width: "100%", display: "flex" }}>

                        </div>
                    }

                </div>
            </div>
        </dialog>
    )
}

function AccountGeneral(props) {
    const account = props.account;
    if (!account) return null;
    const allowFuelNotification = account.allowFuelNotification ? "true" : "false";
    const allowAccidentNotification = account.allowAccidentNotification ? "true" : "false";
    const allowInputOutputNotification = account.allowInputOutputNotification ? "true" : "false";

    return (
        <div style={{
            padding: "20px",
            width: "100%", display: "flex", flexDirection: "column", flexGrow: 1,
            alignItems: "start"
        }}>
            <table>
                <tr>
                    <td className='general-account-header'>Information</td>
                </tr>
                <tr>
                    <td style={{ textAlign: "left" }}>Account Name</td>
                    <td> : </td>
                    <td>{account.name}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <td className='general-account-header'>Notifications</td>
                </tr>
                <tr>
                    <td style={{ textAlign: "left" }}>Fuel Notification</td>
                    <td> : </td>
                    <td>{allowFuelNotification}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: "left" }}>Accident Notification</td>
                    <td> : </td>
                    <td>{allowAccidentNotification}</td>
                </tr>
                <tr>
                    <td style={{ textAlign: "left" }}>I/Os Notification</td>
                    <td> : </td>
                    <td>{allowInputOutputNotification}</td>
                </tr>
            </table>
            <table>
                <tr>
                    <td className='general-account-header'>Users ({account?.users ? account.users.length : 0})</td>
                </tr>
                {account.users.map((user, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ textAlign: "left" }}>{user.userName}</td>
                            <td>{getEmailOrPhoneNumber(user)}</td>
                        </tr>);
                })}
            </table>
            <table>
                <tr>
                    <td className='general-account-header'>Assets ({account?.users ? account.users.length : 0})</td>
                </tr>
                {account.assets.map((asset, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ textAlign: "left" }}>{asset.name}</td>
                            <td>{asset.activeUnit?.hwSerial}</td>
                        </tr>);
                })}
            </table>
        </div>
    )
}

function AccountUsers(props) {
    const [selectedUser, setSelectedUser] = React.useState(null);
    const account = props.account;
    return (
        <div style={{
            padding: "10px",
            width: "100%", display: "flex", flexDirection: "column", flexGrow: 1,
            alignItems: "start"
        }}>
            <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "12px" }}>
                        <thead style={{ position: "sticky", top: "-1px" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Name</th>
                                <th style={{ textAlign: "left", }}>Email</th>
                                <th style={{ textAlign: "left", }}>Roles</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                account?.users &&
                                account.users.map((user, index) => {
                                    const finalUser = user;
                                    const isSelected = (selectedUser && finalUser.id === selectedUser?.id);
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                                setSelectedUser(finalUser)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{finalUser.userName}</td>
                                            <td style={{ textAlign: "left" }}>{finalUser.email}</td>
                                            <td style={{ textAlign: "left" }}>{finalUser.roles}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <table cellSpacing="5px">
                {account.users.map((user, index) => {
                    return (
                        <tr key={index}>
                            <td style={{ textAlign: "left" }}>{user.userName}</td>
                            <td>{user.email}</td>
                        </tr>);
                })}
            </table> */}
        </div>
    )
}

function AccountAssets(props) {
    const [linkAsset, setLinkAsset] = React.useState(false);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [assetToUnlink, setAssetToUnlink] = React.useState(null);
    const account = props.account;
    const hasUsers = account?.assets && account.assets.length > 0;
    return (
        <div style={{ width: "100%", display: "flex", height: "100%" }}>
            <div style={{
                width: "100%", display: "flex", flexDirection: "column", alignItems: "start", height: "100%"
            }}>
                <div style={{ display: "flex", width: "100%", flexGrow: 1, overflow: "auto", flexDirection: "column" }}>
                    {
                        hasUsers &&
                        <table style={{ width: "100%", fontSize: "12px" }}>
                            {account.assets.map((asset, index) => {
                                const finalAsset = asset;
                                const isSelected = (selectedAsset && finalAsset.id === selectedAsset?.id);
                                return (
                                    <tr key={index}
                                        style={{ backgroundColor: isSelected ? "red" : "transparent" }} onClick={() => {
                                            setSelectedAsset(finalAsset)
                                        }}>
                                        <td style={{ textAlign: "left" }}>{asset.name}</td>
                                        <td style={{ textAlign: "left" }}>{asset?.activeUnit?.hwSerial}</td>
                                        {/* <td>{asset.email}</td> */}
                                    </tr>);
                            })}
                        </table>
                    }
                    {
                        !hasUsers &&
                        <div style={{ alignItems: "center", justifyContent: "center", display: "flex", width: "100%", height: "100%" }}>
                            <span >No user record found</span>
                        </div>
                    }
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    <button onClick={() => {
                        setAssetToUnlink(null)
                        setLinkAsset(true);
                    }}>Link new asset</button>
                    {
                        selectedAsset &&
                        <button disabled={!selectedAsset} onClick={() => {
                            setAssetToUnlink(selectedAsset);
                        }}>Unlink asset</button>
                    }

                </div>
            </div>
            {
                linkAsset &&
                <div style={{
                    display: "flex",
                    position: "absolute", left: "0", top: "0",
                    width: "100%", height: "100%", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    alignItems: "center", justifyContent: "center"
                }}>
                    <AccountAssetLink
                        {...props}
                        onClose={() => {
                            setLinkAsset(null)
                        }}
                        onLinkSuccess={() => {
                            setLinkAsset(null);
                            if (props.onRequireRefreh)
                                props.onRequireRefreh();
                        }}
                    ></AccountAssetLink>
                </div>
            }
            {
                assetToUnlink &&
                <ConfirmBox
                    open={assetToUnlink}
                    title="Asset Unlink"
                    onCancel={() => {
                        setAssetToUnlink(null)
                    }}
                    onConfirm={() => {
                        const data = {
                            assetId: assetToUnlink.id,
                            accountId: assetToUnlink.accountId
                        };

                        setAssetToUnlink(null)
                        unlinkAsset((e) => {
                            if (e.error) {
                                return;
                            }

                            if (props.onRequireRefreh)
                                props.onRequireRefreh();
                            console.log(e.data);
                        }, data)
                    }}
                    message={`You are about to unlink asset ${assetToUnlink?.name}`} />
            }
        </div>
    )
}

function AccountAssetLink(props) {
    const [assets, setAssets] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const updateFreeAssets = () => {
        fetchFreeAssets((e) => {
            if (e.error) {
                return;
            }

            console.log(e.data);
            setAssets(e.data)
        })
    }

    React.useEffect(() => {
        updateFreeAssets();
    }, []);
    const awesomeSize = isMobile ? 18 : 14;
    const hasAssets = assets && assets.length > 0;
    return (
        <div style={{
            padding: "4px", height: "80%", backgroundColor: "white",
            width: "80%", display: "flex", flexDirection: "column",
        }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div style={{ width: "100%", display: "flex" }}>
                    Asset Link
                </div>
                <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize }}>
                            <GrClose />
                        </IconContext.Provider>
                    </div>
                </button>
            </div>
            <div style={{ display: "flex", width: "100%", flexGrow: 1 }}>
                {
                    hasAssets &&
                    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                        <table style={{ width: "100%", fontSize: "12px" }}>
                            {assets.map((asset, index) => {
                                const isSelected = (asset.id === selectedAsset?.id);
                                return (
                                    <tr style={{ backgroundColor: isSelected ? "red" : "transparent" }} key={index} onClick={() => {
                                        setSelectedAsset(asset)
                                    }}>
                                        <td style={{ textAlign: "left" }}>{asset.name}</td>
                                        <td style={{ textAlign: "left" }}>{asset.activeUnit.hwSerial}</td>
                                        {/* <td>{asset.email}</td> */}
                                    </tr>);
                            })}
                        </table>
                    </div>
                }
                {
                    !hasAssets &&
                    <div>No free asset record found</div>
                }
            </div>
            <div>
                <button disabled={!selectedAsset} onClick={() => {
                    const data = {
                        assetId: selectedAsset.id,
                        accountId: props.account.id
                    };

                    linkAsset((e) => {
                        if (e.error) {
                            if (props.onLinkError)
                                props.onLinkError(e);
                            return;
                        }

                        if (props.onLinkSuccess)
                            props.onLinkSuccess(e.data);
                        console.log(e.data);
                    }, data)
                }}>Link asset</button>
            </div>
        </div>
    )
}

export default AccountSetup;