import React from "react";
import { AppContext } from "../../AppContext";
import getUserProfile from "../../calls/authentication/getUserProfile";
import configureDebugUser from "../../calls/administration/users/configureDebugUser";
import { showLoading } from 'react-global-loading';

function AdminSettingPage(props) {
    const [debug, setDebug] = React.useState(false);
    const { activeUser, setActiveUser } = React.useContext(AppContext);

    const updateUserProfile = (onResult) => {
        showLoading(true)
        getUserProfile((e) => {
            showLoading(false)
            console.log(e)
            if (e.error) {
                if (onResult) onResult(false)
                return;
            }

            const user = e.data.user;
            const roles = e.data.roles;

            setActiveUser(user);
            // const normalisedRoles = roles.map(element => {
            //     return element.toLowerCase();
            // });

            // setActiveRoles(normalisedRoles);
            if (onResult) onResult(true)
        })
    }

    React.useEffect(() => {
        showLoading(true)
        updateUserProfile();
    }, []);

    React.useEffect(() => {
        if (!activeUser) return
        setDebug(activeUser.debug)
    }, [activeUser]);

    return (
        <div style={{ display: "flex", padding: "10px" }}>
            <div style={{ display: "flex", gap: "20px", alignItems: "center", flexDirection: "row" }}>
                <label style={{ textAlign: "left" }} htmlFor="admin_settings_page_debug">Accident notification</label>
                <input
                    type="checkbox" id="admin_settings_page_debug"
                    checked={debug}
                    onChange={(e) => {
                        const user = activeUser;
                        if (!user.id)
                            return
                        const newDebug = !debug
                        setDebug(newDebug);
                        const requestData = {
                            state: newDebug
                        }
                        configureDebugUser(() => {
                            if (e.error) {
                                return;
                            }

                            updateUserProfile();
                        }, user.id, requestData)
                    }} />
            </div>
        </div>
    );
}

export default AdminSettingPage;