import React from 'react';
import { lang } from '../language/lang';
import DropdownList from "react-widgets/DropdownList";
import DatePicker from "react-widgets/DatePicker";
import dateFormat from "dateformat";
import { isMobile } from 'react-device-detect';
import { getStartDateTime, getEndDateTime } from '../utilities/dateHelper'
import { forwardRef, useImperativeHandle } from 'react';

function TimeFrameComponent(props, ref) {
    const title = 'Interval de temps';
    const frames = props.demo ?
        [
            "Dernière position",
            '30 dernières minutes',
            "60 dernières minutes",
            "3 dernières heures",
            "6 dernières heures",
            "12 dernières heures",
            // "Aujourd'hui",
            // "Hier",
            // "Personnalisé"
        ] :
        [
            "Dernière position",
            '30 dernières minutes',
            "60 dernières minutes",
            "3 dernières heures",
            "6 dernières heures",
            "12 dernières heures",
            "Aujourd'hui",
            "Hier",
            "Personnalisé"
        ];

    const now = new Date();
    const [value, setValue] = React.useState(frames[0]);
    const [startDate, setStartDate] = React.useState(new Date(getStartDateTime(now.getTime() - 1)));
    const [endDate, setEndDate] = React.useState(new Date(getEndDateTime(now.getTime())));

    const [startPickerOpen, setStartPickerOpen] = React.useState(undefined);
    const [endPickerOpen, setEndPickerOpen] = React.useState(undefined);
    const [hourPrecision, setHourPrecision] = React.useState(false);
    const [popupToggle, setPopupToggle] = React.useState(false);

    const configureTimeFrame = (config) => {
        if (config.valueIndex) {
            if (config.valueIndex >= 0 && config.valueIndex < frames.length) {
                setValue(frames[config.valueIndex]);
            }
            else {
                setValue(-1)
            }
        }
        if (config.start) setStartDate(config.start)
        if (config.end) setEndDate(config.end)
        if (config.precision) setHourPrecision(config.precision)
    }


    const getTimeFrameConfiguration = () => {
        return {
            start: startDate,
            end: endDate,
            valueIndex: frames.indexOf(value),
            precision: hourPrecision,
            pop: popupToggle
        };
    }

    const getRange = () => {
        if (!value) {
            return null
        }

        var hasToday = false;
        if (frames.length >= 8 && value === frames[8]) {

            var startTime = startDate.getTime();
            var endTime = endDate.getTime();

            if (!hourPrecision) {
                startTime = getStartDateTime(startTime);
                endTime = getEndDateTime(endTime);
            }

            var now = Date.now();

            if (startTime > endTime) {
                console.error("Start time cannot be greater than end time");
                throw "Start time cannot be greater than end time"
            }

            else if (!hourPrecision && startTime === getStartDateTime(now) && endTime === getEndDateTime(now)) {
                hasToday = true;
            }
            else {
                return {
                    end: new Date(endTime),
                    start: new Date(startTime),
                    description: `From ${dateFormat(startTime, "dd mmm yyyy")} to ${dateFormat(endTime, "dd mmm yyyy")}`
                }
            }
        }

        if (value === frames[0]) {
            return null;
        }

        if (value === frames[1]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 30 * 60 * 1000);
            return { end: end, start: start, description: frames[1] }
        }

        if (value === frames[2]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 60 * 60 * 1000);
            return { end: end, start: start, description: frames[2] }
        }

        if (value === frames[3]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 3 * 60 * 60 * 1000);
            return { end: end, start: start, description: frames[3] }
        }

        if (value === frames[4]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 6 * 60 * 60 * 1000);
            return { end: end, start: start, description: frames[4] };
        }

        if (frames.length >= 5 && value === frames[5]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 12 * 60 * 60 * 1000);
            return { end: end, start: start, description: frames[5] }
        }

        if (frames.length >= 6 && (value === frames[6] || hasToday)) {
            const daySpan = 24 * 60 * 60 * 1000;
            var today = new Date(Date.now());
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();


            var start = new Date(year, month, day, 0, 0, 0, 0);
            var end = new Date(start.getTime() + daySpan - 1);
            return { end: end, start: start, description: frames[6] }
        }

        if (frames.length >= 7 && value === frames[7]) {
            const daySpan = 24 * 60 * 60 * 1000;
            var yesterday = new Date(Date.now() - daySpan);
            var year = yesterday.getFullYear();
            var month = yesterday.getMonth();
            var day = yesterday.getDate();


            var start = new Date(year, month, day, 0, 0, 0, 0);
            var end = new Date(start.getTime() + daySpan - 1);
            return { end: end, start: start, description: frames[7] }
        }
    }

    useImperativeHandle(ref, () => ({
        configureTimeFrame: configureTimeFrame,
        getTimeFrameConfiguration: getTimeFrameConfiguration,
        getRange: getRange
    }));

    const onGoClick = (e) => {
        try {
            var range = getRange();
            if (!props.onGo) return;
            props.onGo(range)

        }
        catch (e) {
            console.error(e);
        }
    }

    const onGoClick2 = (e) => {
        if (!value) {
            props.onGo(null)
        }

        var hasToday = false;
        if (frames.length >= 8 && value === frames[8]) {

            var startTime = startDate.getTime();
            var endTime = endDate.getTime();

            if (!hourPrecision) {
                startTime = getStartDateTime(startTime);
                endTime = getEndDateTime(endTime);
            }

            var now = Date.now();

            if (startTime > endTime) {
                console.error("Start time cannot be greater than end time");
                return;
            }

            else if (!hourPrecision && startTime === getStartDateTime(now) && endTime === getEndDateTime(now)) {
                hasToday = true;
            }
            else {
                if (!props.onGo) return;
                props.onGo({
                    end: new Date(endTime),
                    start: new Date(startTime),
                    description: `From ${dateFormat(startTime, "dd mmm yyyy")} to ${dateFormat(endTime, "dd mmm yyyy")}`
                });
                return;
            }
        }

        if (value === frames[0]) {
            props.onGo(null)
            return;
        }

        if (value === frames[1]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 30 * 60 * 1000);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[1] });
        }

        if (value === frames[2]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 60 * 60 * 1000);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[2] });
        }

        if (value === frames[3]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 3 * 60 * 60 * 1000);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[3] });
        }

        if (value === frames[4]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 6 * 60 * 60 * 1000);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[4] });
        }

        if (frames.length >= 5 && value === frames[5]) {
            var end = new Date(Date.now());
            var start = new Date(end.getTime() - 12 * 60 * 60 * 1000);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[5] });
        }

        if (frames.length >= 6 && (value === frames[6] || hasToday)) {
            const daySpan = 24 * 60 * 60 * 1000;
            var today = new Date(Date.now());
            var year = today.getFullYear();
            var month = today.getMonth();
            var day = today.getDate();


            var start = new Date(year, month, day, 0, 0, 0, 0);
            var end = new Date(start.getTime() + daySpan - 1);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[6] });
        }

        if (frames.length >= 7 && value === frames[7]) {
            const daySpan = 24 * 60 * 60 * 1000;
            var yesterday = new Date(Date.now() - daySpan);
            var year = yesterday.getFullYear();
            var month = yesterday.getMonth();
            var day = yesterday.getDate();


            var start = new Date(year, month, day, 0, 0, 0, 0);
            var end = new Date(start.getTime() + daySpan - 1);
            if (!props.onGo) return;
            props.onGo({ end: end, start: start, description: frames[7] });
        }
    }

    const mobile = isMobile;
    const titleFontSize = mobile ? 10 : 14;
    const panelPadding = mobile ? "0px 4px" : "2px 12px";
    const goButtonHeight = mobile ? "20px" : "25px";
    const pickerLabelWidth = mobile ? "38px" : "45px";


    const startPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setStartPickerOpen(true);
        }
    };

    const endPickerInputProps = {
        editing: false,
        readOnly: true,
        onClick: (e) => {
            setEndPickerOpen(true);
        }
    };

    const renderCustomDates = () => {
        return <div style={{ padding: panelPadding }} >
            <div style={{ display: "flex", margin: "5px 0px", alignItems: "baseline" }}>
                <label htmlFor="hourPrecision" style={{ minWidth: pickerLabelWidth, textAlign: "left", fontSize: titleFontSize, display: "block" }}>
                    {lang.language.hourPrecision}
                </label>
                <div style={{ display: "flex", width: "100%", flex: 1 }}>
                    <input
                        style={{ display: "flex", flex: 1 }}
                        id="hourPrecision"
                        type="checkbox"
                        checked={hourPrecision}
                        onChange={(e) => {
                            setHourPrecision(!hourPrecision)
                            setStartDate(new Date(getStartDateTime(startDate)))
                            setEndDate(new Date(getEndDateTime(endDate)))
                        }} />
                </div>

            </div>
            <div style={{ display: "flex", margin: "2px 0px", alignItems: "baseline" }}>
                <label htmlFor="fromDate" style={{ minWidth: pickerLabelWidth, textAlign: "left", fontSize: titleFontSize }}>
                    {lang.language.fromDate}
                </label>
                <DatePicker
                    style={{ flex: 1 }}
                    autoFocus={false}
                    onChange={e => setStartDate(e)}
                    id="fromDate"
                    dropUp={true}
                    value={startDate}
                    open={startPickerOpen}
                    filter={undefined}
                    inputProps={startPickerInputProps}
                    defaultValue={startDate}
                    max={endDate}
                    valueFormat={{ dateStyle: "medium", timeStyle: hourPrecision ? "short" : undefined, hourCycle: 'h23' }}
                    readOnly={props.disabled}
                    onBlur={() => { setStartPickerOpen(false) }}
                    onSelect={() => { setStartPickerOpen(false) }}
                    onToggle={(state) => {
                        setPopupToggle(state)
                        setStartPickerOpen(state)
                    }}
                    includeTime={hourPrecision} />
            </div>
            <div style={{ display: "flex", margin: "2px 0px", alignItems: "baseline" }}>
                <label htmlFor="toDate" style={{ minWidth: pickerLabelWidth, textAlign: "left", fontSize: titleFontSize }}>
                    {lang.language.toDate}
                </label>
                <DatePicker
                    style={{ flex: 1 }}
                    onChange={e => setEndDate(e)}
                    autoFocus={false}
                    id="toDate"
                    open={endPickerOpen}
                    dropUp={true}
                    value={endDate}
                    min={startDate}
                    inputProps={endPickerInputProps}
                    filter={undefined}
                    defaultValue={endDate}
                    valueFormat={{ dateStyle: "medium", timeStyle: hourPrecision ? "short" : undefined, hourCycle: 'h23' }}
                    readOnly={props.disabled}
                    onBlur={() => {
                        setEndPickerOpen(false)
                    }}
                    onSelect={() => {
                        setEndPickerOpen(false)
                    }}
                    onToggle={(state) => {
                        setPopupToggle(state)
                        setEndPickerOpen(state)
                    }}
                    includeTime={hourPrecision} />
            </div>
        </div>;
    }

    const personnalised = frames.length >= 8 && value === frames[8];
    if (!props.visible) {
        return null;
    }

    return <div style={{
        height: "100%", width: "100%", display: 'flex',
        flexDirection: 'column', borderTopWidth: "1px",
        borderStyle: "solid", borderColor: "#ccc",
        borderLeftStyle: "none", borderRightStyle: "none",
        overflow: popupToggle || startPickerOpen || endPickerOpen ? "visible" : "auto"
    }}>
        <div style={{ flexGrow: 1, width: "100%", display: "flex" }}>
            <div style={{ display: "flex", alignContent: "center", padding: panelPadding, width: "100%", justifyContent: "right", fontWeight: "bold", fontSize: titleFontSize }}>{title}</div>
        </div>
        {
            personnalised ? renderCustomDates() : null
        }
        <div style={{ padding: panelPadding }} >
            <DropdownList
                //readOnly={false}
                dropUp
                defaultValue={frames[0]}
                data={frames}
                value={value}
                filter={false}
                onChange={e => setValue(e)}
                disabled={props.disabled}
                autoFocus={false}
                onToggle={(state) => {
                    setPopupToggle(state)
                }}
            />
        </div>
        <div style={{ padding: panelPadding }}>
            <button disabled={props.disabled} style={{
                width: "100%",
                backgroundColor: "whitesmoke",
                color: "black",
                fontSize: titleFontSize,
                height: goButtonHeight,
                borderRadius: "2px",
                borderWidth: "2px",
                margin: "5px 0px 1px 0px",
                borderColor: 'lightgray',
                cursor: props.disabled ? "not-allowed" : "pointer"
            }} onClick={onGoClick}>Go</button>
        </div>
    </div>;
}

export default forwardRef(TimeFrameComponent);
