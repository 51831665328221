import React from 'react'
import { showLoading } from 'react-global-loading';

import fetchRoles from '../../../calls/administration/roles/fetchRoles';
import deleteRole from '../../../calls/administration/roles/deleteRole';

import ConfirmBox from '../ConfirmBox';
import RoleCreation from './RoleCreation';
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';

function RolesListView(props) {
    const [roles, setRoles] = React.useState([]);
    const [selectedRole, setSelectedRole] = React.useState(null);
    const [roleCreationData, setRoleCreationData] = React.useState(null);
    const [busy, setBusy] = React.useState(true);
    const [roleDeleteData, setRoleDeleteData] = React.useState(null)

    const updateRoles = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        fetchRoles((e) => {
            setBusy(false);
            showLoading(false);
            if (e.error) {
                return;
            }

            const roles = e?.data;
            setRoles(roles);
        });
    }

    React.useEffect(() => {
        updateRoles(false);
    }, []);

    if (busy) return null;
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div id='topbar' style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Name</th>
                                    <th style={{ textAlign: "left", }}>Description</th>
                                    <th style={{ textAlign: "left", }}>Level</th>
                                    <th style={{ textAlign: "left", }}>Active</th>
                                    <th style={{ textAlign: "left", }}>Primary</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    roles &&
                                    roles.map((role, index) => {
                                        const finalRole = role;
                                        const isSelected = (finalRole && finalRole.id === selectedRole?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                                    setSelectedRole(finalRole)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalRole.name}</td>
                                                <td style={{ textAlign: "left" }}>{finalRole.description ?? '__'}</td>
                                                <td style={{ textAlign: "left" }}>{finalRole.level ?? '__'}</td>
                                                <td style={{ textAlign: "left" }}>{String(finalRole.active)}</td>
                                                <td style={{ textAlign: "left" }}>{String(finalRole.primary)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    onClick={() => {
                        setSelectedRole(null)
                        setRoleCreationData({
                            open: true,
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedRole}
                    onClick={() => {
                        if (selectedRole) {
                            setRoleCreationData({
                                open: true,
                                editRole: selectedRole
                            });
                        }
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", width: "80px", margin: "0px 5px" }}
                    disabled={!selectedRole}
                    onClick={() => {
                        setRoleDeleteData({
                            open: true,
                            deleteRole: selectedRole
                        })
                    }}
                >
                    Delete
                </button>
            </div>
            {roleDeleteData?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0
            }}>
                <ConfirmBox
                    open={roleDeleteData?.open}
                    title="Role deletion"
                    onCancel={() => {
                        setRoleDeleteData(null)
                    }}
                    onConfirm={() => {
                        const role = roleDeleteData.deleteRole;
                        const roleId = role.id;
                        if (!roleId) return
                        setRoleDeleteData(null)
                        showLoading(true);
                        deleteRole((e) => {
                            showLoading(false);
                            if (e.error) {

                                return
                            }

                            updateRoles(false)
                        }, roleId);
                    }}
                    message={`You are about to delete role ${roleDeleteData?.deleteRole?.name}`} />
            </div>}
            {
                roleCreationData?.open && <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    justifyContent: "center",
                    alignItems: "center",
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <RoleCreation
                        options={roleCreationData}
                        onRoleCreated={(data) => {
                            setRoleCreationData(null);
                            updateRoles(false);
                        }}
                        onRoleEdited={(data) => {
                            setRoleCreationData(null);
                            updateRoles(false);
                        }}
                        onCancel={() => {
                            setRoleCreationData(null);
                        }} />
                </div>
            }
        </div>);
}

export default RolesListView;