import { useEffect, useState } from 'react';

function useOrientation() {
    const [portrait, setPortrait] = useState(false);
    useEffect(() => {
        function onResize() {
            const innerWidth = window.innerWidth;
            const innerHeight = window.innerHeight;
            if (innerHeight > innerWidth) {
                setPortrait(true);
            }
            else {
                setPortrait(false);
            }
        };

        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        }
    }, []);


    return portrait;
}

export default useOrientation