import Listbox from "react-widgets/Listbox";
import { localTimeFormat, convertTimeSent, toLocalTime } from '../../../utilities/timeHelper.js'
import React from 'react'

import { fetchUssd, } from "../../../calls/administration/ussd/fetchUssd.js";
import forwardUssdRequest from "../../../calls/administration/ussd/forwardUssdRequest.js";
import fetchAssets from '../../../calls/administration/assets/fetchAssets.js'

import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';

import { hexToRgbaWithAlpha } from "../../../utilities/convertColors.js";

import { timeSpanFromMinutes, timeSpanFromHours } from "../../../utilities/dateHelper.js";
import { GrClose } from "react-icons/gr";
import DropdownList from "react-widgets/DropdownList";
import UssdSavedCode from "./UssdSavedCode.js";

function UssdMessenger(props) {
    const [assets, setAssets] = React.useState([]);
    const [selectedAsset, setSelectedAsset] = React.useState(null);
    const [assetSearch, setAssetSearch] = React.useState("");
    const [ussdSend, setUssdSend] = React.useState("");
    const [error, setError] = React.useState("");
    const timerTick = React.useRef(); // <-- React ref

    const [ussdList, setUssdList] = React.useState([]);
    const [busy, setBusy] = React.useState(false);
    const [ranges] = React.useState(["Dernières 20 minutes", "Dernières 24 heures"]);
    const [range, setRange] = React.useState(ranges[0]);
    const [timerTicked, setTimerTicked] = React.useState(ranges[0]);
    const [saveCodeOpen, setSaveCodeOpen] = React.useState(false);

    const updateUssds = () => {
        const assetId = selectedAsset?.id;

        if (!assetId) return;
        console.log(selectedAsset.name)
        const interval = range === ranges[1] ? timeSpanFromHours(24) : timeSpanFromMinutes(20);
        const filter = {
            assetId: assetId,
            interval: interval,

        }

        fetchUssd((e) => {
            if (e.error) {
                console.log(e.error);
                return;
            }

            setUssdList(prev => {
                //var changed = false;
                const currentList = [...prev];
                const incommingList = e.data.data;

                for (const item of incommingList) {
                    const entry = currentList.find(x => x.id === item.id);
                    if (!entry) {
                        //changed = true;
                        currentList.push(item)
                    }
                }

                //if (!changed) return currentList;
                return currentList.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).reverse();
            });
        }, filter);
    }

    const onTimer5s = () => {

        setTimerTicked(true)
        console.log("Interval Tick ...")
    }

    const updateAssets = () => {
        const filter = {
            search: assetSearch
        }

        fetchAssets((e) => {
            if (e.error)
                return;
            setAssets(e.data.data)
        }, filter);
    }

    function assetDroplistFilter(person, value) {
        // let lastname = person.lastName.toLowerCase()
        // let search  = value.toLowerCase();

        // return lastname.indexOf(search) === 0
        return true;
    }

    React.useEffect(() => {
        if (timerTick.current)
            clearInterval(timerTick.current);
        timerTick.current = setInterval(onTimer5s, 5000);

        return () => {
            clearInterval(timerTick.current);
            console.log("Clear Interval")
        }
    }, [props.selectedUssd, range]);

    React.useEffect(() => {
        updateAssets();
    }, [assetSearch]);

    React.useEffect(() => {
        if (timerTicked) {
            setTimerTicked(false)
            updateUssds();
            console.log("timer ticked")
        }
    }, [timerTicked]);

    React.useEffect(() => {

        if (props.initialCode) {
            setUssdSend(props.initialCode.code)
        }

        return () => {
            clearInterval(timerTick.current);
            setError("")
            setUssdSend("")
        }
    }, []);

    React.useEffect(() => {
        updateUssds();
    }, [range]);
    const awesomeSize = isMobile ? 18 : 14;
    return (
        <dialog open={true}
            style={{
                top: "50%",
                bottom: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "2px",
                borderWidth: "1px", borderRadius: "5px", minWidth: "450px", height: "70%"
            }}>
            <div style={{ width: "100%", height: "100%", display: "flex", position: "relative" }}>
                <div style={{ width: "100%", height: "100%", padding: "2px", display: "flex", flexDirection: "column" }}>
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px", gap: "4px" }}>
                        <div style={{ width: "100%", display: "flex", flex: 1 }}>
                            Ussd Messenger
                        </div>
                        <button style={{ backgroundColor: "transparent", borderWidth: "0px" }}
                            onClick={() => {
                                if (props.onClose) {
                                    props.onClose();
                                }
                            }}>
                            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                                <IconContext.Provider value={{ size: awesomeSize }}>
                                    <GrClose />
                                </IconContext.Provider>
                            </div>
                        </button>
                    </div>
                    <div style={{ display: "flex", width: "100%", flexDirection: "column", position: "relative", flex: 1 }}>
                        <div style={{ display: "flex", width: "100%", height: "100%", position: "absolute" }}>
                            <div style={{ display: "flex", width: "100%", flexFlow: "column", height: "100%", gap: "5px", }}>
                                <div style={{ display: "flex", gap: "10px", flexDirection: "row" }}>
                                    <DropdownList
                                        id="asset_selection"
                                        //style={{ display: "block", width: "100%" }}
                                        filter={assetDroplistFilter}
                                        data={assets}
                                        placeholder="Select asset"
                                        dataKey='id'
                                        textField='name'
                                        searchTerm={assetSearch}
                                        value={selectedAsset}
                                        onChange={(value) => {
                                            setSelectedAsset(value)
                                        }}
                                        onSearch={(e) => {
                                            console.log(e);
                                            setAssetSearch(e);
                                        }}
                                        renderValue={({ item }) => (
                                            <span style={{ display: "flex", }}>
                                                <strong style={{ flex: 1 }}>{item?.name}</strong>
                                            </span>
                                        )}
                                    />
                                    <button onClick={() => {
                                        setUssdList([])
                                        setSelectedAsset(null)
                                    }}>
                                        clear
                                    </button>
                                </div>

                                <div
                                    style={{
                                        //pointerEvents: selectedAsset ? "auto" : "none",
                                        display: "flex",
                                        minHeight: "30px",
                                        gap: "5px",
                                        textAlign: "left",
                                        width: "100%",
                                        flexDirection: "row"
                                    }}>
                                    <input disabled={!selectedAsset} style={{ display: "flex", flex: 1, width: "100%", height: "100%", borderColor: "lightgray", textAlign: "center" }}
                                        type="text" id="config_creation_feature"
                                        placeholder="Enter ussd code sequence, ex: *111#$51" value={ussdSend}
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setUssdSend(e.target.value)
                                        }} />
                                    <button
                                        disabled={busy || !selectedAsset}
                                        style={{ height: "100%", display: "block" }}
                                        onClick={() => {
                                            const assetId = selectedAsset?.id;
                                            if (!assetId)
                                                return;
                                            const data = {
                                                code: ussdSend,
                                                assetId: assetId
                                            };

                                            setBusy(true)
                                            forwardUssdRequest(data, (e) => {
                                                setBusy(false)
                                                if (e.error) {
                                                    return;
                                                }

                                                setUssdSend("");
                                            })
                                        }}>Send Code</button>
                                </div>
                                <div style={{ display: "flex", textAlign: "left", alignItems: "start", width: "100%", overflowY: "auto", flex: 1 }}>
                                    <Listbox
                                        dataKey="id"
                                        //style={{ backgroundColor: "transparent" }}
                                        data={ussdList}
                                        onChange={(item) => {
                                        }}
                                        renderItem={({ item }) => {

                                            const time = localTimeFormat(toLocalTime(item.createdAt));
                                            const recent = (new Date() - toLocalTime(item.createdAt)) < (3600 * 1000);
                                            const backgroundColor = recent ? `${hexToRgbaWithAlpha("red", 0.1)}` : undefined;
                                            const lastSeen = recent < 3600 ? ` (${convertTimeSent(item.createdAt)})` : "";
                                            return (
                                                <div style={{ width: "100%", padding: "0px 0px 3px 0px", backgroundColor: backgroundColor }}>
                                                    <div style={{ width: "100%", color: "limegreen" }}>{time}{lastSeen}</div>
                                                    <span style={{ width: "100%" }}>{item.body}</span>
                                                </div>);
                                        }} />
                                </div>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", minHeight: "30px", alignItems: "start", justifyContent: "space-between", textAlign: "left", flexDirection: "row" }}>
                        <button onClick={() => {
                            setSaveCodeOpen(true)
                        }}>
                            Saved codes
                        </button>
                        <div style={{ display: "flex", textAlign: "left", gap: "5px", flexDirection: "row" }}>
                            <span>Interval</span>
                            <DropdownList
                                style={{ width: "160px" }}
                                defaultValue={ranges[0]}
                                data={ranges}
                                value={range}
                                filter={false}
                                onChange={(e) => {
                                    setRange(e)
                                    setUssdList([])
                                }}
                                dropUp
                            />
                        </div>
                    </div>

                </div>
                {
                    saveCodeOpen &&
                    <div
                        style={{
                            color: "orangered",
                            alignItems: "center", justifyContent: "center",
                            display: "flex", width: "100%", height: "100%", position: "absolute",
                            backgroundColor: hexToRgbaWithAlpha("black", 0.25)
                        }}>
                        <UssdSavedCode
                            onClose={() => {
                                setSaveCodeOpen(false)
                            }}
                            onCopy={(e) => {
                                setSaveCodeOpen(false)
                                setUssdSend(e.code)
                            }}>

                        </UssdSavedCode>
                    </div>
                }
            </div>

        </dialog>);
};

export default UssdMessenger;