import React from 'react'
import { AppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
function UserDropdownMenu(props) {
    const navigate = useNavigate();

    const { activeRoles } = React.useContext(AppContext);
    const { authorisationToken } = React.useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);
    const { setPasswordChangeOverlay } = React.useContext(AppContext);
    const admin =
        activeRoles.includes("root") ||
        activeRoles.includes("admin") ||
        activeRoles.includes("support");
    const mouseIn = React.useRef(false);
    const parentMouseIn = React.useRef(false);
    React.useEffect(() => {
        parentMouseIn.current = props.mouseOverButon;
    }, [props.mouseOverButon]);

    React.useEffect(() => {
        const onMouseDown = (e) => {
            if (!mouseIn.current && !parentMouseIn.current) {
                if (props.onMouseClickOutside)
                    props.onMouseClickOutside()
            }
        }

        window.addEventListener('mousedown', onMouseDown);
        return () => {
            window.removeEventListener('mousedown', onMouseDown);
        }
    }, []);

    return (<ul
        onMouseLeave={() => {
            mouseIn.current = false;
        }}
        onMouseEnter={() => {
            mouseIn.current = true;
        }}
        style={{
            position: "absolute", top: "10px", borderRadius: "4px", borderWidth: "2px", borderStyle: "solid",
            borderColor: appTheme.themeColor, backgroundColor: "white", right: `${props.right}px`,
            listStyleType: "none", padding: "10px 10px", width: "150px", zIndex: 1
        }}>
        {
            admin && props.showUserMenuDashboard ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/dashboard")
                    }}>Dashboard</button>
                </li>) :
                null
        }
        {
            admin && props.showUserMenuAdministration ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/administration")
                    }}>Administration</button>
                </li>) : null
        }
        <hr />
        {
            authorisationToken ?
                <li>
                    <button
                        style={{ width: "100%" }}
                        onClick={() => {
                            if (props.onMouseClickOutside)
                                props.onMouseClickOutside()
                            setPasswordChangeOverlay(true)
                        }}>Change Password
                    </button>
                </li> :
                null
        }
        {
            authorisationToken ?
                (<li>

                    <button style={{ width: "100%" }} onClick={() => {
                        navigate("/logout", { replace: true })
                    }}>Deconnexion</button>
                </li>) : null
        }
    </ul>);
}

export default UserDropdownMenu;