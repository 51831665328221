const filterObject = (object, filter) => {
    if (!object)
        return false;
    if (!filter)
        return true
    filter = filter.toLowerCase()
    for (var key in object) {
        const value = object[key]
        if (!value) continue;
        if (value.toString().toLowerCase().includes(filter))
            return true;
    }

    return false;
}

export { filterObject }