const root = "root"
const admin = "admin"
const support = "support"
const installer = "installer"
const user = "user"


const rolesCount = (activeRoles) => {
    if (!activeRoles || !Array.isArray(activeRoles))
        return 0;
    return activeRoles.length;
}

const hasRole = (activeRoles, role) => {
    return (activeRoles && Array.isArray(activeRoles) && activeRoles.includes(role)) ? true : false;
}

const hasAnyRoles = (activeRoles, args) => {
    if (!activeRoles || !Array.isArray(activeRoles))
        return false;
    for (const arg of args) {
        if (activeRoles.includes(arg))
            return true
    }

    return false;
};

const hasAllRoles = (activeRoles, args) => {
    if (!activeRoles || !Array.isArray(activeRoles))
        return false;
    if (activeRoles.length <= 0) {
        return false
    }

    let result = true;
    for (const arg of args) {
        if (!activeRoles.includes(arg)) {
            result = false;
            break;
        }
    }

    return result;
};

export {
    root,
    admin,
    support,
    installer,
    user,
    rolesCount,
    hasRole,
    hasAnyRoles,
    hasAllRoles
}