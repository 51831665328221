import React from 'react'
import { useContext } from 'react';
import { AppContext } from "../../../AppContext";
import { showLoading } from 'react-global-loading';
import { isMobile } from 'react-device-detect';
import { useNavigate } from "react-router-dom";
import TopBarComponenent from '../../TopBarComponenent';
import PasswordChangeForm from './PasswordChangeForm';
import './PasswordChangePage.css';

function PasswordChangePage(props) {
    const navigate = useNavigate();
    const { setBusy } = useContext(AppContext);
    const { appTheme } = React.useContext(AppContext);
    const mobile = isMobile;
    const barHeight = mobile ? "22px" : "35px";

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, []);

    React.useEffect(() => {
        if (props.ready) {
            setBusy(false);
            showLoading(false);
        }
    }, [props.ready]);

    const onBackAction = (e) => {
        e.preventDefault();
        if (props.onCancel) {
            props.onCancel();
        }

        if (props.cancelRoute) {
            navigate(props.cancelRoute)
        }
    };

    React.useEffect(() => {
        window.addEventListener("popstate", onBackAction);
        return () => window.removeEventListener("popstate", onBackAction);
    }, []);

    if (!props.ready)
        return null;
    return (
        <div className='full-screen'>
            <div className={isMobile ? 'top-bar-div-mobile' : 'top-bar-div'} style={{ backgroundColor: appTheme.themeColor }}>
                <TopBarComponenent showInfo={true} showScreenControl={true} showNoFullScreen={true} />
            </div>
            <div className='centre-div'>
                <PasswordChangeForm {...props}></PasswordChangeForm>
            </div>
            <div style={{ height: barHeight }}></div>
        </div>);
}

export default PasswordChangePage;