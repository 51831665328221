import React from 'react'
import { IconContext } from "react-icons";
import { isMobile } from 'react-device-detect';
import { showLoading } from 'react-global-loading';

import fetchSimcards from "../../../calls/administration/simcards/fetchSimcards.js";
import updateSimNumber from "../../../calls/administration/simcards/updateSimNumber.js";
import { localTimeFormat, convertTimeSent, toLocalTime } from '../../../utilities/timeHelper.js'

import { hexToRgbaWithAlpha } from "../../../utilities/convertColors.js";
import { GrClose } from "react-icons/gr";
import SearchFilter from '../../SearchFilter/SearchFilter'

function SimcardManager(props) {
    const [data, setData] = React.useState(null);
    const [selectedRecord, setSelectedRecord] = React.useState(null);
    const [simcardNumberSetup, setSimcardNumberSetup] = React.useState(null);
    const [busy, setBusy] = React.useState(false);
    const [refresh, setRefresh] = React.useState(false);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [searchFilter, setSearchFilter] = React.useState("");

    const updateUnitWithSimcardList = (silent) => {
        if (!silent) {
            setBusy(true);
            showLoading(true)
        }

        const filter = {
            pageNumber: pageNumber,
            search: searchFilter?.value ?? ""
        }

        fetchSimcards((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false)
            }
            if (e.error) {
                return;
            }

            console.log(e.data)
            setData(e.data)
            //setSimcardRecords(e.data)
        }, filter);
    }


    React.useEffect(() => {
        updateUnitWithSimcardList();
    }, []);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateUnitWithSimcardList(true);
        }
    }, [searchFilter]);

    // React.useEffect(() => {
    //     if (refresh) {
    //         setRefresh(false)
    //         updateUnitWithSimcardList();
    //     }
    // }, [refresh]);

    if (busy) {
        return null;
    }

    const simcardRecords = data?.data
    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
            <div style={{ display: "flex", height: "100%", width: "100%", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column", height: "100%", width: "100%" }}>
                    <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                        <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                            <table style={{ width: "100%", fontSize: "14px" }}>
                                <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                    <tr>
                                        <th style={{ textAlign: "left", }}>Code</th>
                                        <th style={{ textAlign: "left", }}>Description</th>
                                        <th style={{ textAlign: "left" }}>Network</th>
                                        <th style={{ textAlign: "left" }}>Number</th>
                                        <th style={{ textAlign: "left" }}>Unit</th>
                                        <th style={{ textAlign: "left" }}>Asset</th>
                                        <th style={{ textAlign: "left" }}>Last Refresh</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        simcardRecords &&
                                        simcardRecords.map((record, index) => {
                                            const finalRecord = record;
                                            const isSelected = (selectedRecord && finalRecord.id === selectedRecord?.id);
                                            const lastRefresh = finalRecord.latestUpdate?.createdAt ?
                                                localTimeFormat(toLocalTime(finalRecord.latestUpdate.createdAt)) :
                                                "_";
                                            return (
                                                <tr key={index}
                                                    style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                                    onClick={() => {
                                                        setSelectedRecord(finalRecord)
                                                    }}>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.imsi}</td>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.iccid}</td>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.network}</td>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.number ?? "_"}</td>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.latestUpdate?.unit}</td>
                                                    <td style={{ textAlign: "left" }}>{finalRecord.latestUpdate?.asset}</td>
                                                    <td style={{ textAlign: "left" }}>{lastRefresh}</td>
                                                </tr>);
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ width: "100%", display: "flex", columnGap: '20px', height: "25px", padding: "3px" }}>
                        <button
                            disabled={!selectedRecord}
                            onClick={() => {
                                setSimcardNumberSetup({
                                    open: true,
                                    record: selectedRecord
                                })
                            }}>
                            Set Sim Number
                        </button>
                        <SearchFilter
                            initialValue={searchFilter?.value}
                            onFilterChange={(s) => {
                                setSearchFilter({
                                    updated: true,
                                    value: s
                                })
                            }}>
                        </SearchFilter>
                    </div>
                </div>
            </div>
            {simcardNumberSetup?.open && <div style={{
                width: "100%", height: "100%", display: "flex",
                position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                right: 0, left: 0, top: 0, bottom: 0,
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <SetupSimcardNumber
                    {...simcardNumberSetup}
                    onSuccess={() => {
                        setSimcardNumberSetup(null)
                        setRefresh(true)
                    }}
                    onClose={() => {
                        setSimcardNumberSetup(null)
                    }} />
            </div>}
        </div>)
}

function SetupSimcardNumber(props) {
    const [number, setNumber] = React.useState("");
    const [error, setError] = React.useState(null);
    React.useEffect(() => {
    }, []);
    const awesomeSize = isMobile ? 18 : 14;
    return (
        <dialog open={true}
            style={{
                padding: "10px",
                borderWidth: "1px",
                borderRadius: "5px", display: "flex", flexDirection: "column", gap: "10px"
            }}>
            <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
                <div style={{ width: "100%", display: "flex", flex: 1 }}>
                    Setup number for {props?.record?.imsi}
                </div>
                <button style={{ backgroundColor: "transparent", borderWidth: "0px", height: "100%" }}
                    onClick={() => {
                        if (props.onClose) {
                            props.onClose();
                        }
                    }}>
                    <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <IconContext.Provider value={{ size: awesomeSize }}>
                            <GrClose />
                        </IconContext.Provider>
                    </div>
                </button>
            </div>
            <input type="tel" placeholder="Sim Number" value={number}
                autoComplete="off"
                onChange={(e) => {
                    setNumber(e.target.value)
                }} />
            <button
                onClick={() => {
                    const data = {
                        id: props.record.id,
                        number: number
                    }

                    updateSimNumber(data, (e) => {
                        if (e.error) {
                            setError(e.error)
                            return;
                        }
                        if (props.onSuccess) {
                            props.onSuccess(e);
                        }
                    })
                }}>
                Set Sim Number
            </button>
            {
                !error ?
                    null :
                    <div style={{ display: "flex", color: "red", fontWeight: "bold", fontSize: "10px", whiteSpace: "pre-line" }}>
                        {error}
                    </div>}
        </dialog>
    );
}

export default SimcardManager;