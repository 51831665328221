import { useState, useEffect } from "react";

const loadLocalObject = (key, defaultValue) => {
    if (!key) return defaultValue;
    const data = localStorage.getItem(key);
    try {
        if (data)
            return JSON.parse(data)
        else
            return defaultValue
    }
    catch (ex) {
        return defaultValue
    }
}

const storeLocalObject = (key, obj) => {
    if (!key) return
    if (!obj) {
        localStorage.setItem(key, "");
        return;
    }

    localStorage.setItem(key, JSON.stringify(obj));
}

const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => {
        return loadLocalObject(key, defaultValue);
    });

    useEffect(() => {
        storeLocalObject(key, value);
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage