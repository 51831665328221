import React from 'react'
import { isMobile } from 'react-device-detect';
import { fetchUnitSms } from "../../../calls/administration/sms/fetchUnitSms.js";
import { timeSpanFromHours } from "../../../utilities/dateHelper";
import { hexToRgbaWithAlpha } from "../../../utilities/convertColors";
import { truncateWithEllipses } from "../../../utilities/stringHelper";
import { localTimeFormat, convertTimeSent, toLocalTime } from '../../../utilities/timeHelper.js'
import Paginator from '../../Paginator/Paginator'
import SearchFilter from '../../SearchFilter/SearchFilter'

export default function SimcardSmsList(props) {
    const [busy, setBusy] = React.useState([]);
    const [data, setData] = React.useState(null);
    const [selectedSms, setSelectedSms] = React.useState(null);

    const [pageNumber, setPageNumber] = React.useState(1);
    const [searchFilter, setSearchFilter] = React.useState("");

    const updateUnitSmsList = (silent) => {

        const assetId = props?.selectedEntry?.assetId;
        const interval = timeSpanFromHours(24 * 90);
        const filter = {
            pageNumber: pageNumber,
            search: searchFilter?.value ?? ""
        }

        if (!silent) {
            setBusy(true)
        }

        fetchUnitSms((e) => {
            if (!silent) {
                setBusy(false)
            }

            if (e.error) {
                return;
            }

            setData(e.data)
        }, filter);
    }

    React.useEffect(() => {
        updateUnitSmsList();
    }, []);

    React.useEffect(() => {
        updateUnitSmsList(true);
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateUnitSmsList(true);
        }
    }, [searchFilter]);


    if (busy) return null;
    const messages = data?.data;
    return (

        <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", padding: "5px 0px" }}>
            </div>
            <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                    <table style={{ width: "100%", fontSize: "14px" }}>
                        <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                            <tr>
                                <th style={{ textAlign: "left", }}>Created</th>
                                <th style={{ textAlign: "left", }}>Asset</th>
                                <th style={{ textAlign: "left" }}>Header</th>
                                <th style={{ textAlign: "left" }}>Body</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                messages &&
                                messages.map((sms, index) => {
                                    const finalSms = sms;
                                    const isSelected = (selectedSms && finalSms.id === selectedSms?.id);
                                    const time = localTimeFormat(toLocalTime(finalSms.createdAt));
                                    return (
                                        <tr key={index}
                                            style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }}
                                            onClick={() => {
                                                setSelectedSms(finalSms)
                                            }}>
                                            <td style={{ textAlign: "left" }}>{time}</td>
                                            <td style={{ textAlign: "left" }}>{finalSms.asset}</td>
                                            <td style={{ textAlign: "left" }}>{finalSms.header}</td>
                                            <td style={{ textAlign: "left" }}>{truncateWithEllipses(finalSms.body, 50)}</td>
                                        </tr>);
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div style={{ width: "100%", display: "flex", flexDirection: "row", columnGap: '20px', height: "25px", padding: "3px" }}>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
                <Paginator
                    changeIndicator={data}
                    initialNumber={pageNumber}
                    pageSize={data?.pageSize}
                    pageCount={data?.pageCount}
                    count={data?.count}
                    onPageSelected={(page) => {
                        console.log(page)
                        setPageNumber(page.number)
                    }} />
            </div>
        </div>
    );
}
