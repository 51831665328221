import React from 'react'

import fetchUsers from "../../../calls/administration/users/fetchUsers";
import { isMobile } from 'react-device-detect';
import { toLocalTimeFormatted } from '../../../utilities/timeHelper'
import { showLoading } from 'react-global-loading';
import ConfirmBox from '../ConfirmBox';
import { hexToRgbaWithAlpha } from '../../../utilities/convertColors';

import deleteUser from '../../../calls/administration/users/deleteUser';

import { adminResetPassword } from '../../../calls/administration/users/resetPassword';
import UserCreation from "./UserCreation"
import SearchFilter from '../../SearchFilter/SearchFilter';
import Paginator from '../../Paginator/Paginator';

function UsersListView(props) {
    const [data, setData] = React.useState(null);
    const [users, setUsers] = React.useState([]);
    const [selectedUser, setSelectedUser] = React.useState(null);

    const [busy, setBusy] = React.useState(true);
    const [userDeleteData, setUserDeleteData] = React.useState(null);
    const [userCreationOptions, setUserCreationOptions] = React.useState(false);
    const [userResetPasswordData, setUserResetPasswordData] = React.useState(null);
    const [pageNumber, setPageNumber] = React.useState({ value: 1 });
    const [searchFilter, setSearchFilter] = React.useState(null);

    const updateUsers = (silent = true) => {
        if (!silent) {
            setBusy(true);
            showLoading(true);
        }

        const filter = {
            pageNumber: pageNumber.value,
            search: searchFilter?.value ?? "",
        }

        fetchUsers((e) => {
            if (!silent) {
                setBusy(false);
                showLoading(false);
            }

            if (e.error) {
                return;
            }

            setData(e?.data)
            setUsers(e?.data?.data);
        }, filter);
    }

    React.useEffect(() => {
        updateUsers(false);
    }, []);

    React.useEffect(() => {
        if (pageNumber?.updated) {
            const page = { ...pageNumber }
            page.updated = false;
            setPageNumber(page)
            updateUsers(true);
        }
    }, [pageNumber]);

    React.useEffect(() => {
        if (searchFilter?.updated) {
            const filter = { ...searchFilter }
            filter.updated = false;
            setSearchFilter(filter)
            updateUsers(true);
        }
    }, [searchFilter]);

    if (busy) {
        return null;
    }

    return (
        <div style={{ width: "100%", height: "100%", display: "flex", overflow: "auto", flexDirection: "column" }}>
            <div id='topbar' style={{ minHeight: "30px", display: "flex", marginRight: "5px" }}>
                <div style={{ flex: 1 }}></div>
                <SearchFilter
                    initialValue={searchFilter?.value}
                    onFilterChange={(s) => {
                        setSearchFilter({
                            updated: true,
                            value: s
                        })
                    }}>
                </SearchFilter>
            </div>
            <div style={{ width: "100%", flexFlow: "column", display: "flex", flex: 1 }}>
                <div style={{ width: "100%", flexFlow: "column", position: "relative", display: "flex", flex: 1 }}>
                    <div style={{ height: "100%", width: "100%", position: "absolute", overflow: "auto" }}>
                        <table style={{ width: "100%", fontSize: "14px" }}>
                            <thead style={{ position: "sticky", top: "-1px", backgroundColor: "whitesmoke" }}>
                                <tr>
                                    <th style={{ textAlign: "left", }}>Username</th>
                                    <th style={{ textAlign: "left", }}>Account</th>
                                    <th style={{ textAlign: "left", }}>Email</th>
                                    <th style={{ textAlign: "left", }}>PhoneNumber</th>
                                    <th style={{ textAlign: "left", }}>Active</th>
                                    <th style={{ textAlign: "left" }}>Updated</th>
                                    <th style={{ textAlign: "left" }}>Created</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users &&
                                    users.map((user, index) => {
                                        const finalUser = user;
                                        const isSelected = (selectedUser && finalUser.id === selectedUser?.id);
                                        return (
                                            <tr key={index}
                                                style={{ backgroundColor: isSelected ? "#dbecfa" : "transparent" }} onClick={() => {
                                                    setSelectedUser(finalUser)
                                                }}>
                                                <td style={{ textAlign: "left" }}>{finalUser.userName}</td>
                                                <td style={{ textAlign: "left" }}>{finalUser.account ?? '__'}</td>
                                                <td style={{ textAlign: "left" }}>{finalUser.email}</td>
                                                <td style={{ textAlign: "left" }}>{finalUser.phoneNumber}</td>
                                                <td style={{ textAlign: "left", color: finalUser.active ? "green" : "red" }}>{String(finalUser.active)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{toLocalTimeFormatted(finalUser.createdAt)}</td>
                                                <td style={{ textAlign: "left", width: "1%", textWrap: "nowrap" }}>{toLocalTimeFormatted(finalUser.updatedAt)}</td>
                                            </tr>);
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    Boolean(data?.pageCount && data?.pageCount > 1) &&
                    <div style={{ alignItems: "end", display: "flex", justifyContent: "end", marginRight: "20px" }}>
                        <Paginator
                            changeIndicator={data}
                            initialNumber={pageNumber?.value}
                            pageSize={data?.pageSize}
                            pageCount={data?.pageCount}
                            count={data?.count}
                            onPageSelected={(page) => {
                                console.log(page)
                                setPageNumber({
                                    value: page.number,
                                    updated: true
                                })
                            }} />
                    </div>
                }
            </div>
            <div style={{ height: "30px", display: "flex", padding: "2px" }}>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    onClick={() => {
                        setUserCreationOptions({
                            open: true,
                        })
                    }}>
                    Create
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedUser}
                    onClick={() => {
                        const selected = selectedUser;
                        if (!selected) return;
                        setUserCreationOptions({
                            open: true,
                            editUser: selected
                        })
                    }}>
                    Edit
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedUser}
                    onClick={() => {
                        const selected = selectedUser;
                        if (!selected) return;
                        setUserResetPasswordData({
                            open: true,
                            user: selected
                        });
                    }}>
                    Reset Password
                </button>
                <button style={{ padding: "2px", minWidth: "80px", margin: "0px 5px" }}
                    disabled={!selectedUser}
                    onClick={() => {
                        setUserDeleteData({
                            open: true,
                            deleteUser: selectedUser
                        });
                    }}
                >
                    Delete
                </button>
            </div>
            {
                userDeleteData?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        open={userDeleteData?.open}
                        title="User deletion"
                        onCancel={() => {
                            setUserDeleteData(null)
                        }}
                        onConfirm={() => {
                            const userId = userDeleteData?.deleteUser?.id;
                            if (!userId) return;
                            setUserDeleteData(null)
                            deleteUser((e) => {
                                if (e.error) {
                                    return
                                }

                                updateUsers(false)
                            }, userId);
                        }}
                        message={`You are about to delete user ${userDeleteData?.deleteUser?.userName}`} />
                </div>
            }
            {
                userResetPasswordData?.open &&
                <div style={{
                    width: "100%", height: "100%", display: "flex",
                    position: "fixed", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4),
                    right: 0, left: 0, top: 0, bottom: 0
                }}>
                    <ConfirmBox
                        open={userResetPasswordData?.open}
                        title="Password Reset"
                        onCancel={() => {
                            setUserResetPasswordData(null)
                        }}
                        onConfirm={() => {
                            const userId = userResetPasswordData?.user?.id;
                            if (!userId) return;
                            const data = {
                                userId: userId
                            }
                            setUserResetPasswordData(null)
                            adminResetPassword((e) => {
                                if (e.error) {
                                    return
                                }

                                updateUsers(false)
                            }, data);
                        }}
                        message={`You are about to reset password of user ${userResetPasswordData?.user?.userName}`} />
                </div>
            }
            {
                userCreationOptions?.open &&
                <div style={{ position: "fixed", left: 0, top: 0, width: "100%", height: "100%", backgroundColor: hexToRgbaWithAlpha("#000000", 0.4) }}>
                    <UserCreation
                        options={userCreationOptions}
                        onUserCreated={(data) => {
                            setUserCreationOptions(null)
                            updateUsers();
                        }}
                        onUserEdited={(data) => {
                            setUserCreationOptions(null)
                            updateUsers();
                        }}
                        onCancel={() => {
                            setUserCreationOptions(null)
                        }} /></div>
            }

        </div>)
}

export default UsersListView;