import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-data-grid/lib/styles.css';

//import "react-widgets/styles.css";
// import "bootstrap/dist/css/bootstrap.min.css";
// import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';
import App from './App';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  //<React.StrictMode>
  <App />
  //</React.StrictMode>
);
