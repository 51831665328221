import { isMobile, isIOS, isAndroid } from 'react-device-detect';
const desktopTheme = {
    fontSize: "14px",
    statusFontSize: "13px",
    eventGridFontSize: '14px',
    eventGridErrorFontSize: "18px",
    userGridFontSize: '12px'
}

const mobilePortraitTheme = {
    fontSize: "12px",
    statusFontSize: "10px",
    eventGridFontSize: '10px',
    eventGridErrorFontSize: "12px",
    userGridFontSize: '10px'
}

const mobileLandscapeTheme = {
    fontSize: "8px",
    statusFontSize: "8px",
    eventGridFontSize: '10px',
    eventGridErrorFontSize: "12px",
    userGridFontSize: '10px'
}

const androidThemeColor = "#4A148C"
const iosThemeColor = "#01579B"
const desktopThemeColor = "#004D40"

const configureTheme = ({ isPortrait }) => {

    if (!isMobile) {
        const theme = { ...desktopTheme }
        theme.themeColor = desktopThemeColor
        return theme
    }
    else {
        const theme = { ...(isPortrait ? mobilePortraitTheme : mobileLandscapeTheme) }
        if (isAndroid)
            theme.themeColor = androidThemeColor
        else if (isIOS)
            theme.themeColor = iosThemeColor
        else
            theme.themeColor = desktopThemeColor
        return theme
    }
}

export { configureTheme }